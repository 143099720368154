import React, {Component} from "react";
import {Descriptions, Icon, message, Modal} from "antd";
import LinkButton from "../LinkButton";
import TaxDialog from "./TaxDialog";
import {reqOverWriteTax} from "../../api";


class FeeList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      fee: props.fee,
      showDialog: false,
      displayAdd: false
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.fee !== prevProps.fee) {
      this.setState({fee: this.props.fee, displayAdd:this.props.displayAdd})
    }
  }

  onSetTax = () => {
    this.setState({showDialog: true})
  };

  overwriteTax = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const id = this.props.id;
        const {gst, hst, pst} = values;
        const tax = {gst, hst, pst};
        reqOverWriteTax(id, tax)
          .then(response => {
            message.success('Tax Overwritten!');
            const data = response.data;
            this.setState({fee: data, showDialog: false});
          })
          .catch(error => {
            console.log(error)
          });
      }
    })
  };

  render() {
    const {fee, showDialog,displayAdd} = this.state;
    return (
      <React.Fragment>
        <Descriptions bordered size="small" style={{float: 'right', paddingRight: '25px'}}>
          <Descriptions.Item label="Total" span={3} className='cy-retail'>${fee.total}</Descriptions.Item>
          <Descriptions.Item label="Discount" span={3} className='cy-retail'>${fee.discount}</Descriptions.Item>
          <Descriptions.Item label="Retail" span={3} className='cy-retail'>${fee.retail}</Descriptions.Item>
          <Descriptions.Item label="GST" span={3} className='cy-gst'>${fee.gst}</Descriptions.Item>
          <Descriptions.Item label="PST" span={3} className='cy-pst'>${fee.pst}</Descriptions.Item>
          <Descriptions.Item label="HST" span={3} className='cy-hst'>${fee.hst}</Descriptions.Item>
          <Descriptions.Item label="Total Cost" span={3}
                             className='cy-retail-with-taxes'>${fee.retail_with_taxes}</Descriptions.Item>
          <Descriptions.Item label="Total Payment" span={3}
                             className='cy-amount-paid'>${fee.amount_paid}</Descriptions.Item>
          <Descriptions.Item label="Outstanding Balance" className='cy-balance'
                             span={3}>${fee.outstanding_balance}</Descriptions.Item>
        </Descriptions>
        { displayAdd &&
          <>
            <div style={{float: 'right', paddingRight: '20px'}} className='overwrite-btn'>
              <LinkButton
                onClick={() => this.onSetTax()}>
                <Icon style={{fontSize: '20px'}}
                      type="dollar"/>
              </LinkButton>
            </div>
            <Modal
              title='Overwrite Tax'
              visible={showDialog}
              onOk={this.overwriteTax}
              onCancel={() => {
                this.setState({showDialog: false});
                this.form.resetFields();
              }}
            >
              <TaxDialog setForm={form => this.form = form} fee={this.props.fee}/>
            </Modal>
          </>
        }

      </React.Fragment>
    )
  }
}


export default FeeList;