import React, {Component} from 'react';
import {Form, Row, Col, Select, DatePicker} from 'antd';
import {reqGetVendorsPlusDelete} from '../../../api';

const {Item} = Form;
const {Option} = Select;
const {RangePicker} = DatePicker;

class SearchSold extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
    };
    props.setForm(props.form)
  }

  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'diamond', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }




  refreshTable = () => {
    setTimeout(() => {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.props.onSubmit(values);
        }
      });
    }, 0);
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    return (
      <Form>
        <Row>
          <Col span={24}>
            <Item label="Stone Sold Dates" labelCol={{span: 3}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('between_dates', {
                  initialValue: null
                })(<RangePicker onChange={this.refreshTable} />)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Item label="Vendor" labelCol={{span: 7}} wrapperCol={{span: 12}}>
              {getFieldDecorator('vendor_id', {
                initialValue: '',
              })(
                <Select
                  showSearch
                  onChange={this.refreshTable}
                  filterOption={(inputValue, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                >
                  <Option value='' key='Any'>Any</Option>
                  {
                    this.state.vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                           key={item.id}> {item.name} </Option>)
                  }
                </Select>
              )}
            </Item>
          </Col>
          <Col span={6}>
            <Item label="OC" labelCol={{span: 5}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('oc', {
                  initialValue: ""
                })(
                  <Select onChange={this.refreshTable}>
                    <Option value='' key='Any'>Any</Option>
                    <Option value='1' key='Yes'>Yes</Option>
                    <Option value='0' key='No'>No</Option>
                  </Select>
                )
              }
            </Item>
          </Col>
          <Col span={6}>
            <Item label="Reported" labelCol={{span: 6}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('reported', {
                  initialValue: ""
                })(
                  <Select onChange={this.refreshTable}>
                    <Option value='' key='Any'>Any</Option>
                    <Option value='1' key='Yes'>Yes</Option>
                    <Option value='0' key='No'>No</Option>
                  </Select>
                )
              }
            </Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(SearchSold)