export const DISCOUNT_TYPE = ['Credit Card', 'Cheque', 'Debit', 'Cash', 'Wire Transfer', 'e-Transfer'];

export const PAYMENT_TYPE = ['Credit Card', 'Cheque', 'Debit', 'Financing', 'Cash', 'Correction', 'Wire Transfer', 'e-Transfer', 'Trade-In'];

export const SHAPE = ['Asscher', 'Baguette', 'Cushion', 'Cushion Modified', 'Dutch Marquise', 'Emerald', 'European Cut', 'Half Moon', 'Heart',
  'Marquise', 'Old Miner', 'Oval', 'Pear', 'Princess', 'Radiant', 'Rose', 'Round', 'Shield', 'Star', 'Tapered Baguette', 'Trapezoid', 'Trilliant'];

export const COLOUR = ['D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const CLARITY = ['VVS1', 'VVS2', 'VS1', 'VS2', 'SI', 'SI1', 'SI2', 'SI3', 'I1', 'I2', 'I3', 'IF', 'FL'];

export const CUT = ['Triple Excellent', 'Excellent', 'Very Good', 'Good', 'Ideal', 'Fair', 'Poor', '\'000\' Ideal',];

export const CERTIFICATE_TYPE = ['GIA', 'AGS', 'EGL', 'GCAL', 'Gem Lab', 'Gemscan', 'HRD', 'IGI', 'Stuller', 'NONE'];

export const STATUS = ['Incomplete', 'Awaiting Stones', 'In Progress', 'Ready For Pickup', 'Completed'];

export const STATUS_DIAMOND = ['Completed', 'Hold', 'Awaiting Shipment', 'Instock', 'Off Site'];

export const STATUS_JEWELLERY = ['Completed', 'Ordered', 'Off Site'];

export const STATUS_REPAIR = ['Completed', 'Ordered', 'Sent', 'Off Site'];

export const STATUS_STONE = ['Completed', 'Off Site'];

export const STATUS_STYLE = ['Completed', 'cancelled', 'Ordered', 'Sent', 'Wax Started', 'Off Site'];

export const STATUS_COMPONENT = ['Completed', 'Ordered', 'Sent', 'Off Site'];

export const REFERRALS = ['Web search', 'Facebook', 'Instagram', 'Forum', 'Other', 'Referral'];

export const COMPONENT_JOB_TYPE = ['Add Finish', 'Appraisal', 'Component', 'Discount', 'Engraving', 'Financing Admin Fee',
  'Prototype Loan', 'Setting', 'Shipping', 'silver model', 'Stone Cutting', 'Stone Removal', 'Style Modification', 'Ring Registry'];

export const JEWELLERY_JOB_TYPE = ['Bracelet', 'Chain', 'Cuff Links', 'Earrings', 'Necklace'];

export const REPAIR_JOB_TYPE = ['Appraisal', 'Assembly', 'Modelling', 'Polish/Rhodium', 'repair', 'Ring Re-do',
  'Sizing', 'Testing'];

export const ORDER_JOB_TYPE = ['Engagement Ring', 'Wedding Band', 'Repair', 'Jewellery', 'Miscellaneous', 'Ring Registry'];

export const STONE_TYPE = ['Amethyst', 'Black diamond', 'blue sapphire', 'blue topaz', 'Create Blue Sapphire', 'Created Alexxandrite',
  'Created Blue Sapphire', 'Created Pink Sapphire', 'created rubies', 'Created Ruby', 'Cultured Akoya Pearl', 'diamond',
  'Fancy yellow diamonds (irradiated)', 'Forever Brilliant Moissanite', 'Forever One', 'Forever One Moissanite',
  'Forever One Moissanite by Charles & Colv', 'Genuine Aquamarine', 'Genuine Blue Sapphire', 'Genuine Emerald',
  'Genuine Morganite', 'Genuine Ruby', 'Genuine Tanzanite', 'Genuine Tourmaline', 'Genuine White Topaz', 'Genuine Yellow Sapphire',
  'Green Quart', 'Lab Created Diamond', 'Moissanite', 'Moonstone', 'Morganite', 'Mosaic Opal', 'Mozambique Garnet',
  'Natural Blue Sapphire', 'Natural Kunzite', 'Opal', 'Padparadscha sapphire', 'Pearl', 'Peridot', 'Pink Sapphire', 'red garnet',
  'Rhodalite Garnet', 'Swarovski Cubic Zirconia', 'White sapphire', 'Yellow CZ', 'Yellow Sapphire'];

export const STONE_SHAPE = ['Asscher', 'Cabachone', 'Cushion', 'Emerald', 'Marquise', 'Oval', 'pear', 'Princess',
  'Round', 'Square', 'Triangle', 'Trillion'];

export const STONE_COLOUR = ['Colorless', '\'AAA\' quality', 'AA'];

export const STONE_CUT = ['Very Good', 'Ex', 'Triple Excellent', 'Heart & Arrows'];

export const STONE_CLARITY = ['AAA', 'VS2'];

export const STONE_CERTIFICATE_TYPE = ['CIA', 'IGI'];

export const MOUNT_METAL = ['10k Rose and White gold', '10k Rose gold', '10k White and yellow gold', '10k White gold',
  '10k White gold with titanium inlay', '10k Yellow gold', '10k Yellow Gold and Titanium', '10kt White gold with TI inlay & TI liner',
  '10kt with TI inlay', '10kt WYW', '14 kt and tit', '14 kt whte gold with yellow gold', '14k Rose and White gold',
  '14k Rose gold', '14k Rose Gold and Platinum', '14k white & black rhodium', '14k White & Yellow Gold with Ti Liner',
  '14k White and Pink gold', '14k White and Rose Gold', '14k White and Yellow gold with titanium inlay',
  '14k White gold', '14k White gold and 14k Rose gold', '14k White gold and 18k Yellow gold', '14k White gold with Titanium Inlay',
  '14k White gold with titanium inlay and titanium liner', '14k White gold with Titanium liner', '14k White, Yellow and Rose Gold',
  '14k Yellow and rose gold', '14k Yellow gold', '14k Yellow gold & White gold', '14k Yellow gold and platinum',
  '14kt w/ TI liner', '18k Rose gold', '18K W/G & Pink Gold',
  '18k White & yellow gold', '18k White and Rose Gold', '18k White gold', '18k Yellow and White gold', '18k Yellow gold',
  'Black Ceramic', 'Black Tungsten', 'Brass',
  'ceramic', 'Cobalt', 'Cobalt with carbon fibre inlay', 'Cobalt with silver inlay', 'Colbalt',
  'Palladium', 'Palladium and 14k Rose Gold', 'Palladium and 14k Yellow gold', 'Palladium w/ TI liner',
  'Palladium with titanium inlay', 'Palladium with titanium liner', 'Platinum', 'Platinum (PT600)',
  'Platinum with 14k Yellow Gold', 'Platinum with 18k yellow gold inlay', 'Platinum/18k Yellow gold', 'Platinum+ 5% Ruthenium',
  'Reclaimed Platinu', 'Rose alloy', 'Silver', 'Silver (rhodium plated)', 'Silver and Titanium', 'Titanium',
  'Titanium with 10k Rose gold inlay', 'Titanium with 14k Rose gold inlay', 'Titanium with 18k White gold inlay', 'Titanium with Palladium inlay',
  'Titanium with Silver inlay', 'Tungsten Carbide', 'Tungsten Carbide with palladium inlay', 'Tungsten w/ 18kt Rose gold inlay',
  'Tungsten w/ Silver Inlay', 'Tunsgten Carbide with 18k Yellow gold inlay', 'wax', 'White Tungsten'];

export const HEAD_METAL = ['10k White gold', '10k Yellow gold', '10kt with TI inlay', '10kt WYW',
  '14 kt whte gold with yellow gold', '14k Rose and White gold', '14k Rose gold', '14k White and Pink gold',
  '14k White and Rose Gold', '14k White and Yellow gold with titanium inlay', '14k White gold', '14k White gold and 14k Rose gold',
  '14k White gold and 18k Yellow gold', '14k Yellow and rose gold', '14k Yellow gold', '14k Yellow gold & White gold', '14kt w/ TI liner',
  '18k Rose gold', '18k White & yellow gold', '18k White gold', '18k Yellow gold', 'Black Ceramic', 'ceramic', 'Colbalt',
  'Palladium', 'Palladium and 14k Rose Gold', 'Palladium w/ TI liner', 'Palladium with titanium inlay', 'Platinum',
  'Silver', 'Titanium', 'Titanium with Silver inlay', 'Tungsten Carbide', 'Tungsten w/ Silver Inlay'];

export const JOB_TYPE = ['Engraving', 'Appraisal'];

export const MONDAY = 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEyMjIzODUxOSwidWlkIjoyMzY3MTk4MiwiaWFkIjoiMjAyMS0wOC0yNlQxNjoyOTo0Ny4wMjBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6OTYwNDM4NywicmduIjoidXNlMSJ9.EOavZoQZZMW-eFNynH2YIiGCg6l5w7wQNVZ0geXzL9s';
