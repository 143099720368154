import React, {Component} from 'react';
import {Card, Collapse, Spin} from 'antd';
import {reqGetInventoryVendors} from '../../../api';
import InvoiceNo from '../../../components/Inventory/Vendor/InvoiceNo';

const {Panel} = Collapse;

export default class Vendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [], //vendor list
      loading: false
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    reqGetInventoryVendors()
      .then(response => {
        const vendors = response.data;
        this.setState({vendors, loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  render() {
    const {vendors, loading} = this.state;

    return (
      <Card>
        <Spin spinning={loading}>
          <Collapse bordered={false}>
            {
              vendors.map(item => {
                return <Panel header={item.name} key={item.id}>
                  <InvoiceNo vendor={item.id}/>
                </Panel>
              })
            }
          </Collapse>
        </Spin>
      </Card>
    )
  }
}