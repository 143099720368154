import React, {Component} from 'react';
import {Card, Collapse, Spin} from 'antd';
import {reqGetInvoices} from '../../../api';
import InvoiceTable from './InvoiceTable';

const {Panel} = Collapse;

export default class InvoiceNo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [], //vendor list
      loading: false
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    reqGetInvoices(this.props.vendor)
      .then(response => {
        const invoices = response.data;
        this.setState({invoices, loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  render() {
    const {invoices, loading} = this.state;

    return (
      <Card>
        <Spin spinning={loading}>
          <Collapse bordered={false}>
            {
              invoices.map(item => {
                return <Panel header={`${item.invoice_number + " Date: " + item.inventory_date}`}
                              key={`${item.invoice_number}`}>
                  <InvoiceTable invocie={item.invoice_number}/>
                </Panel>
              })
            }
          </Collapse>
        </Spin>
      </Card>
    )
  }
}