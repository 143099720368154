import React, {Component} from 'react';
import {Spin, Card, Descriptions, Icon, Button, Table, Col, Row, message, Modal} from 'antd';
import LinkButton from '../../components/LinkButton';
import {formatDate} from '../../utils/utility';
import PaymentTable from '../../components/Order/Payment/PaymentTable';
import FeeList from '../../components/Order/FeeList';
import {
  reqGetOrderByOrderIdInView,
  reqGetOrderItemsByOrderId, reqMoveBackFromPurgatory,
  reqUpdateExcludeByOrderItemId
} from '../../api';
import Address from '../../components/Order/Address';
import Print from '../../components/Order/Print';
import {Link} from 'react-router-dom';
import {getUserType, hasRole} from '../../utils/auth';

class View extends Component {

  constructor(props) {
    super(props);
    this.user_type = getUserType();
    this.id = this.props.match.params.id;

    this.state = {
      loading: true,
      selectedRowKeys: [],
      fee: {},
      order: {},
      full_name: '',
      invoice_address: {},
      items_description: [],
      print: false,
      flag: false,
    };

    this.initColumns();
  }

  componentDidMount() {
    //get order info
    reqGetOrderByOrderIdInView(this.id)
      .then(response => {
        let {items_description, fee, customer, invoice_address, order} = response.data;
        console.log(fee)
        customer = customer[0];
        this.setState({
          items_description,
          fee,
          full_name: `${customer.first_name} ${customer.last_name}`,
          invoice_address,
          order
        });
        //if item is exclude, checked
        let selectedRowKeys = [];
        for (let i = 0; i < items_description.length; i++) {
          if (!!items_description[i].exclude) {
            selectedRowKeys.push(items_description[i].id);
          }
        }
        this.setState({selectedRowKeys})
        this.customer_id = customer.id;
        this.setState({loading: false})
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
        const data = error.response.data;
        if (data.message) {
          message.error(data.message)
        }
        this.props.history.replace('/orders/list/');
      });
  }

  displayPrintDiv = () => {
    reqGetOrderItemsByOrderId(this.id)
      .then(response => {
        this.data = response.data;
        this.setState({print: true}, this.printDiv)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
      });
  };

  printDiv = () => {
    let divContents = document.getElementById("if_print").innerHTML;
    let a = window.open('', '', 'height=1000, width=1000');
    a.document.write('<html lang="en">');
    a.document.write('<body >');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.focus();
    setTimeout(function () {
      a.print();
      a.close();
    }, 500);
  }

  //checkbox change
  onChange = (selectedRowKeys, selectedRows) => {
    this.setState({selectedRowKeys});
  };

  //CheckBox select changed
  onSelectChange = (selectedRow) => {
    if (this.state.flag) return false;
    this.setState({flag: true})
    reqUpdateExcludeByOrderItemId(selectedRow.id)
      .then(response => {
        if (response.data) {
          message.success('Excluded!');
        } else {
          message.success('Unexcluded!');
        }
        this.setState({flag: false})
      })
      .catch(error => {
        console.log(error)
        console.log(error.response);
        this.setState({flag: false})
      });
  };

  getCheckboxProps = record => ({
    // eslint-disable-next-line
    disabled: record.retail != 0, // Column configuration not to be checked
  });

  moveBackFromPurgatory = order_id =>{
    Modal.confirm({
      title: `Are you sure you want to move this order back?`,
      onOk: () => {
        reqMoveBackFromPurgatory(order_id)
          .then(response => {
              this.props.history.push('/orders/edit/'+ order_id);
            }
          )
          .catch(error => {
            //console.log(error.response);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  }

  infoTitle = (order_number, display_address) => {
    return (
      <Row>
        <Col span={12}><span>Order# {order_number}</span></Col>
        {display_address && <Col offset={9} span={3}>
          <Address order_id={this.id}
                   onUpdateData={(data) => this.setState({invoice_address: data.invoice_address, fee: data.fee})}/>
        </Col>}
      </Row>
    )
  };

  initColumns = () => {
    this.columns = [
      {
        title: 'Type',
        render: (item) =>
          item.diamond_id ?
          <span>
                <Link to={'/inventory/view/' + item.diamond_id}>
                    <LinkButton>Diamond</LinkButton></Link>
            </span>: <span>{item.type}</span>

      },
      {
        title: 'Description',
        dataIndex: 'desc',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
        width: 100,
        render: (retail) => '$' + retail
      },
    ];
  };


  render() {
    const {loading, selectedRowKeys, fee, full_name, invoice_address, items_description, order, print} = this.state;
    const rowSelection = {
      columnTitle: ' ',
      selectedRowKeys,
      onChange: this.onChange,
      getCheckboxProps: this.getCheckboxProps,
      onSelect: this.onSelectChange
    };

    return (
      <>
        <Spin spinning={loading} size="large">
          {print && <Print data={this.data}/>}
          <div style={{padding: '10px', fontSize: '18px'}}>
          <span>
          <LinkButton onClick={() => this.props.history.goBack()}>
            <Icon type="arrow-left"/>
          </LinkButton>
          </span>
            <span>
              <Link to={'/customers/edit/' + this.customer_id}>
                <LinkButton>{full_name}</LinkButton>
              </Link>
          </span>

            <div style={{float: 'right', fontSize: '18px'}}>
              {
                !order.deleted_at &&
                <>
                  <Button onClick={this.displayPrintDiv}>Print Order</Button>
                  <Link to={'/orders/edit/' + order.id}>
                    <Button style={{margin: '0px 5px'}}
                            onClick={() => this.props.history.push('/orders/edit/' + this.id)}>
                      Edit Order</Button>
                  </Link>
                </>
              }
              {
                (!!order.deleted_at && (hasRole(this.user_type,['super admin']))) &&
                <Button onClick={() => this.moveBackFromPurgatory(order.id)}>Move Back</Button>
              }
            </div>
          </div>

          <div style={{background: '#ECECEC', padding: '15px'}}>
            <Card bordered={false} size="small" title={this.infoTitle(order.order_number, !!!order.deleted_at)}>
              <Descriptions bordered size='small' column={2}>
                <Descriptions.Item label="Address" span={2}>
                  {invoice_address.address_line1}
                  {invoice_address.address_line1 && <br/>}
                  {invoice_address.address_line2}
                </Descriptions.Item>
                <Descriptions.Item label="Order Date">{formatDate(order.order_date)}</Descriptions.Item>
                <Descriptions.Item label="Due Date">{formatDate(order.due_date)}</Descriptions.Item>
                <Descriptions.Item label="Sales">{order.sales_person}</Descriptions.Item>
                <Descriptions.Item label="Payment Type">{order.payment_type}</Descriptions.Item>
                <Descriptions.Item label="Job Description">{order.job_description}</Descriptions.Item>
                <Descriptions.Item label="Urgent">{order.urgent ? 'YES' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Status">{order.status}</Descriptions.Item>
                <Descriptions.Item label="Picked up">{formatDate(order.picked_up)}</Descriptions.Item>
                <Descriptions.Item label="Comments" span={2}>
                  <p style={{whiteSpace: 'pre-line'}}>{order.comments}</p>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </div>
          <div style={{background: '#ECECEC', padding: '0px 15px 15px 15px'}}>
            <Card>
              <Table
                rowSelection={rowSelection}
                columns={this.columns}
                dataSource={items_description}
                size='small'
                bordered={true} rowKey='id'
                pagination={false}/>
            </Card>
          </div>
          <div style={{padding: '10px 0px 10px 25px'}}>
            <Row>
              <Col span={12}>
                <PaymentTable id={this.id} displayAdd={false}/>
              </Col>
              <Col span={12}>
                <FeeList fee={fee} id={this.id} displayAdd={false}/>
              </Col>
            </Row>
          </div>

        </Spin>
      </>
    )
  }
}


export default View;