import React, {Component} from 'react';
import {Icon, Table, Card, Form, Checkbox, Row, Col} from 'antd';
import {Link} from 'react-router-dom';
import Search from '../../../components/Inventory/SearchDiamond/Search';
import {shapeSort} from '../../../utils/utility';
import LinkButton from '../../../components/LinkButton';
import {debounce} from 'lodash';
import {reqSearchPageDiamond} from '../../../api';
import {CSVLink} from 'react-csv';
import moment from 'moment';


const {Item} = Form;

class SearchDiamond extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      diamonds: [],
      csvData: [],
      diamond_values: {},
      location_values: {
        location: ["Instore", "In Manufacturing", "Set in Ring"],
        sold_to: false
      }
    }
    this.initColumns();
  }

  //refresh table
  refreshTable = () => {
    setTimeout(() => {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          //this.props.onSubmit(values);
          this.setState({location_values: values});
          const {diamond_values, location_values} = this.state;
          this.ajaxSearchDiamond(diamond_values, location_values);
        }
      });
    }, 0);
  }


  handleSubmit = values => {
    const search_value = this.validateFilter(values);
    this.setState({loading: true, diamond_values: search_value});
    const {diamond_values, location_values} = this.state;
    this.ajaxSearchDiamond(diamond_values, location_values);
  };

  validateFilter = values => {
    let {
      diamond_type, shape, carats_min, carats_max, colour_min, colour_max, clarity_min, clarity_max,
      price_min, price_max, id
    } = values;
    let carats = [carats_min, carats_max];
    let colour = [colour_min, colour_max];
    let clarity = [clarity_min, clarity_max];
    let price = [price_min, price_max];
    return {id, diamond_type, shape, carats, colour, clarity, price};
  }


  ajaxSearchDiamond = debounce((val1, val2) =>
    reqSearchPageDiamond(val1, val2)
      .then(response => {
        const diamonds = response.data;
        //csv
        const csvData = diamonds.map((item) => {
          const {
            id, name, diamond_type, shape, carats, colour, clarity, cut, measurements, certificate_type, certificate_number,
            comments, currency, true_cost_cad, shipping_cost_cad, true_cost_usd, shipping_cost_usd, market_cost_usd, currency_cost_cad,
            sell_cad, reduced_cad, diamond_location, diamond_state,buffer_rate
          } = item;
          let customer = "";
          if (item.last_name) {
            customer = item.last_name + ", " + item.first_name;
          }
          return {
            id,
            customer,
            name,
            diamond_type,
            shape,
            carats,
            colour,
            clarity,
            cut,
            measurements,
            certificate_type,
            certificate_number,
            comments,
            diamond_location,
            diamond_state,
            currency,
            true_cost_cad,
            shipping_cost_cad,
            true_cost_usd,
            shipping_cost_usd,
            market_cost_usd,
            buffer_rate,
            currency_cost_cad,
            sell_cad,
            reduced_cad,
          };
        });
        this.setState({diamonds, csvData, loading: false});
      })
      .catch(error => {
        console.log(error.response)
      }), 1500)

  initColumns = () => {
    this.columns = [
      {
        title: 'Location',
        dataIndex: 'diamond_location',
      },
      {
        title: 'Sold To',
        render: (item) =>
          item.order_id &&
          <span>
                <Link to={'/orders/view/' + item.order_id}>
                    <LinkButton>{`${item.last_name}, ${item.first_name}`}</LinkButton></Link>
            </span>,
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'diamond_type',
      },
      {
        title: 'Shape',
        dataIndex: 'shape',
      },
      {
        title: 'Carats',
        dataIndex: 'carats',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'carats', 'number'),
      },
      {
        title: 'Colour',
        dataIndex: 'colour',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'colour', 'letter'),
      },
      {
        title: 'Clarity',
        dataIndex: 'clarity',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'clarity', 'letter'),
      },
      {
        title: 'Cut',
        dataIndex: 'cut',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'cut', 'letter'),
      },
      {
        title: 'Sell',
        dataIndex: 'sell_cad',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'sell_cad', 'number'),
      },
      {
        title: 'Reduced',
        dataIndex: 'reduced_cad',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'reduced_cad', 'number'),
      },
      {
        title: 'Detail',
        render: (item) =>
          <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
            </span>
      }
    ];
  }

  render() {
    const {loading, diamonds, csvData} = this.state;
    const {getFieldDecorator} = this.props.form;

    return (
      <Card>
        <div style={{paddingBottom: '15px'}}>
          <Form>
            <Row>
              <Col span={5}>
                <Item label="Unsold Only" labelCol={{span: 15}} wrapperCol={{span: 9}}>
                  {getFieldDecorator('sold_to', {
                    initialValue: false,
                    valuePropName: 'checked',
                  })(
                    <Checkbox onChange={this.refreshTable}/>
                  )}
                </Item>
              </Col>
              <Col span={19}>
                <Item label="Location" labelCol={{span: 4}} wrapperCol={{offset: 1, span: 19}}>
                  {getFieldDecorator('location', {
                    initialValue: ['Instore', 'In Manufacturing', 'Set in Ring'],
                    rules: [
                      {required: true, message: 'Required'}
                    ]
                  })(
                    <Checkbox.Group style={{width: '100%'}} onChange={this.refreshTable}>
                      <Col span={5}>
                        <Checkbox value="Instore">Instore</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="In Manufacturing">In Manufacturing</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="Set in Ring">Set in Ring</Checkbox>
                      </Col>
                    </Checkbox.Group>
                  )}
                </Item>
              </Col>
            </Row>
          </Form>
          <Search setForm={form => this.form = form} onSubmit={this.handleSubmit}/>
          <div style={{float: 'right', marginBottom: '15px'}}>
            <CSVLink
              data={csvData}
              filename={`${moment().format('YYYY-MM-DD')}-diamonds.csv`}
            >
              Download
            </CSVLink>
          </div>
        </div>
        <Table
          loading={loading}
          rowKey='id'
          columns={this.columns}
          dataSource={diamonds}
          size="small"
          pagination={false}
        />
      </Card>
    );
  }

}

export default Form.create()(SearchDiamond);