import React, {Component} from 'react';
import { Layout } from 'antd';
import SideBar from "../SideBar";
import Header from "../Header";
import AdminRouter from "../../routes/AdminRouter";

const { Footer, Content } = Layout;

export default class Dashboard extends Component {

  render() {
    return (
      <Layout style={{minHeight:window.innerHeight + 'px'}}>
          <SideBar />
        <Layout style={{ marginLeft: 200 }}>

          <Header/>

          <div className="wrapper">

            <Content style={{background:'white', margin:'20px',overflow: 'initial'}}>

              <AdminRouter />

            </Content>

            <Footer style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.5)'}} className="footer">
              2020 ENGAGE DIAMOND STUDIO
            </Footer>

          </div>
        </Layout>
      </Layout>
    );
  }
}