import React, {Component} from 'react';
import {Modal, Table, message, Button} from "antd";
import {
  reqUpdateStatusToCompletedByOrderItemId,
  reqGetOrderItemsByVendor, reqUpdateStatusByOrderItemId, reqUpdateLocationByDiamondIds
} from '../../api';
import {formatDate} from '../../utils/utility';
import LinkButton from '../LinkButton';
import UpdateStatusForm from './UpdateStatusForm';
import {Link} from 'react-router-dom';
import CheckDiamond from '../Inventory/CheckDiamond';

class VendorTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      order_items: [],
      showDialog: false,
      checkDiamonds: [],
      showCheckDiamond: false
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    reqGetOrderItemsByVendor(this.props.id)
      .then(response => {
        const order_items = response.data;
        this.setState({order_items});
        this.order_items = order_items;
      })
      .catch(error => {
        console.log(error)
      });
  }

  //changeStatus
  handleChangeStatus = () => {
    Modal.confirm({
      title: `Are you sure you want to change all selected items' status to Completed ?`,
      onOk: () => {
        reqUpdateStatusToCompletedByOrderItemId(this.selectedOrderItem)
          .then(response => {
            //delete all records from table
            //const orderItemsAfterDelete = this.state.order_items.filter(item => !this.selectedOrderItem.includes(item.id));
            //this.setState({order_items: orderItemsAfterDelete});

            //change status to completed
            const orderItemsAfterDelete = this.state.order_items.map(item => {
              if (this.selectedOrderItem.includes(item.id)) {
                item.status = 'Completed'
              }
              return item
            });
            this.setState({order_items: orderItemsAfterDelete});
            message.success('Updated Status to Completed Successfully');

            //show check diamond dialog
            const diamonds = response.data;
            if (diamonds.length > 0) {
              this.setState({checkDiamonds: diamonds, showCheckDiamond: true})
              this.selectedDiamonds = diamonds;
            }

          })
          .catch(error => {
            console.log(error)
          });
      },
      onCancel() {
      },
    })
  };

  changeLocationToSetInRing = () => {
    const selectedDiamondsId = this.selectedDiamonds.map(item => item.id);
    reqUpdateLocationByDiamondIds(selectedDiamondsId, 'Set in Ring')
      .then(response => {
        message.success('Location Changed Successfully');
        this.setState({showCheckDiamond: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  getDiamonds = (diamonds) => this.selectedDiamonds = diamonds;

  //show update Dialog
  showUpdate = (order_item) => {
    this.order_item = order_item;
    this.setState({showDialog: true})
  };

  //update status
  updateStatus = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const {status} = values;
        const order_item_id = this.order_item.id;
        reqUpdateStatusByOrderItemId(order_item_id, status)
          .then(response => {
            this.setState((prevState) => ({
                order_items: prevState.order_items.map(item => {
                    if (item.id === order_item_id) {
                      item = {...item, status: status}
                    }
                    return item
                  }
                )
              }
            ));
            this.setState({showDialog: 0});
            message.success('Updated Status Successfully!');
            this.order_item = {};
            this.form.resetFields();
          })
          .catch(error => {
            console.log(error)
          });
      }
      // this.order_item = {};
      // this.form.resetFields();
    })
  }

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Order#',
        key: 'order_number',
        render: (item) =>
          <span>
            <Link to={'/orders/edit/' + item.order_id}>
            <LinkButton>{item.order_number}</LinkButton></Link>
          </span>
      },
      {
        title: 'PO#',
        dataIndex: 'po_number'
      },
      {
        title: 'Order Date',
        dataIndex: 'order_date',
        render: formatDate
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: formatDate
      },
      {
        title: 'Customer',
        render: (customer) => `${customer.last_name}, ${customer.first_name}`
      },
      {
        title: 'Job Description',
        dataIndex: 'job_description'
      },
      {
        title: 'Item Type',
        dataIndex: 'type',
      },
      {
        title: 'Job Type',
        dataIndex: 'job_type',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'Job Status',
        dataIndex: 'status',
      },
      {
        title: 'Action',
        render: (order_item) =>
          <span>
            <LinkButton onClick={() => this.showUpdate(order_item)}>Edit</LinkButton>
          </span>
      }
    ]
  }

  render() {

    const {order_items, showDialog, checkDiamonds} = this.state;

    // rowSelection object indicates the need for row selection
    const rowSelection = {
      columnTitle: ' ',
      onChange: (selectedRowKeys) => {
        this.selectedOrderItem = selectedRowKeys;
      },
    };

    return (
      <React.Fragment>
        <div style={{paddingBottom: '12px'}}>
          <Button type="primary" onClick={this.handleChangeStatus}>
            Change Status to Completed
          </Button>
        </div>
        <Modal
          width={1000}
          visible={this.state.showCheckDiamond}
          okText='Change Location to Set in Ring'
          onOk={this.changeLocationToSetInRing}
          onCancel={() => this.setState({showCheckDiamond: false})}>
          <CheckDiamond
            diamonds={checkDiamonds}
            rowSelected
            onGetDiamonds={this.getDiamonds}
          />
        </Modal>
        <Table
          className='vendor-table'
          rowKey='id'
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={order_items}
          size="small"
          rowSelection={rowSelection}
          scroll={{x: true}}
        />
        <Modal
          title='Update Status'
          visible={showDialog}
          onOk={this.updateStatus}
          onCancel={() => {
            this.setState({showDialog: false});
            this.form.resetFields();
          }}
        >
          <UpdateStatusForm order_item={this.order_item} setForm={form => this.form = form}/>
        </Modal>
      </React.Fragment>
    )
  }
}

export default VendorTable;