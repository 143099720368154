import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Modal} from 'antd'
import './index.less';
import LinkButton from "../LinkButton";
import {reqLogout} from "../../api";
import {getUserName} from '../../utils/auth';

class Header extends Component {

  constructor(props) {
    super(props);
    this.username = getUserName();
  }

//logout
  logout = () => {
    Modal.confirm({
      title: 'Are you sure you want to logout?',
      onOk: () => {
        reqLogout()
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.log(error.response);
          })
        localStorage.removeItem('token');
        this.props.history.replace('/login');

      },
      onCancel() {
      },
    })
  };

  render() {
    const path = this.props.location.pathname;

    return (
      <div className="header">
        <div className="header-top">
          <span>Welcome, {this.username} </span>
          <LinkButton onClick={this.logout}>Logout</LinkButton>
        </div>
        <div className="header-bottom">
          <div className="header-bottom-left">{path.split("/")[1]}</div>
          <div className="header-bottom-right">
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);