import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Select} from 'antd';
import {
  STATUS_DIAMOND, STATUS_JEWELLERY, STATUS_REPAIR,
  STATUS_STONE, STATUS_STYLE, STATUS_COMPONENT
} from '../../utils/const';

const {Item} = Form;
const {Option} = Select;

class UpdateStatusForm extends Component {

  constructor(props) {
    super(props);
    this.props.setForm(this.props.form);
  }

  //get status select option from item type
  getStatusOption = (item_type) => {
    switch (item_type) {
      case 'diamond':
        return STATUS_DIAMOND;
      case 'repair':
        return STATUS_REPAIR;
      case 'jewellery':
        return STATUS_JEWELLERY;
      case 'style':
        return STATUS_STYLE;
      case 'stone':
        return STATUS_STONE;
      case 'component':
        return STATUS_COMPONENT;
      default:
        return [];
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {status, type, po_number, job_description, job_type} = this.props.order_item;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 15}
    };

    return (
      <Form {...formLayout}>
        <Item label="PO#">
          <span className="ant-form-text">{po_number}</span>
        </Item>
        <Item label="Job Description">
          <span className="ant-form-text">{job_description}</span>
        </Item>
        <Item label="Job Type">
          <span className="ant-form-text">{job_type}</span>
        </Item>
        <Item label="Status">
          {getFieldDecorator('status', {
            initialValue: status,
          })(
            <Select>
              {
                this.getStatusOption(type).map(item => <Option value={item} key={item}> {item} </Option>)
              }
            </Select>
          )}
        </Item>
      </Form>
    )
  }
}

UpdateStatusForm.propTypes = {
  setForm: PropTypes.func.isRequired,
  order_item: PropTypes.object,
};

export default Form.create()(UpdateStatusForm)