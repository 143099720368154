import React, {Component} from 'react';
import {Col, Icon, message, Modal, Row, Table} from "antd";
import LinkButton from "../../LinkButton";
import {reqAddNote, reqDeleteNote, reqGetNotesByOrderId, reqUpdateNote} from "../../../api";
import {formatDate, momentToString} from "../../../utils/utility";
import NoteAddUpdateForm from "./NoteAddUpdateForm";

class NoteTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      notes: [],
      addUpdateDialogShow: 0, //0:hide; 1:Add, 2:Update

    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    reqGetNotesByOrderId(this.props.id)
      .then(response => {
        const notes = response.data;
        this.setState({notes});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //create and update
  onAddUpdate = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const order_id = this.props.id;
        let {note, note_date} = values;
        note_date = momentToString(note_date);
        let newNote = {note, note_date, order_id};

        if (this.state.addUpdateDialogShow === 1) {//add
          reqAddNote(newNote)
            .then(response => {
              this.setState({notes: response.data});
              this.setState({addUpdateDialogShow: 0});
              message.success('Added Successfully!');
            })
            .catch(error => {
              console.log(error)
            });
        } else if (this.state.addUpdateDialogShow === 2) {//update
          const id = this.note.id;
          reqUpdateNote(id, newNote)
            .then(response => {
              this.setState({notes: response.data});
              this.setState({addUpdateDialogShow: 0});
              message.success('Updated Successfully!');
            })
            .catch(error => {
              console.log(error)
            });
        }
        this.form.resetFields();
        this.note = {};
      }
    })
  };

  //Delete
  onDelete = (note) => {
    Modal.confirm({
      title: "Are you sure you want to delete the note ?",
      onOk: async () => {
        reqDeleteNote(note.id)
          .then(response => {
            const notesAfterDelete = this.state.notes.filter(item => !(item.id === note.id));
            this.setState({notes: notesAfterDelete});
            message.success('Deleted Successfully!');
          })
          .catch(error => {
            console.log(error)
          });

      },
      onCancel() {
      },
    })
  };

  //show add Dialog
  showAdd = () => {
    this.note = {};
    this.setState({addUpdateDialogShow: 1})
  };

  //show update Dialog
  showUpdate = (note) => {
    this.note = note;
    this.setState({addUpdateDialogShow: 2})
  };

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Date',
        dataIndex: 'note_date',
        render: formatDate
      },
      {
        title: 'Note',
        dataIndex: 'note',
      },
      {
        title: 'Editor',
        dataIndex: 'user',
        render: (user) => (user.full_name)
      },
      {
        title: 'Action',
        render: (note) =>
          <span>
            <LinkButton onClick={() => this.showUpdate(note)}><Icon type="edit"/></LinkButton>
            <LinkButton onClick={() => this.onDelete(note)}><Icon type="delete"/></LinkButton>
          </span>
      },
    ];
  };

  title = (
    <Row>
      <Col span={2}>
        Notes
      </Col>
      <Col span={8}>
        <LinkButton onClick={() => this.showAdd()}><span>Add Note</span></LinkButton>
      </Col>
    </Row>
  );

  render() {

    const {notes, addUpdateDialogShow} = this.state;


    return (
      <div className="order-note-table" style={{paddingRight: '16px'}}>
        <Table
          bordered={false}
          title={() => this.title}
          rowKey='id'
          dataSource={notes}
          columns={this.columns}
          pagination={false}
          size="middle"
        />
        <Modal
          title={addUpdateDialogShow === 1 ? 'Add Note' : 'Update Note'}
          visible={addUpdateDialogShow !== 0}
          onOk={this.onAddUpdate}
          onCancel={() => {
            this.form.resetFields();
            this.setState({addUpdateDialogShow: 0})
          }}
        >
          <NoteAddUpdateForm note={this.note} setForm={form => this.form = form}/>
        </Modal>
      </div>
    )
  }

}

export default NoteTable;