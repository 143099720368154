import React, {Component} from 'react';
import {Button, Card, Icon, Input, Select, Table} from 'antd';
import LinkButton from "../../../components/LinkButton";
import {reqGetOffSiteDiamonds} from "../../../api";
import {Link} from 'react-router-dom';

const PAGE_SIZE = 20;
const Option = Select.Option;

export default class OffSite extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      diamonds: [],
      currentPage: 1,
      total: 0,
      searchValue: '',
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    this.ajaxGetOffSiteDiamonds();
  }

  ajaxGetOffSiteDiamonds = () => {
    this.setState({loading: true});
    let {currentPage,searchValue} = this.state;
    reqGetOffSiteDiamonds({currentPage, per_page:PAGE_SIZE,searchValue})
      .then(response => {
        const diamonds = response.data.data;
        const total = response.data.total;
        this.setState({diamonds,total});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  //sort
  handleTableChange = (pagination) => {
    window.scrollTo({top: 100, behavior: 'smooth'});
    let page = pagination.current;
    this.setState({currentPage: page},()=>this.ajaxGetOffSiteDiamonds());
  };

  //Search
  onSearchDiamonds = () => {
    this.setState({currentPage: 1},()=>this.ajaxGetOffSiteDiamonds());
  };

  initColumns = () => {
    this.columns =
      [
        {
          title: 'Type',
          dataIndex: 'diamond_type',
          render: (diamond_type) => diamond_type === 'NATURAL' ? <span style={{color: 'red'}}>{diamond_type} </span> :
            <span>{diamond_type}</span>,
        },
        {
          title: 'Shape',
          dataIndex: 'shape',
        },
        {
          title: 'Carats',
          dataIndex: 'carats',
        },
        {
          title: 'Colour',
          dataIndex: 'colour',
        },
        {
          title: 'Clarity',
          dataIndex: 'clarity',
        },
        {
          title: 'Cut',
          dataIndex: 'cut',
        },
        {
          title: 'Cert. Type',
          dataIndex: 'certificate_type',
        },
        {
          title: 'Cert. No',
          dataIndex: 'certificate_number',
        },
        {
          title: 'Comments',
          dataIndex: 'comments',
        },
        {
          title: 'Sold To',
          render: (item) =>
            item.order_id &&
            <span>
                <Link to={'/orders/view/' + item.order_id}>
                    <LinkButton>{`${item.last_name}, ${item.first_name}`}</LinkButton></Link>
            </span>,
        },
        {
          title: 'Detail',
          fixed: 'right',
          render: (item) =>
            <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
            </span>
        }
      ]
  }

  render() {
    const {loading, diamonds,total,currentPage,searchValue} = this.state;

    const title = (
      <span>
        <Select
          style={{width: 100}}
          value='Cert. #'
          showArrow={false}
        >
          <Option value="certificate_number">Cert. #</Option>
        </Select>
        <Input
          style={{width: 200, margin: '0 10px'}}
          value={searchValue}
          onChange={event => this.setState({searchValue: event.target.value})}
          onPressEnter={this.onSearchDiamonds}
        />
        <Button type="primary" onClick={() => {
          this.onSearchDiamonds();
        }}>SEARCH</Button>
      </span>
    );

    return (
      <div className="customer">
        <Card title={title}>
          <Table
            rowKey="id"
            loading={loading}
            dataSource={diamonds}
            columns={this.columns}
            onChange={this.handleTableChange}
            pagination={{
              total,
              defaultPageSize: PAGE_SIZE,
              showQuickJumper: true,
              current: currentPage,
            }}
            size="middle"
            scroll={{ x: true }}
          />
        </Card>
      </div>
    );
  }
}
