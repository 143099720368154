import React, {Component} from 'react';
import {Divider, Icon, Modal, Table} from 'antd';
import Search from './Search';
import {debounce} from 'lodash';
import {reqSearchDiamond} from '../../../api';
import {shapeSort} from '../../../utils/utility';
import {Link} from 'react-router-dom';
import LinkButton from '../../LinkButton';

export default class SearchDiamonds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      diamonds: [],
      showSearch: false
    }
    this.initColumns();
  }

  handleSubmit = values => {
    const search_value = this.validateFilter(values);
    this.setState({loading: true});
    this.ajaxSearchDiamond(search_value);
  };

  validateFilter = values => {
    let {
      diamond_type, shape, carats_min, carats_max, colour_min, colour_max, clarity_min, clarity_max,
      price_min, price_max,id
    } = values;
    let carats = [carats_min, carats_max];
    let colour = [colour_min, colour_max];
    let clarity = [clarity_min, clarity_max];
    let price = [price_min, price_max];
    return {id, diamond_type, shape, carats, colour, clarity, price};
  }

  getDiamond = diamond => {
    this.setState({showSearch: false});
    this.props.onPickDiamond(diamond);
  }

  ajaxSearchDiamond = debounce((search_value) =>
    reqSearchDiamond(search_value)
      .then(response => {
        const diamonds = response.data;
        this.setState({diamonds});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
      }), 1500)

  initColumns = () => {
    this.columns = [{
      title: 'Vendor',
      dataIndex: 'name',
    },
      {
        title: 'Type',
        dataIndex: 'diamond_type',
      },
      {
        title: 'Shape',
        dataIndex: 'shape',
        sorter: (a, b) => a.shape.localeCompare(b.shape),
      },
      {
        title: 'Carats',
        dataIndex: 'carats',
        sorter: (a, b) => a.carats.localeCompare(b.carats),
      },
      {
        title: 'Colour',
        dataIndex: 'colour',
        sorter: (a, b) => a.colour.localeCompare(b.colour),
      },
      {
        title: 'Clarity',
        dataIndex: 'clarity',
        sorter: (a, b) => a.clarity.localeCompare(b.clarity),
      },
      {
        title: 'Cut',
        dataIndex: 'cut',
      },
      {
        title: 'Cert. Type',
        dataIndex: 'certificate_type',
      },
      {
        title: 'Cert. No',
        dataIndex: 'certificate_number',
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
      },
      {
        title: 'Sell',
        dataIndex: 'sell_cad',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'sell_cad', 'number'),
      },
      {
        title: 'Reduced',
        dataIndex: 'reduced_cad',
        sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'reduced_cad', 'number'),
      },
      {
        title: 'Detail',
        render: (item) =>
          <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
              <Divider type="vertical"/>
              <LinkButton onClick={() => this.getDiamond(item)}>PICK</LinkButton>
            </span>
      }
    ];
  }

  render() {
    const {loading, diamonds, showSearch} = this.state;

    return (
      <>
        <LinkButton onClick={() => this.setState({showSearch: true})}>SEARCH DIAMOND</LinkButton>
        <Modal
          width={1400}
          title="Search Diamond"
          visible={showSearch}
          onOk={this.handleAddUpdate}
          onCancel={() => this.setState({showSearch: false})}>
          <Search setForm={form => this.form = form} onSubmit={this.handleSubmit}/>
          <Table
            loading={loading}
            rowKey='id'
            columns={this.columns}
            dataSource={diamonds}
            size="small"
            pagination={false}
          />
        </Modal>
      </>
    );
  }

}