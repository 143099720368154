import React, {Component} from 'react';
import {Form, DatePicker, Input, Select, Col, Checkbox, Row, Modal, message} from 'antd';
import {reqGetVendorsPlusDelete, reqUpdateLocationByDiamondIds} from "../../../api";
import {CERTIFICATE_TYPE, CLARITY, COLOUR, CUT, SHAPE, STATUS_DIAMOND} from "../../../utils/const";
import moment from 'moment';
import _ from 'lodash';
import {regexMoney} from "../../../utils/utility";
import LinkButton from "../../LinkButton";
import SelectInput from '../../SelectInput';
import SearchDiamonds from '../../Inventory/SearchDiamond';

const {Item} = Form;
const {Option} = Select;

class DiamondAddUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
    };
    props.setForm(props.form)
  }

  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'diamond', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  pickDiamond = diamond => {
    //get diamond from SearchDiamonds components
    const {
      id, carats, measurements, certificate_number, sell_cad, vendor_id, shape, colour, clarity, cut, certificate_type
    } = diamond;
    //assign value to form field
    this.props.form.setFieldsValue({
      carats, measurements, certificate_number, id, shape, colour, clarity, cut, certificate_type,
      requires_approval_stone: false, approved_stone: false, retail: sell_cad,
      vendor_id, status: 'Completed'
    })
  }

  changeLocationToManufacturing = () => {
    const selectedItems = [this.props.order_item.id];
    Modal.confirm({
      title: `Are you sure you want move the diamond to "In Manufacturing"?`,
      onOk: () => {
        reqUpdateLocationByDiamondIds(selectedItems, 'In Manufacturing')
          .then(response => {
            message.success('Diamond Location is In Manufacturing');
            this.props.hideDialog();
          })
          .catch(error => {
            console.log(error)
          });
      },
      onCancel() {
      },
    })
  };


  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      carats, measurements, certificate_number, start_date, requires_approval_stone, stone_sold_date,
      approved_stone, retail, vendor_id, status, shape, colour, clarity, cut, certificate_type, id
    } = this.props.order_item;
    const {vendors} = this.state;

    return (
      <>
        <Form>
          <Row>
            <Col span={12}>
              {(_.isEmpty(this.props.order_item)) &&
              <SearchDiamonds onPickDiamond={this.pickDiamond}/>}
            </Col>
            <Col span={12}>
              <Item label='Diamond ID' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('id', {
                    initialValue: id || '',
                  })(<Input disabled/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="Shape" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('shape', {
                  initialValue: shape || '',
                  rules: [
                    {required: true, message: 'Shape is required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={SHAPE}/>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label='Carats' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('carats', {
                    initialValue: carats || '',
                    rules: [
                      {required: true, message: 'Carats is required'},
                      {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/, message: 'Number with 4 decimal places Only',}
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="Colour" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('colour', {
                  initialValue: colour || '',
                  rules: [
                    {required: true, message: 'Colour is required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={COLOUR}/>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Clarity" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('clarity', {
                  initialValue: clarity || '',
                  rules: [
                    {required: true, message: 'Clarity is required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={CLARITY}/>
                )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="Cut" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('cut', {
                  initialValue: cut || '',
                  rules: [
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={CUT}/>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label='Measurements' labelCol={{span: 9}} wrapperCol={{span: 13}}>
                {
                  getFieldDecorator('measurements', {
                    initialValue: measurements || '',
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="Certificate Type" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('certificate_type', {
                  initialValue: certificate_type || '',
                  rules: [
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={CERTIFICATE_TYPE}/>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label='Certificate Number' labelCol={{span: 9}} wrapperCol={{span: 13}}>
                {
                  getFieldDecorator('certificate_number', {
                    initialValue: certificate_number || '',
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label='Retail' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('retail', {
                    initialValue: retail || '',
                    rules: [
                      {required: true, message: 'retail is required'},
                      {
                        pattern: regexMoney,
                        message: 'Number with 2 decimal places Only',
                      }
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Stone Sold Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('stone_sold_date', {
                    initialValue: !(stone_sold_date) ? moment() : moment(stone_sold_date),
                  })(
                    <DatePicker/>
                  )
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Item label="Require Stone" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('requires_approval_stone', {
                  initialValue: requires_approval_stone || false,
                  valuePropName: 'checked',
                })(
                  <Checkbox></Checkbox>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Approve Stone" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('approved_stone', {
                  initialValue: approved_stone || false,
                  valuePropName: 'checked',
                })(
                  <Checkbox></Checkbox>
                )}
              </Item>
            </Col>
          </Row>
          {
            //if order_item exists(update diamond), vendor_id and status exist(search diamond), display vendor and status
            (!(_.isEmpty(this.props.order_item)) || this.props.form.getFieldValue('vendor_id') ||
              this.props.form.getFieldValue('status')) &&
            (
              <React.Fragment>
                <Row>
                  <Col span={12}>
                    <Item label="Vendor" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                      {getFieldDecorator('vendor_id', {
                        initialValue: vendor_id || '',
                      })(
                        <Select
                          showSearch
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          }
                        >
                          {
                            vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                        key={item.id}> {item.name} </Option>)
                          }
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={12}>
                    <Item label="Start Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                      {
                        getFieldDecorator('start_date', {
                          initialValue: !(start_date) ? null : moment(start_date),
                        })(
                          <DatePicker/>
                        )
                      }
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Item label="Status" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                      {getFieldDecorator('status', {
                        initialValue: status || '',
                      })(
                        <Select>
                          {
                            STATUS_DIAMOND.map(item => <Option value={item} key={item}> {item} </Option>)
                          }
                        </Select>
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <LinkButton onClick={() => this.props.deletePO()}>DELETE PO#</LinkButton>
                  </Col>
                  <Col span={18}>
                    <LinkButton onClick={() => this.changeLocationToManufacturing()}>Change Location to In
                      Manufacturing</LinkButton>
                  </Col>
                </Row>
              </React.Fragment>
            )
          }
        </Form>
      </>
    )
  }
}

export default Form.create()(DiamondAddUpdateForm)