import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'antd';

const {Item} = Form;

class PasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
    this.props.setForm(this.props.form);
  }

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleConfirmBlur = e => {
    const {value} = e.target;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {username} = this.props.user;

    return (
      <Form>
        <Item label="Username">
          <span className="ant-form-text">{username}</span>
        </Item>
        <Item label="Password" hasFeedback>
          {getFieldDecorator('password', {
            initialValue:'',
            rules: [
              {
                required: true,
                message: 'Please input your password!',
              },
              {max: 80, message: 'Maximum 80 characters'},
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password/>)}
        </Item>
        <Item label="Confirm Password" hasFeedback>
          {getFieldDecorator('confirm', {
            initialValue:'',
            rules: [
              {
                required: true,
                message: 'Please confirm your password!',
              },
              {max: 80, message: 'Maximum 80 characters'},
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur}/>)}
        </Item>
      </Form>
    )
  }
}

PasswordForm.propTypes = {
  setForm: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default Form.create()(PasswordForm)