import React, {Component} from 'react';
import {Badge, Calendar} from "antd";
import moment from 'moment';
import {reqGetCalendar} from '../../api';

const DATE = 'YYYY-MM-DD';
const MOUTH = 'YYYY-MM';

export default class VendorCalendar extends Component{

  constructor(props) {
    super(props);
    this.state = {
      calendars: [], //vendor list
    };
  }

  componentDidMount() {
    reqGetCalendar()
      .then(response => {
        const calendars = response.data;
        this.setState({calendars});
      })
      .catch(error => {
        console.log(error)
      });
  }

   getListData = (value,format) =>{
    let calendarDate = this.state.calendars;
    let valueDate = moment(value).format(format);
    let listData = [];
    for (let i = 0; i < calendarDate.length; i++) {
      let start_date = calendarDate[i].start_date;
      let date = moment(start_date).format(format);
      if (valueDate === date) {
        if(calendarDate[i].type === 'repair'){
          listData.push({type: 'error', content: calendarDate[i].po_number})
        }
        else{
          listData.push({type: 'success', content: calendarDate[i].po_number})
        }
      }
    }
    return listData;
  }

   cellRender = (value,format) =>{
    const listData = this.getListData(value,format);
    return (
      <ul className="events">
        {listData.map(item => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content}/>
          </li>
        ))}
      </ul>
    );
  }

  render(){
    return <Calendar
      dateCellRender={(value)=>this.cellRender(value,DATE)}
      monthCellRender={(value)=>this.cellRender(value,MOUTH)} />
  }
}