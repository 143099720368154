import React, {Component} from 'react';
import {Icon, Table, Divider} from 'antd';
import LinkButton from "../../components/LinkButton";
import {reqGetOrdersByStatusJobDescription} from "../../api";
import {formatDate} from "../../utils/utility";
import {Link} from 'react-router-dom';
import './repair.less';

export default class List extends Component {


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      orders: []
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.ajaxGetAllOrders();

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.status !== prevProps.status) {
      this.ajaxGetAllOrders();
    }
  }

  ajaxGetAllOrders = () => {
    this.setState({loading: true});
    reqGetOrdersByStatusJobDescription(this.props.status, 'repair')
      .then(response => {
        const orders = response.data;
        this.orders = orders;
        this.setState({orders});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
      });
  };


  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Order#',
        dataIndex: 'order_number'
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
      },
      {title: 'Job Description', dataIndex: 'job_description', key: 'job_description'},
      {
        title: 'Order Date',
        dataIndex: 'order_date',
        render: formatDate,
        sorter: (a, b) => new Date(a.order_date) - new Date(b.order_date),
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: formatDate,
        sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
      },
      {
        title: 'Days To Due',
        dataIndex: 'days_to_due',
        render: days_to_due => days_to_due && (days_to_due + " days"),
        sorter: (a, b) => a.days_to_due - b.days_to_due,
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Salesperson',
        dataIndex: 'sales',
        sorter: (a, b) => a.sales.localeCompare(b.sales),
      },

      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Action',
        key: 'operation',
        render: (order) =>
          <span>
            <Link to={'/orders/view/' + order.id}>
            <LinkButton onClick={() => this.props.history.push('/orders/view/' + order.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
            {this.props.status !== 'Purgatory' &&
            <>
              <Divider type="vertical"/>
              <Link to={'/orders/edit/' + order.id}>
                <LinkButton onClick={() => this.props.history.push('/orders/edit/' + order.id)}><Icon
                  type="edit"/></LinkButton>
              </Link>
            </>
            }
          </span>
      },
    ];
  }


  render() {
    const {loading, orders} = this.state;

    const expandedRowRender = (record) => {
      const columns = [
        {title: 'Job Type', dataIndex: 'job_type'},
        {title: 'Status', dataIndex: 'status'},
        {title: 'Vendor', dataIndex: 'vendor',},
        {title: 'Start Date', dataIndex: 'start_date',render: formatDate,},
        {title: 'PO#', dataIndex: 'po_number'},
        {title: 'Retail', dataIndex: 'retail'},
        {title: 'Item Description', dataIndex: 'item_description',},
        {title: 'Repair Description', dataIndex: 'repair_description',},

      ];

      return <Table className='repair-detail' columns={columns} dataSource={record.items} pagination={false} size='small' rowKey="id"/>;
    };


    return (
      <Table
        className="components-table-demo-nested"
        columns={this.columns}
        expandedRowRender={expandedRowRender}
        dataSource={orders}
        defaultExpandAllRows={true}
        rowKey="id"
        pagination={false}
        loading={loading}
      />

    );
  }
}
