import React, {Component} from "react";
import {HashRouter, Switch, Route} from 'react-router-dom';
import Layout from "../components/Layout";
import ScrollToTop from "./ScrollToTop";
import Login from "../pages/Login";

class AppRouter extends Component {
  render() {
    return (
      <HashRouter>
          <ScrollToTop>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route path="/" component={Layout}/>
            </Switch>
          </ScrollToTop>
      </HashRouter>
    );
  }
}

export default AppRouter;