import React, {Component} from 'react';
import {Card, Collapse} from 'antd';
import {reqGetVendorsWithOpenOrder} from '../../api';
import VendorTable from '../../components/Vendor/VendorTable';

const {Panel} = Collapse;

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [], //vendor list
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    //get vendors
    reqGetVendorsWithOpenOrder()
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error)
      });
  }

  render() {
    const {vendors} = this.state;

    return (
      <>
        <Card>
          <Collapse>
            {
              vendors.map(item => (
                <Panel key={item.id} header={item.name}>
                  <VendorTable id={item.id}/>
                </Panel>
              ))
            }
          </Collapse>
        </Card>
      </>
    )
  }
}