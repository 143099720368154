import React, {Component} from 'react'
import {Switch, Route, Redirect, withRouter} from 'react-router-dom'
import OrderRouter from "./OrderRouter";
import Dashboard from "../pages/Dashboard";
import CustomerRouter from "./CustomerRouter";
import VendorList from "../pages/Vendor/List";
import VendorDashboard from "../pages/Vendor/Dashboard";
import User from "../pages/User/List";
import AccountsReceivable from "../pages/Report/AccountsReceivable";
import CostReport from "../pages/Report/CostReport";
import VendorCalendar from '../pages/Vendor/VendorCalendar';
import {getUserType, hasRole} from '../utils/auth';
import InventoryRouter from './InventoryRouter';
import BuyCalculator from '../pages/Calculator/BuyCalculator';
import SellCalculator from '../pages/Calculator/SellCalculator';
import Repair from "../pages/Repair/List";

class AdminRouter extends Component {

  constructor(props) {
    super(props);
    this.user_type = getUserType();
  }

  render() {
    if (!this.user_type) {
      return <Redirect to="/login"/>
    }
    return (
      <Switch>
        <Route path="/home" exact component={Dashboard}/>
        <Route path='/customers' component={CustomerRouter}/>
        <Route path='/orders' component={OrderRouter}/>
        <Route path="/repairs/Incomplete" exact render={(props) => <Repair status='Incomplete' {...props} />}/>
        <Route path="/repairs/InProgress" exact render={(props) => <Repair status='In Progress' {...props} />}/>
        <Route path="/repairs/ReadyForPickup" exact render={(props) => <Repair status='Ready For Pickup' {...props} />}/>
        <Route path='/repairs' component={CustomerRouter}/>
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/reports/accountReceivable' exact component={AccountsReceivable}/>}
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/reports/cost' exact component={CostReport}/>}
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/vendors/list' component={VendorList}/>}
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/vendors/dashboard' component={VendorDashboard}/>}
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/vendors/calendar' component={VendorCalendar}/>}
        {hasRole(this.user_type, ['super admin']) && <Route path='/users' component={User}/>}
        <Route path='/inventory' component={InventoryRouter}/>}
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/calculator/buy' component={BuyCalculator}/>}
        {hasRole(this.user_type, ['super admin', 'production']) &&
        <Route path='/calculator/sell' component={SellCalculator}/>}
        <Redirect to="/home"/>
      </Switch>
    )
  }
}

export default withRouter(AdminRouter);