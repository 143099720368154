import React, { Component } from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import List from "../pages/Customer/List";
import AddUpdate from "../pages/Customer/ViewAddUpdate";

class CustomerRouter extends Component {

  render () {
    return (
      <Switch>
        <Route path="/customers/list" exact component={List}/> {/* exact: exactly match*/}
        <Route path="/customers/edit/:id" exact component={AddUpdate}/>
        <Route path="/customers/view/:id" exact component={AddUpdate}/>
        <Route path="/customers/new" exact component={AddUpdate}/>
        <Redirect to="/customers" />
      </Switch>
    )
  }
}
export default CustomerRouter;