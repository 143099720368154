import React, {Component} from 'react';
import {Button, Collapse, Divider, Icon, message, Modal, Table} from "antd";
import {reqGetAllMemos, reqUpdateStateByDiamondIds} from '../../../api';
import {memoDateDiff, sortByType} from '../../../utils/utility';
import {withRouter, Link} from 'react-router-dom';
import LinkButton from '../../LinkButton';
import CheckDiamond from '../CheckDiamond';
import {CSVLink} from 'react-csv';
import moment from 'moment';

const {Panel} = Collapse;

class MemoTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      memos: [],
      loading: false,
      diamonds: [],
      showCheckDiamond: false,
    };

    //initialize table columns
    this.columns = [
      {
        title: 'State',
        render: (item) =>
          <>
            <span>{item.diamond_state} </span>
            <span style={{color: 'red'}}>{`(${memoDateDiff(item.inventory_date, item.memo_day)} Days)`}</span>
          </>,
        defaultSortOrder: 'ascend',
        sorter: (a, b) => memoDateDiff(a.inventory_date, a.memo_day) - memoDateDiff(b.inventory_date, b.memo_day),
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
        sorter: (a, b) => sortByType(a, b, 'name', 'letter'),
      },
      {
        title: 'Invoice#',
        dataIndex: 'invoice_number',
        sorter: (a, b) => sortByType(a, b, 'invoice_number', 'letter'),
      },
      {
        title: 'OC',
        dataIndex: 'original_certificate',
        render: (item) => item === 1 ? 'Yes' : '',
        sorter: (a, b) => sortByType(a, b, 'original_certificate', 'number'),
      },
      {
        title: 'Sold To',
        render: (item) =>
          item.order_id &&
          <span>
            <Link to={'/orders/view/' + item.order_id}>
            <LinkButton>{`${item.last_name}, ${item.first_name}`}</LinkButton></Link>
      </span>,
        sorter: (a, b) => sortByType(a, b, 'last_name', 'letter'),
      },
      {
        title: 'Type',
        dataIndex: 'diamond_type',
        render: (diamond_type) => diamond_type === 'NATURAL' ? <span style={{color: 'red'}}>{diamond_type} </span> :
          <span>{diamond_type}</span>,
        sorter: (a, b) => sortByType(a, b, 'diamond_type', 'letter'),
      },
      {
        title: 'Shape',
        dataIndex: 'shape',
        sorter: (a, b) => sortByType(a, b, 'shape', 'letter'),
      },
      {
        title: 'Carats',
        dataIndex: 'carats',
        sorter: (a, b) => sortByType(a, b, 'carats', 'number'),
      },
      {
        title: 'Colour',
        dataIndex: 'colour',
        sorter: (a, b) => sortByType(a, b, 'colour', 'letter'),
      },
      {
        title: 'Clarity',
        dataIndex: 'clarity',
        sorter: (a, b) => sortByType(a, b, 'clarity', 'letter'),
      },
      {
        title: 'Cut',
        dataIndex: 'cut',
        sorter: (a, b) => sortByType(a, b, 'cut', 'letter'),
      },
      {
        title: 'Cert. Type',
        dataIndex: 'certificate_type',
        sorter: (a, b) => sortByType(a, b, 'certificate_type', 'letter'),
      },
      {
        title: 'Cert. No',
        dataIndex: 'certificate_number',
        sorter: (a, b) => sortByType(a, b, 'certificate_number', 'letter'),
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        sorter: (a, b) => sortByType(a, b, 'comments', 'letter'),
      },
      {
        title: 'True Cost CAD',
        dataIndex: 'true_cost_cad',
        sorter: (a, b) => sortByType(a, b, 'true_cost_cad', 'number'),
      },
      {
        title: 'Shipping Cost CAD',
        dataIndex: 'shipping_cost_cad',
        sorter: (a, b) => sortByType(a, b, 'shipping_cost_cad', 'number'),
      },
      {
        title: 'True Cost USD',
        dataIndex: 'true_cost_usd',
        sorter: (a, b) => sortByType(a, b, 'true_cost_usd', 'number'),
      },
      {
        title: 'Shipping Cost USD',
        dataIndex: 'shipping_cost_usd',
        sorter: (a, b) => sortByType(a, b, 'shipping_cost_usd', 'number'),
      },
      {
        title: 'Market Cost USD',
        dataIndex: 'market_cost_usd',
        sorter: (a, b) => sortByType(a, b, 'market_cost_usd', 'number'),
      },
      {
        title: 'Buffer Rate',
        dataIndex: 'buffer_rate',
        sorter: (a, b) => sortByType(a, b, 'buffer_rate', 'number'),
      },
      {
        title: ' Currency Cost CAD',
        dataIndex: 'currency_cost_cad',
        sorter: (a, b) => sortByType(a, b, 'currency_cost_cad', 'number'),
      },
      {
        title: 'Sell CAD',
        fixed: 'right',
        dataIndex: 'sell_cad',
        sorter: (a, b) => sortByType(a, b, 'sell_cad', 'number'),
      },
      {
        title: 'Reduced CAD',
        fixed: 'right',
        dataIndex: 'reduced_cad',
        sorter: (a, b) => sortByType(a, b, 'reduced_cad', 'number'),
      },
      {
        title: 'Detail',
        fixed: 'right',
        render: (item) =>
          <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
              <Divider type="vertical"/>
              <Link to={'/inventory/edit/' + item.id}><LinkButton
                onClick={() => this.props.history.push('/inventory/edit/' + item.id)}><Icon
                type="edit"/></LinkButton>
              </Link>
            </span>
      }
    ];
  }

  componentDidMount() {
    this.ajaxGetAllMemos();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    //if update is true and this.props.update !== prevProps.update
    if (this.props.update !== prevProps.update && this.props.update) {
      this.ajaxGetAllMemos();
      this.props.onUpdateData();
    }
  }

  ajaxGetAllMemos = () =>{
    this.setState({loading: true});
    reqGetAllMemos()
      .then(response => {
        const memos = response.data;
        this.setState({memos});
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //changeStatus
  onSubmit = () => {
    reqUpdateStateByDiamondIds(this.selectedItems, 'Instock')
      .then(response => {
        message.success('Changed Successfully');
        //delete all records from table
        const _memosAfterDelete = this.state.memos.filter(item => !this.selectedItems.includes(item.id));
        this.setState({memos: _memosAfterDelete, showCheckDiamond: false});
      })
      .catch(error => {
        console.log(error)
      });
  };

  render() {

    const {memos, loading} = this.state;
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      columnTitle: ' ',
      onChange: (selectedRowKeys) => {
        this.selectedItems = selectedRowKeys;
        const diamonds = this.state.memos.filter(item => this.selectedItems.includes(item.id));
        this.setState({diamonds});
      },
    };

    return (
      <Collapse defaultActiveKey={['memo']}>
        <Panel key='memo' header='Memo & Cash Memo'>
          <div style={{paddingBottom: '15px'}}>
            <Button type="primary" onClick={() => this.setState({showCheckDiamond: true})} size='small'>
              Change State to Instock
            </Button>
            <div style={{float:'right'}}>
            <CSVLink
              data={memos}
              filename={moment().format('YYYY-MM-DD') + "-Memo.csv"}
            >
              Download Memo
            </CSVLink>
            </div>
            <Modal
              width={1000}
              visible={this.state.showCheckDiamond}
              okText='Change State to Instock'
              onOk={this.onSubmit}
              onCancel={() => this.setState({showCheckDiamond: false})}>
              <CheckDiamond diamonds={this.state.diamonds}/>
            </Modal>
          </div>
          <Table
            className='memo-table'
            loading={loading}
            rowKey='id'
            bordered
            pagination={false}
            columns={this.columns}
            dataSource={memos}
            size="small"
            rowSelection={rowSelection}
            scroll={{x: 'max-content'}}
            rowClassName={(record, index) => record.order_id && 'sold'}
          />
        </Panel>
      </Collapse>
    )
  }
}

export default withRouter(MemoTable);