import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Menu, Icon, Layout} from 'antd';
import logo from '../../asset/logo-black.png'
import './index.less';
import {getUserType, hasRole} from '../../utils/auth';

const {Sider} = Layout;
const {SubMenu} = Menu;

const OPEN_MENU_ROOT_PATH = ['orders', 'reports', 'vendors', 'inventory'];


class SideBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };

    this.openKey = ['orders', 'reports', 'vendors', 'orders-awaiting', 'inventory'];

    this.user_type = getUserType();
  }

  getActiveKey = path => {
    const rootPath = path.split("/")[1];
    let selectKey;
    if (OPEN_MENU_ROOT_PATH.includes(rootPath)) {
      selectKey = rootPath + '-' + path.split("/")[2];
    } else {
      selectKey = rootPath;
    }
    return selectKey;
  };

  render() {
    //const selectKey = this.getActiveKey(this.props.location.pathname);

    return (
      <Sider style={{
        background: 'black',
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}>
        <div className="nav">
          <Link className="nav-link" to="/home">
            <img src={logo} alt="logo"/>
          </Link>

          <Menu
            mode="vertical"
            theme="dark"
          >
            <Menu.Item key="home">
              <Link to="/home">
                <Icon type="dashboard"/>
                <span>Dashboard</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="customers">
              <Link to="/customers/list">
                <Icon type="user"/>
                <span>Customers</span>
              </Link>
            </Menu.Item>

            <SubMenu
              key="orders"
              title={<span><Icon type="ordered-list"/><span>Orders</span></span>}>
              <Menu.Item key="orders-incomplete"><Link to="/orders/incomplete">Incomplete</Link></Menu.Item>

              <SubMenu
                key="orders-awaiting"
                title={<span>Awaiting</span>}>
                <Menu.Item key="orders-awaitingStone"><Link to="/orders/awaitingStone">Awaiting Stone</Link></Menu.Item>
                <Menu.Item key="orders-awaitingCad"><Link to="/orders/awaitingCad">Awaiting CAD</Link></Menu.Item>
                <Menu.Item key="orders-awaitingResin"><Link to="/orders/awaitingResin">Awaiting Resin</Link></Menu.Item>
                <Menu.Item key="orders-awaitingReplica"><Link to="/orders/awaitingReplica">Awaiting
                  Replica</Link></Menu.Item>
              </SubMenu>

              <Menu.Item key="orders-inProgress"><Link to="/orders/inProgress">In Progress</Link></Menu.Item>
              <Menu.Item key="orders-readyForPickup"><Link to="/orders/readyForPickup">Pickup</Link></Menu.Item>
              <Menu.Item key="orders-list"><Link to="/orders/list">All</Link></Menu.Item>
              {hasRole(this.user_type, ['super admin']) &&
              <Menu.Item key="orders-purgatory"><Link to="/orders/purgatory">Purgatory</Link></Menu.Item>}
            </SubMenu>

            <SubMenu key="repairs" title={<span><Icon type="tool"/><span>Repair</span></span>}>
              <Menu.Item key="repairs-incomplete"><Link to="/repairs/Incomplete">Incomplete</Link></Menu.Item>
              <Menu.Item key="repairs-inProgress"><Link to="/repairs/InProgress">In Progress</Link></Menu.Item>
              <Menu.Item key="repairs-readyForPickup"><Link to="/repairs/ReadyForPickup">Pickup</Link></Menu.Item>
            </SubMenu>

            {hasRole(this.user_type, ['super admin', 'production']) &&
            (
              <SubMenu
                key="reports"
                title={
                  <span>
                  <Icon type="project"/>
                  <span>Reports</span>
                </span>
                }
              >
                <Menu.Item key="reports-cost"><Link to="/reports/cost">Cost Report</Link></Menu.Item>
                <Menu.Item key="reports-accountReceivable"><Link to="/reports/accountReceivable">Accounts
                  Receivable</Link></Menu.Item>
              </SubMenu>
            )}

            {hasRole(this.user_type, ['super admin', 'production']) &&
            (
              <SubMenu
                key="vendors"
                title={
                  <span>
                  <Icon type="container"/>
                  <span>Vendors</span>
                </span>
                }
              >
                <Menu.Item key="vendors-dashboard"><Link to="/vendors/dashboard">Dashboard</Link></Menu.Item>
                <Menu.Item key="vendors-list"><Link to="/vendors/list">List</Link></Menu.Item>
                <Menu.Item key="vendors-calendar"><Link to="/vendors/calendar">Calendar</Link></Menu.Item>
              </SubMenu>
            )}

            {hasRole(this.user_type, ['super admin']) &&
            (
              <Menu.Item key="users">
                <Link to="/users">
                  <Icon type="solution"/>
                  <span>Users</span>
                </Link>
              </Menu.Item>
            )}

            <SubMenu
              key="inventory"
              title={<span><Icon type="sketch"/><span>Inventory</span></span>}>
              <SubMenu
                key="List"
                title={<span>List Diamonds</span>}>
                <Menu.Item key="inventory-list-sold"><Link to="/inventory/sold">Sold</Link></Menu.Item>
                <Menu.Item key="inventory-list-memo"><Link to="/inventory/insite">Memo</Link></Menu.Item>
                <Menu.Item key="inventory-list-location"><Link to="/inventory/location">Location</Link></Menu.Item>
              </SubMenu>
              <SubMenu
                key="action"
                title={<span>Action</span>}>
                <Menu.Item key="inventory-action-search"><Link to="/inventory/search">Search Diamonds</Link></Menu.Item>
                <Menu.Item key="inventory-action-import"><Link to="/inventory/import-diamonds">Import Diamonds</Link></Menu.Item>
                <Menu.Item key="inventory-action-add"><Link to="/inventory/add-diamonds">Add Diamonds</Link></Menu.Item>
                <Menu.Item key="inventory-action-combine"><Link to="/inventory/update">Combine
                  Diamonds</Link></Menu.Item>
              </SubMenu>
              <Menu.Item key="inventory-vendor"><Link to="/inventory/vendor">Invoice</Link></Menu.Item>
              <SubMenu
                key="Other"
                title={<span>Other</span>}>
                <Menu.Item key="inventory-other-offsite"><Link to="/inventory/offsite">Off Site
                  Diamonds</Link></Menu.Item>
                {hasRole(this.user_type, ['super admin']) &&
                (
                  <Menu.Item key="inventory-other-adjustment"><Link
                    to="/inventory/adjustment">Adjustment</Link></Menu.Item>
                )}
                {hasRole(this.user_type, ['super admin']) &&
                (
                  <Menu.Item key="inventory-other-sell-tier"><Link to="/inventory/sell-tier">Sell
                    Tiers</Link></Menu.Item>
                )}
              </SubMenu>
            </SubMenu>

            <SubMenu
              key="calculator"
              title={
                <span>
                  <Icon type="calculator"/>
                  <span>Calculator</span>
                </span>
              }
            >
              <Menu.Item key="calculator-buy"><Link to="/calculator/buy">Buy</Link></Menu.Item>
              <Menu.Item key="calculator-sell"><Link to="/calculator/sell">Sell</Link></Menu.Item>
            </SubMenu>

          </Menu>
        </div>
      </Sider>
    );
  }
}

export default withRouter(SideBar);