import React, {Component} from 'react';
import LinkButton from "../../components/LinkButton";
import {formatDate} from "../../utils/utility";
import * as ReactDOM from "react-dom";
import './result.less';
import {Descriptions} from 'antd';
import {Link} from 'react-router-dom';


export default class Result extends Component {

  hideRow = order_item_id => {
    let element = document.getElementById(order_item_id);
    let display = ReactDOM.findDOMNode(element).style.display;
    if (display === "none") {
      ReactDOM.findDOMNode(element).style.display = 'table-row-group';
    } else {
      ReactDOM.findDOMNode(element).style.display = "none";
    }
  };

  render() {
    const {orders, cad, usd} = this.props.costs;

    return (
      <div className="report-result" {...this.props}>
        <Descriptions size='small' layout="vertical" bordered >
          <Descriptions.Item label="COUNT" key='count'>
            <span>{orders.length}</span>
          </Descriptions.Item>
          <Descriptions.Item label="CAD TOTAL" key='cad'>
            <span>${cad}</span>
          </Descriptions.Item>
          <Descriptions.Item label="USD TOTAL" key='usd'>
            <span>${usd}</span>
          </Descriptions.Item>
        </Descriptions>

        <table>
          {orders.map(item =>
            <React.Fragment key={item.id}>
              <tbody className="head">
              <tr>
                <th colSpan="7" className="customer">Customer</th>
                <th colSpan="2" className="component">Component</th>
              </tr>
              </tbody>

              <tbody>
              <tr>
                <th>Order#</th>
                <th>Customer</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Job Description</th>
                <th>Payment Type</th>
                <th>Price</th>
                <th>Type</th>
                <th>Detail</th>
              </tr>
              </tbody>

              <tbody className="customer_info">
              <tr>
                <td><Link to={'/orders/edit/' + item.id}>
                  <LinkButton>{item.order_number}</LinkButton>
                </Link></td>
                <td>{item.customer.full_name}</td>
                <td>{formatDate(item.order_date)}</td>
                <td>{item.status}</td>
                <td>{item.job_description}</td>
                <td>{item.payment_type}</td>
                <td>${item.retail}</td>
                <td colSpan="2"></td>
              </tr>
              </tbody>

              {item.order_items.map(order_item =>
                <React.Fragment key={order_item.id}>
                  <tbody>
                  <tr>
                    <td colSpan="7"></td>
                    <th>{order_item.component}</th>
                    <th>
                      <LinkButton onClick={() => this.hideRow(order_item.id)}>Check</LinkButton>
                    </th>
                  </tr>
                  </tbody>
                  <tbody className="collapsed" id={order_item.id} style={{display: 'none'}}>
                  {order_item.details.map(detail =>
                    <tr key={detail.name}>
                      <td colSpan="7"></td>
                      <td>{detail.name}</td>
                      <td>{detail.value}</td>
                    </tr>
                  )}
                  </tbody>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </table>
      </div>
    );
  }

}