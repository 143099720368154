import React, {Component} from 'react';
import {Col, Row, Tag} from "antd";
import {reqGetCurrentAdjustment} from '../../../api';

class TitleCurrentAdjustment extends Component {

  constructor(props) {
    super(props);
    this.state = {
      adjustment: {},
    };
  }

  componentDidMount() {
    this.ajaxGetCurrentAdjustment();
  }

  //ajax: get all adjustment
  ajaxGetCurrentAdjustment = () => {
    reqGetCurrentAdjustment()
      .then(response => {
        const adjustment = response.data;
        this.setState({adjustment});
      })
      .catch(error => {
        console.log(error)
      });
  }

  render() {
    const {adjustment} = this.state;
    return (
      <div style={{padding: '10px'}}>
        <Row>
          <Col span={4}><Tag color="blue">Exchange Rate: {adjustment.exchange_rate}</Tag></Col>
          <Col span={4}><Tag color="blue">Buffer Rate Low: {adjustment.buffer_rate_low}</Tag></Col>
          <Col span={4}><Tag color="blue">Buffer Rate High: {adjustment.buffer_rate_high}</Tag></Col>
          <Col span={5}><Tag color="blue">Last Updated Date: {adjustment.adjustment_updated_date}</Tag></Col>
        </Row>
      </div>
    )
  }
}

export default TitleCurrentAdjustment;