import React, {Component} from 'react';
import {Button, Card, Checkbox, Col, Radio, Form, Input, Row, Spin} from 'antd';
import {reqGetBuyPrice} from "../../api";
import { regexMoney} from "../../utils/utility";
import moment from 'moment';

const {Item} = Form;

class BuyCalculator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sell_prices: {
        currency: '',
        prices_max: '',
        prices_min: ''
      },
      buy_prices: {
        prices_max: {cad: '', usd: ''},
        prices_min: {cad: '', usd: ''},
      },
      date: moment().format('YYYY-MM-DD'),
      name: ""
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const sell_prices = {
          currency: values.sell_currency,
          prices_min: values.sell_price_min,
          prices_max: values.sell_price_max
        };
        this.setState({loading: true, name: values.name, sell_prices});
        reqGetBuyPrice(values)
          .then(response => {
            console.log(response.data)
            this.setState({loading: false, buy_prices: response.data});
          })
          .catch(error => {
            console.log(error)
          });
      }
    });
  };

  render() {
    const {date, loading, sell_prices, buy_prices, name} = this.state;
    const {getFieldDecorator} = this.props.form;

    return (
      <Card title='Buy Calculator'>
        <Form onSubmit={this.handleSubmit}>
          <Item label="Client Name" labelCol={{span: 4}} wrapperCol={{span: 9}}>
            {
              getFieldDecorator('name', {
                initialValue: '',
              })(<Input/>)
            }
          </Item>
          <Item label="Sell Currency" labelCol={{span: 4}} wrapperCol={{span: 10}}>
            {getFieldDecorator('sell_currency', {
              initialValue: 'CAD',
              rules: [
                {required: true, message: 'Required'},
              ]
            })(
              <Radio.Group>
                <Radio value='CAD'>CAD</Radio>
                <Radio value='USD'>USD</Radio>
              </Radio.Group>
            )}
          </Item>
          <Row>
            <Col span={8}>
              <Item label="Sell Price - From" labelCol={{span: 12}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('sell_price_min', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                      {
                        pattern: regexMoney,
                        message: 'Number with 2 Decimal Only',
                      }
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
            <Col span={8}>
              <Item label="To" labelCol={{span: 4}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('sell_price_max', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                      {
                        pattern: regexMoney,
                        message: 'Number with 2 Decimal Only',
                      }
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Item label="Discount Buffer" labelCol={{span: 4}} wrapperCol={{span: 10}}>
            {getFieldDecorator('discount_buffer', {
              initialValue: false,
              valuePropName: 'checked',
            })(
              <Checkbox></Checkbox>
            )}
          </Item>

          <Item label="Shipping Buffer" labelCol={{span: 4}} wrapperCol={{span: 4}}>
            {getFieldDecorator('shipping_buffer', {
              initialValue: 0,
              rules: [
                {required: true, message: 'Required'},
                {
                  pattern: regexMoney,
                  message: 'Number with 2 decimal places Only',
                }
              ]
            })(
              <Input/>
            )}
          </Item>
          <Row>
            <Col span={12} offset={3}>
              <Item>
                <Button type="primary" htmlType="submit">Submit</Button>
              </Item>
            </Col>
          </Row>
        </Form>
        <Spin spinning={loading}>
          <h1>{date} {name} - Sell price: {sell_prices.currency}{sell_prices.prices_min} - {sell_prices.currency}{sell_prices.prices_max},
            Buy price: CAD{buy_prices.prices_min.cad} - CAD{buy_prices.prices_max.cad}, USD{buy_prices.prices_min.usd} - USD{buy_prices.prices_max.usd}</h1>
        </Spin>
      </Card>
    );
  }

}

export default Form.create()(BuyCalculator)