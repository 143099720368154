import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, DatePicker, Input, Select} from 'antd';
import moment from 'moment';
import FormattedSelect from './FormattedSelect';
import _ from 'lodash';
import {getUserName} from '../../../utils/auth';

const {Item} = Form;
const {TextArea} = Input;
const {Option} = Select;


class NoteAddUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.username = getUserName();
    this.props.setForm(this.props.form);
    this.state = {
      isFormatted: _.isEmpty(this.props.note),
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.note !== prevProps.note) {
      this.setState({isFormatted:_.isEmpty(this.props.note)})
    }
  }


  onChange = (value) => {
    if (value === 'Formatted') {
      this.setState({isFormatted: true})
    } else {
      this.setState({isFormatted: false})
    }
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {note, note_date} = this.props.note;
    const {isFormatted} = this.state;

    return (
      <Form>
        <Item label="Type">
          {
            getFieldDecorator('type', {
              initialValue: isFormatted? 'Formatted' :'Custom',
              rules: [
                {required: true, message: 'Note is required'}
              ]
            })(
              <Select onChange={this.onChange}>
                <Option value='Formatted'>Formatted</Option>
                <Option value='Custom'>Custom</Option>
              </Select>
            )
          }
        </Item>
        <Item label="Note">
          {
            getFieldDecorator('note', {
              initialValue: note || '', //add or update
              rules: [
                {required: true, message: 'Note is required'}
              ]
            })(isFormatted ? <FormattedSelect userName={this.username}/> : <TextArea rows={5}/>)
          }
        </Item>
        <Item label="Date">
          {
            getFieldDecorator('note_date', {
              initialValue: !(note_date) ? moment(Date.now()) : moment(note_date),
              rules: [
                {required: true, message: 'Date is required'}
              ]
            })(
              <DatePicker/>
            )
          }
        </Item>
      </Form>
    )
  }
}

NoteAddUpdateForm.propTypes = {
  setForm: PropTypes.func.isRequired,
  note: PropTypes.object,
};

export default Form.create()(NoteAddUpdateForm)