import React, {Component} from 'react';
import {Form, DatePicker, Input, Select, Col, Checkbox, Row} from 'antd';
import {reqGetVendorsPlusDelete} from "../../../api";
import {
  STATUS_STONE, STONE_CERTIFICATE_TYPE, STONE_CLARITY, STONE_COLOUR,
  STONE_CUT, STONE_SHAPE, STONE_TYPE
} from "../../../utils/const";
import moment from 'moment';
import _ from 'lodash';
import {regexMoney} from "../../../utils/utility";
import LinkButton from "../../LinkButton";
import SelectInput from '../../SelectInput';

const {Item} = Form;
const {Option} = Select;

class StonesAddUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vendors: []
    };
    props.setForm(props.form)
  }

  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'stone', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      count, measurements, carats, certificate_number,
      type, shape, colour, clarity, cut, certificate_type,
      start_date, requires_approval_stone, approved_stone, retail, vendor_id, status
    } = this.props.order_item;
    const {vendors} = this.state;

    return (
      <Form>
        <Row>
          <Col span={18}>
            <Item label="Type" labelCol={{span: 6}} wrapperCol={{span: 16}}>
              {getFieldDecorator('type', {
                initialValue: type || '',
                rules: [
                  {required: true, message: 'Type is required'},
                ]
              })(
                <SelectInput options={STONE_TYPE}/>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <Item label='Measurements' labelCol={{span: 7}} wrapperCol={{span: 14}}>
              {
                getFieldDecorator('measurements', {
                  initialValue: measurements || '',
                  rules: [
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
          <Col span={9}>
            <Item label='Count' labelCol={{span: 9}} wrapperCol={{span: 10}}>
              {
                getFieldDecorator('count', {
                  initialValue: count || '',
                  rules: [
                    {required: true, message: 'Count is required'},
                    {
                      pattern: /^[0-9]*[1-9][0-9]*$/,
                      message: 'Number Only',
                    }
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Shape" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('shape', {
                initialValue: shape || '',
                rules: [
                  {max: 40, message: 'Maximum 40 characters'},
                ]
              })(
                <SelectInput options={STONE_SHAPE} defaultOption={true}/>
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label='Carats' labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('carats', {
                  initialValue: carats || '',
                  rules: [
                    {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/, message: 'Number with 4 decimal places Only',}
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Colour" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('colour', {
                initialValue: colour || '',
                rules: [
                  {max: 40, message: 'Maximum 40 characters'},
                ]
              })(
                <SelectInput options={STONE_COLOUR} defaultOption={true}/>
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Clarity" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('clarity', {
                initialValue: clarity || '',
                rules: [
                  {max: 40, message: 'Maximum 40 characters'},
                ]
              })(
                <SelectInput options={STONE_CLARITY} defaultOption={true}/>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Cut" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('cut', {
                initialValue: cut || '',
                rules: [
                  {max: 40, message: 'Maximum 40 characters'},
                ]
              })(
                <SelectInput options={STONE_CUT} defaultOption={true}/>
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Certificate Type" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('certificate_type', {
                initialValue: certificate_type || '',
                rules: [
                  {max: 40, message: 'Maximum 40 characters'},
                ]
              })(
                <SelectInput options={STONE_CERTIFICATE_TYPE} defaultOption={true}/>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>

            <Item label='Certificate Number' labelCol={{span: 9}} wrapperCol={{span: 14}}>
              {
                getFieldDecorator('certificate_number', {
                  initialValue: certificate_number || '',
                })(<Input/>)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item label='Retail' labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('retail', {
                  initialValue: retail || '',
                  rules: [
                    {required: true, message: 'retail is required'},
                    {
                      pattern: regexMoney,
                      message: 'Number with 2 decimal places Only',
                    }
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Require Stone" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('requires_approval_stone', {
                initialValue: requires_approval_stone || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Approve Stone" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {getFieldDecorator('approved_stone', {
                initialValue: approved_stone || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
        </Row>
        {
          !(_.isEmpty(this.props.order_item)) &&
          (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <Item label="Vendor" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                    {getFieldDecorator('vendor_id', {
                      initialValue: vendor_id || '',
                    })(
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {
                          vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                      key={item.id}> {item.name} </Option>)
                        }
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="Start Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                    {
                      getFieldDecorator('start_date', {
                        initialValue: !(start_date) ? null : moment(start_date),
                      })(
                        <DatePicker/>
                      )
                    }
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Item label="Status" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                    {getFieldDecorator('status', {
                      initialValue: status || '',
                    })(
                      <Select>
                        {
                          STATUS_STONE.map(item => <Option value={item} key={item}> {item} </Option>)
                        }
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row>
                {!!vendor_id && <LinkButton onClick={() => this.props.deletePO()}>DELETE PO#</LinkButton>}
              </Row>
            </React.Fragment>
          )
        }
      </Form>
    )
  }
}

export default Form.create()(StonesAddUpdateForm)