import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Form, Input, Select, Row, Col, Checkbox} from 'antd';

const {Item} = Form;
const {Option} = Select;

class UpdateForm extends Component {

  constructor(props) {
    super(props);
    this.props.setForm(this.props.form);
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {first_name, last_name, username, user_type, is_sales_rep, is_active} = this.props.user;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 15}
    };

    return (
      <Form {...formLayout}>
        <Item label="Username">
          <span className="ant-form-text">{username}</span>
        </Item>
        <Row>
          <Col span={13} offset={2}>
            <Item label="Sales Rep">
              {getFieldDecorator('is_sales_rep', {
                initialValue: is_sales_rep,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={9}>
            <Item label="Active">
              {getFieldDecorator('is_active', {
                initialValue: is_active,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
        </Row>
        <Item label="First Name">
          {
            getFieldDecorator('first_name', {
              initialValue: first_name,
              rules: [
                {required: true, message: 'First Name is required'},
                {max: 255, message: 'Maximum 255 characters'},
              ]
            })(
              <Input type="text" placeholder="First Name"/>
            )
          }
        </Item>
        <Item label="Last Name">
          {
            getFieldDecorator('last_name', {
              initialValue: last_name,
              rules: [
                {required: true, message: 'Last Name is required'},
                {max: 255, message: 'Maximum 255 characters'},
              ]
            })(
              <Input type="text" placeholder="Last Name"/>
            )
          }
        </Item>
        <Item label="Type">
          {getFieldDecorator('user_type', {
            initialValue: user_type,
            rules: [
              {required: true, message: 'Type is required'}
            ]
          })(
            <Select>
              <Option value="sales">Sales</Option>
              <Option value="production">Production</Option>
              <Option value="super admin">Super Admin</Option>
            </Select>
          )}
        </Item>
      </Form>
    )
  }
}

UpdateForm.propTypes = {
  setForm: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default Form.create()(UpdateForm)