import React, {Component} from 'react';
import {Table, Col, Row, Modal, message} from "antd";
import {throttle} from 'lodash';
import {reqAddPayment, reqGetPaymentsByOrderId} from "../../../api";
import {formatDate, momentToString} from "../../../utils/utility";
import LinkButton from "../../LinkButton";
import PaymentAddForm from "./PaymentAddForm";

class PaymentTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      totalAmount: '',
      showAddDialog: false,
      displayAdd: false
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    reqGetPaymentsByOrderId(this.props.id)
      .then(response => {
        const payments = response.data.payments;
        this.setState({payments});
        const totalAmount = response.data.total.amount_paid;
        this.setState({totalAmount});
        this.setState({displayAdd: this.props.displayAdd})
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  //Add
  onAdd = throttle(() => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const order_id = this.props.id;
        let {type, cheque_number, payment_time, amount} = values;
        payment_time = momentToString(payment_time);
        let payment = {type, cheque_number, payment_time, amount, order_id};

        await this.addPayment(payment);
      }
    })
  }, 5000, {leading: true, trailing: false});

  addPayment = (payment) =>
    reqAddPayment(payment)
      .then(response => {
        this.setState({payments: response.data.payments});
        this.setState({totalAmount: response.data.total.amount_paid});
        this.setState({showAddDialog: false});
        message.success('Added Successfully!');
        this.form.resetFields();
        this.props.onUpdateFee();
      })
      .catch(error => {
          console.log(error.response)
        }
      );

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Type',
        dataIndex: 'type'
      },
      {
        title: 'Date',
        dataIndex: 'payment_time',
        render: formatDate
      },
      {
        title: 'Editor',
        dataIndex: 'user',
        render: (user) => (user.full_name)
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
      },
    ];
  };

  title = (displayAdd) => (
    <Row>
      <Col span={3}>
        Payments
      </Col>
      {
        (displayAdd &&
          <Col span={8}>
            <LinkButton onClick={() => this.setState({showAddDialog: true})}><span>Add Payment</span></LinkButton>
          </Col>)
      }
    </Row>
  );


  render() {
    const {payments, showAddDialog, totalAmount, displayAdd} = this.state;

    return (
      <React.Fragment>
        <Table
          className='payment-table'
          bordered={false}
          title={() => this.title(displayAdd)}
          rowKey='id'
          dataSource={payments}
          columns={this.columns}
          pagination={false}
          size="middle"
          footer={() => <div style={{textAlign: "right"}}>{totalAmount && `Total Amount: ${totalAmount}`}</div>}
        />
        <Modal
          title='Add Payment'
          visible={showAddDialog}
          onOk={this.onAdd}
          onCancel={() => {
            this.setState({showAddDialog: false});
            this.form.resetFields();
          }}
          destroyOnClose
        >
          <PaymentAddForm setForm={form => this.form = form}/>
        </Modal>
      </React.Fragment>
    )
  }

}

export default PaymentTable;