import React, {Component} from 'react';
import {Card, Table} from 'antd';
import {reqGetAccountsReceivable} from "../../api";
import LinkButton from "../../components/LinkButton";
import {strip} from '../../utils/utility';
import {Link} from 'react-router-dom';


export default class AccountsReceivable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      accounts: [], //accounts list
      sum: {}
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    reqGetAccountsReceivable()
      .then(response => {
        const reports = response.data.array;
        //Merge objects with same id in array
        const accounts = reports.reduce((prev, cur) => {
          const index = prev.findIndex(v => v.id === cur.id);
          if (index === -1) {
            prev.push(cur);
          } else {
            prev[index].under30 = this.calculateAmount(prev[index].under30, cur.under30);
            prev[index].under60 = this.calculateAmount(prev[index].under60, cur.under60);
            prev[index].under90 = this.calculateAmount(prev[index].under90, cur.under90);
            prev[index].beyond90 = this.calculateAmount(prev[index].beyond90, cur.beyond90);
            prev[index].balance = this.calculateAmount(prev[index].balance, cur.balance)
          }
          return prev;
        }, []);
        accounts.sort((a,b) => a.name.localeCompare(b.name));
        this.setState({accounts, sum: response.data.sum});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  calculateAmount = (pre, cur) => {
    return strip(parseFloat(pre) + parseFloat(cur));
  };


  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Customer',
        key: 'id',
        render: (account) =>
          <span>
            <Link to={'/customers/edit/' + account.id}>
            <LinkButton>{account.name}</LinkButton>
            </Link>
          </span>
      },
      {
        title: 'Outstanding Balance',
        dataIndex: 'balance',
        render: (value) => '$' + value
      },
      {
        title: '0-30',
        dataIndex: 'under30',
        render: (value) => '$' + value
      },
      {
        title: '31-60',
        dataIndex: 'under60',
        render: (value) => '$' + value
      },
      {
        title: '61-90',
        dataIndex: 'under90',
        render: (value) => '$' + value
      },
      {
        title: '91+',
        dataIndex: 'beyond90',
        render: (value) => '$' + value
      },
    ];
  };

  render() {
    const {accounts, sum, loading} = this.state;
    const totalRow = {
      id: String(Math.random()),
      balance: sum.balance,
      under30: sum.under30,
      under60: sum.under60,
      under90: sum.under90,
      beyond90: sum.beyond90
    }

    //add sum row
    const accounts_sum = accounts && accounts.length > 0 ? [...accounts, totalRow] : [];


    return (
      <div className="report-account">
        <Card title='Accounts Receivable'>
          <Table
            rowKey="id"
            loading={loading}
            dataSource={accounts_sum}
            columns={this.columns}
            pagination={false}
            size="middle"
            bordered
            scroll={{ x: true }}
          />
        </Card>
      </div>
    );
  }

}