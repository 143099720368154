import React, {Component} from 'react';
import {Form, Input, Row, Col, DatePicker, Button, Select, Card} from 'antd';
import {ORDER_JOB_TYPE, STATUS, JOB_TYPE} from "../../utils/const";
import {reqGetSalesPlusDelete, reqGetDistCountries, reqGetDistProvincesByCountry} from "../../api";

const {Item} = Form;
const {RangePicker} = DatePicker;
const {Option} = Select;

class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sales: [],
      countries: [],
      provinces: [],
    }
  }

  componentDidMount() {
    reqGetSalesPlusDelete()
      .then(response => {
        const sales = response.data;
        this.setState({sales});
      })
      .catch(error => {
        console.log(error.response)
      });

    reqGetDistCountries()
      .then(response => {
        const countries = response.data;
        this.setState({countries});
      })
      .catch(error => {
        console.log(error.response)
      });

  }

  //if country changed, get provinces in the selected country
  handleCountryChange = value => {
    if (value.length === 1) {
      reqGetDistProvincesByCountry(value[0])
        .then(response => {
          const provinces = response.data;
          this.setState({provinces});
        })
        .catch(error => {
          console.log(error.response)
        });
    } else {
      this.setState({provinces: []});
      this.props.form.resetFields('provinces');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  exportExcel = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.onExport(values);
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {countries, sales, provinces} = this.state;

    return (
      <Card>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col span={6}>
              <Item label="Order Date">
                {
                  getFieldDecorator('order_date', {
                    initialValue: null
                  })(<DatePicker/>)
                }
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Between Dates">
                {
                  getFieldDecorator('between_dates', {
                    initialValue: null
                  })(<RangePicker/>)
                }
              </Item>
            </Col>
            <Col span={8} offset={1}>
              <Item label="Picked Up">
                {
                  getFieldDecorator('picked_up', {
                    initialValue: null
                  })(<RangePicker/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <Item label="Order Number">
                {
                  getFieldDecorator('order_number', {
                    initialValue: ''
                  })(<Input/>)
                }
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item label="Status">
                {
                  getFieldDecorator('status', {})(
                    <Select mode="multiple">
                      {
                        STATUS.map(item => <Option value={item} key={item}> {item} </Option>)
                      }
                    </Select>
                  )}
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item label='Sales'>
                {
                  getFieldDecorator('sales_representitive', {})(<Select mode="multiple">
                    {
                      sales.map(item => <Option
                        hidden={!item.is_sales_rep} value={item.id}
                        key={item.id}> {item.full_name} </Option>)
                    }
                  </Select>)}
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item label="Job Description">
                {getFieldDecorator('job_description')(
                  <Select mode="multiple">
                    {
                      ORDER_JOB_TYPE.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Payment Dates (only for Excel)">
                {
                  getFieldDecorator('payment_dates', {
                    initialValue: null
                  })(<RangePicker/>)
                }
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item label="Country (only for Excel)">
                {getFieldDecorator('countries')(
                  <Select mode="multiple" onChange={this.handleCountryChange}>
                    {
                      countries.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={5} offset={1}>
              <Item label="Province (only for Excel)">
                {getFieldDecorator('provinces')(
                  <Select mode="multiple">
                    {
                      provinces.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Item label="Component (only for Submit)">
                {
                  getFieldDecorator('job_type', {})(
                    <Select mode="multiple">
                      {
                        JOB_TYPE.map(item => <Option value={item} key={item}> {item} </Option>)
                      }
                    </Select>
                  )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <Item>
                <Button type="primary" onClick={this.handleSubmit}>
                  Submit
                </Button>
              </Item>
            </Col>
            <Col span={2}>
              <Item>
                <Button onClick={() => this.props.form.resetFields()}>
                  Reset
                </Button>
              </Item>
            </Col>
            <Col span={3}>
              <Item>
                <Button type="primary" onClick={this.exportExcel}>
                  Export to Excel
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Card>
    )
  }
}

export default Form.create()(Search)