import React, {Component} from 'react';
import {Icon, Table, Card, Divider, Modal, message, Button, Select, Input, Tag} from 'antd';
import {reqAddVendor, reqDeleteVendor, reqGetAllVendors, reqSearchVendors, reqUpdateVendor,reqPutVendorBack} from "../../api";
import LinkButton from "../../components/LinkButton";
import AddUpdateForm from "../../components/Vendor/AddUpdateForm";
import {formatDate} from '../../utils/utility';

const Option = Select.Option;

export default class List extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      vendors: [], //vendor list
      addUpdateDialogShow: 0, //0:hide; 1:Add, 2:Update
      searchType: 'name', //default search  name
      searchName: '',
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    //get vendors
    this.ajaxGetAllVendors();
  }

  //ajax get all vendors
  ajaxGetAllVendors = () => {
    reqGetAllVendors()
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //show add Dialog
  showAdd = () => {
    this.vendor = {};
    this.setState({addUpdateDialogShow: 1})
  };

  //show update Dialog
  showUpdate = (vendor) => {
    this.vendor = vendor;
    this.setState({addUpdateDialogShow: 2})
  };

  //Search
  onSearchVendors = () => {
    const {searchName, searchType} = this.state;

    reqSearchVendors({searchName, searchType})
      .then(response => {
        this.setState({vendors: response.data})
      })
      .catch(error => {
        console.log(error)
      });

  };


  //create and update
  onAddUpdate = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        let {name, type, currency, shipping_cost, is_memo, is_cash_memo, memo_day, cash_memo_day, default_state} = values;
        let vendor = {
          name,
          type,
          currency,
          shipping_cost,
          is_memo,
          is_cash_memo,
          memo_day,
          cash_memo_day,
          default_state
        };

        if (this.state.addUpdateDialogShow === 1) {//add
          reqAddVendor(vendor)
            .then(response => {
              const newVendor = response.data;
              this.setState(state => ({vendors: [newVendor, ...state.vendors]}));
              this.setState({addUpdateDialogShow: 0});
              message.success('Add Successfully!');
            })
            .catch(error => {
              console.log(error)
            });
        } else if (this.state.addUpdateDialogShow === 2) {//update
          const id = this.vendor.id;
          reqUpdateVendor(id, vendor)
            .then(response => {
              this.setState({addUpdateDialogShow: 0});
              message.success('Update Successfully!');
              this.ajaxGetAllVendors();
            })
            .catch(error => {
              console.log(error)
            });
        }
        this.form.resetFields();
        this.vendor = {};
      }
    })

  };

  //Delete
  onDelete = (vendor) => {
    Modal.confirm({
      title: `Are you sure you want to make [ ${vendor.name} ] inactive ?`,
      onOk: () => {
        reqDeleteVendor(vendor.id)
          .then(response => {
            const vendorsAfterDelete = this.state.vendors.filter(item => !(item.id === vendor.id));
            this.setState({vendors: vendorsAfterDelete});
            message.success('Delete Successfully');
          })
          .catch(error => {
            console.log(error);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });

      },
      onCancel() {
      },
    })
  };

  //Put it back
  onPutBack = (vendor) => {
    Modal.confirm({
      title: `Are you sure you want to make [ ${vendor.name} ] active ?`,
      onOk: () => {
        reqPutVendorBack(vendor.id)
          .then(response => {
            this.ajaxGetAllVendors();
          })
          .catch(error => {
            console.log(error);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });

      },
      onCancel() {
      },
    })
  };

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (type) => (
          <span>{type.map(type => (<Tag color="blue" key={type}>{type}</Tag>))}</span>
        )
      },
      {
        title: 'Currency',
        dataIndex: 'currency'
      },
      {
        title: 'Shipping Cost',
        dataIndex: 'shipping_cost',
        // eslint-disable-next-line
        render: shipping_cost => shipping_cost == 0 ? <span> </span> : <span>{shipping_cost}</span>
      },
      {
        title: 'Cash Memo',
        render: item => item.is_cash_memo ? <span>{item.cash_memo_day} Days</span> : <span> </span>
      },
      {
        title: 'Memo',
        render: item => item.is_memo ? <span>{item.memo_day} Days</span> : <span> </span>
      },
      {
        title: 'Action',
        render: (vendor) =>
          <>
            {
              !vendor.deleted_at &&
                <>
                <LinkButton onClick={() => this.showUpdate(vendor)}>Edit</LinkButton>
                <Divider type="vertical"/>
                </>
            }

            {
              vendor.deleted_at ? <LinkButton onClick={() => this.onPutBack(vendor)}>Active ({formatDate(vendor.deleted_at)})</LinkButton> :
                <LinkButton onClick={() => this.onDelete(vendor)}>Inactive</LinkButton>
            }
          </>
      },
    ];
  };

  render() {
    const {vendors, loading, addUpdateDialogShow, searchType, searchName} = this.state;

    const title = (
      <span>
        <Select
          style={{width: 200}}
          value={searchType}
          showArrow={false}
          onChange={(value) => this.setState({searchType: value})}
        >
          <Option value="name">Search by Name</Option>
        </Select>
        <Input
          style={{width: 200, margin: '0 10px'}}
          placeholder="search"
          value={searchName}
          onChange={event => this.setState({searchName: event.target.value})}
          onPressEnter={this.onSearchVendors}
        />
        <Button type="primary" onClick={() => {
          this.onSearchVendors();
        }}>SEARCH</Button>
      </span>
    );

    const extra = <Button type='primary' onClick={this.showAdd}><Icon type="plus"/>Add Vendor</Button>;

    return (
      <div className="vendors">
        <Card title={title} extra={extra}>
          <Table
            rowKey='id'
            loading={loading}
            dataSource={vendors}
            columns={this.columns}
            pagination={{defaultPageSize: 15, showQuickJumper: true}}
            size="middle"
            scroll={{x: true}}
          />
          <Modal
            width={650}
            title={addUpdateDialogShow === 1 ? 'Create Vendor' : 'Update Vendor'}
            visible={addUpdateDialogShow !== 0}
            onOk={this.onAddUpdate}
            onCancel={() => {
              this.form.resetFields();
              this.setState({addUpdateDialogShow: 0})
            }}
          >
            <AddUpdateForm vendor={this.vendor} setForm={form => this.form = form}/>
          </Modal>
        </Card>
      </div>
    );
  }

}