import React, {Component} from 'react';
import {Divider, Icon, Table} from 'antd';
import {Link} from 'react-router-dom';
import {CSVLink} from 'react-csv';
import moment from 'moment';
import LinkButton from '../../LinkButton';
import {sortByType} from '../../../utils/utility';

export default class SoldDiamondTable extends Component {

  constructor(props) {
    super(props);
    this.initColumns();
  }

  initColumns = () => {
    this.columns =
      [
        {
          title: 'Vendor',
          dataIndex: 'name',
          sorter: (a, b) => sortByType(a, b, 'name', 'letter'),
        },
        {
          title: 'Sold To',
          render: (item) =>
            item.order_id &&
            <span>
                <Link to={'/orders/view/' + item.order_id}>
                    <LinkButton>{item.customer}</LinkButton></Link>
            </span>,
          sorter: (a, b) => sortByType(a, b, 'customer', 'letter'),
        },
        {
          title: 'Sales',
          dataIndex: 'first_name',
          sorter: (a, b) => sortByType(a, b, 'first_name', 'letter'),
        },
        {
          title: 'OC',
          dataIndex: 'original_certificate',
          render: (item) => item === 1 ? 'Yes' : '',
          sorter: (a, b) => a.original_certificate - b.original_certificate,
        },
        {
          title: 'Reported',
          dataIndex: 'reported',
          render: (item) => item === 1 ? 'Yes' : '',
          sorter: (a, b) => a.reported - b.reported,
        },
        {
          title: 'Vendor Invoice#',
          dataIndex: 'invoice_number',
          sorter: (a, b) => sortByType(a, b, 'invoice_number', 'letter'),
        },
        {
          title: 'Stock#',
          dataIndex: 'stock_number',
          sorter: (a, b) => sortByType(a, b, 'stock_number', 'letter'),
        },
        {
          title: 'Stone Sold Date',
          dataIndex: 'stone_sold_date',
          sorter: (a, b) => sortByType(a, b, 'stone_sold_date', 'letter'),
        },
        {
          title: 'Inventory Date',
          dataIndex: 'inventory_date',
          sorter: (a, b) => sortByType(a, b, 'inventory_date', 'letter'),
        },
        {
          title: 'State',
          dataIndex: 'diamond_state',
          sorter: (a, b) => sortByType(a, b, 'diamond_state', 'letter'),
        },
        {
          title: 'Location',
          dataIndex: 'diamond_location',
          sorter: (a, b) => sortByType(a, b, 'diamond_location', 'letter'),
        },
        {
          title: 'Type',
          dataIndex: 'diamond_type',
          render: (diamond_type) => diamond_type === 'NATURAL' ? <span style={{color: 'red'}}>{diamond_type} </span> :
            <span>{diamond_type}</span>,
          sorter: (a, b) => a.diamond_type.localeCompare(b.diamond_type),
        },
        {
          title: 'Shape',
          dataIndex: 'shape',
          sorter: (a, b) => a.shape.localeCompare(b.shape),
        },
        {
          title: 'Carats',
          dataIndex: 'carats',
          sorter: (a, b) => a.carats.localeCompare(b.carats),
        },
        {
          title: 'Colour',
          dataIndex: 'colour',
          sorter: (a, b) => a.colour.localeCompare(b.colour),
        },
        {
          title: 'Clarity',
          dataIndex: 'clarity',
          sorter: (a, b) => a.clarity.localeCompare(b.clarity),
        },
        {
          title: 'Cut',
          dataIndex: 'cut',
          sorter: (a, b) => sortByType(a, b, 'cut', 'letter'),
        },
        {
          title: 'Cert. Type',
          dataIndex: 'certificate_type',
          sorter: (a, b) => sortByType(a, b, 'certificate_type', 'letter'),
        },
        {
          title: 'Cert. No',
          dataIndex: 'certificate_number',
          sorter: (a, b) => sortByType(a, b, 'certificate_number', 'letter'),
        },
        {
          title: 'Comments',
          dataIndex: 'comments',
        },
        {
          title: 'True Cost CAD',
          dataIndex: 'true_cost_cad',
          sorter: (a, b) => a.true_cost_cad - b.true_cost_cad,
        },
        {
          title: 'True Cost USD',
          dataIndex: 'true_cost_usd',
          sorter: (a, b) => a.true_cost_usd - b.true_cost_usd,
        },
        {
          title: 'Sell',
          dataIndex: 'sell_cad',
          sorter: (a, b) => a.sell_cad - b.sell_cad,
        },
        {
          title: 'Detail',
          fixed: 'right',
          render: (item) =>
            <span>
             <Link to={'/inventory/view/' + item.id}>
              <LinkButton><Icon type="eye"/></LinkButton>
             </Link>
              <Divider type="vertical"/>
              <Link to={'/inventory/edit/' + item.id}>
                <LinkButton><Icon type="edit"/></LinkButton>
              </Link>
            </span>
        }
      ]
  }

  render() {
    const {sold} = this.props;
    return (
      <>
        <div style={{float: 'right', marginBottom: '10px'}}>
          <CSVLink
            data={sold}
            filename={moment().format('YYYY-MM-DD') + "-Sold-Diamonds.csv"}
          >
            Download Sold Diamonds
          </CSVLink>
        </div>
        <br/>
        <Table
          rowKey="id"
          dataSource={sold}
          columns={this.columns}
          pagination={false}
          size="middle"
          scroll={{x: true}}
        />
      </>
    )
  }
}