import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Row, Col} from 'antd';
import {regexAdjustment, regexMoney} from '../../utils/utility';

const {Item} = Form;

class SellTierModal extends Component {

  constructor(props) {
    super(props);
    this.props.setForm(this.props.form)
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      number_1, number_2, multiple_number, plus_number_1, plus_number_2
    } = this.props.tier;

    return (
      <Form>
        <Row>
          <Col span={12}>
            <Item label="Start Number (>=)">
              {
                getFieldDecorator('number_1', {
                  initialValue: number_1,
                  rules: [
                    {required: true, message: 'Required'},
                    {
                      pattern: /^(0|[1-9]\d*)$/,
                      message: 'Integer Only',
                    }
                  ]
                })(<Input addonBefore="$"/>)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item label="End Number (<)">
              {
                getFieldDecorator('number_2', {
                  initialValue: number_2 || '',
                  rules: [
                    {required: true, message: 'Required'},
                    {
                      pattern: /^(0|[1-9]\d*)$/,
                      message: 'Integer Only',
                    }
                  ]
                })(<Input addonBefore="$"/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Item label="Multiple_number">
              {
                getFieldDecorator('multiple_number', {
                  initialValue: multiple_number || '',
                  rules: [
                    {required: true, message: 'Required'},
                    {pattern: regexAdjustment, message: 'Positive Number with 3 decimals Only',}
                  ]
                })(<Input addonBefore="X"/>)
              }
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Plus_number_1">
              {
                getFieldDecorator('plus_number_1', {
                  initialValue: plus_number_1 || 0,
                  rules: [
                    {required: true, message: 'Required'},
                    {pattern: regexMoney, message: 'Number with 2 decimals Only',}
                  ]
                })(<Input addonBefore="+"/>)
              }
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Plus_number_2">
              {
                getFieldDecorator('plus_number_2', {
                  initialValue: plus_number_2 || 0,
                  rules: [
                    {required: true, message: 'Required'},
                    {pattern: regexMoney, message: 'Number with 2 decimals Only',}
                  ]
                })(<Input addonBefore="+"/>)
              }
            </Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

SellTierModal.propTypes = {
  setForm: PropTypes.func.isRequired,
  tier: PropTypes.object,
};

export default Form.create()(SellTierModal)