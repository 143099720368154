import axios from 'axios';

const API = process.env.REACT_APP_API_URL;

///////////////////////////Login///////////////////////
//Login request
export const reqLogin = (username, password) => axios.post(API + '/login', {username, password});

/////////////////////////Auth/////////////////////////
const auth = axios.create();
auth.interceptors.request.use(function (config) {
  let token = localStorage.getItem('token');
  config.headers = {'Authorization': `Bearer ${token}`};
  return config
});

auth.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        console.log(401);
        localStorage.removeItem('token');
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  });


export const reqLogout = () => auth.get(API + '/logout');
///////////////////////////Customers/////////////////////
//get and search all customers
export const reqGetAllCustomers = ({currentPage, per_page, searchValue}) => auth.get(API + '/customers', {
  params: {page: currentPage, per_page, search: searchValue}
});

//get a customer by id
export const reqGetCustomerById = (id) => auth.get(API + '/customers/' + id);

//update customer
export const reqUpdateCustomer = (id, customer) => auth.put(API + '/customers/' + id, customer);

//add new customer
export const reqAddCustomer = (customer) => auth.post(API + '/customers', customer);

//delete customer
export const reqDeleteCustomer = (id) => auth.delete(API + '/customers/' + id);

//get all customers full name
export const reqGetCustomersName = () => auth.get(API + '/customersName');

///////////////////////////Vendors/////////////////////
//get all vendors
export const reqGetAllVendors = () => auth.get(API + '/vendors');

//get all vendors(including delete)
export const reqGetVendorsPlusDelete = ({searchName, searchType}) => auth.get(API + '/vendorsDelete', {
  params: {[searchType]: searchName}
});

//get all vendors with open order
export const reqGetVendorsWithOpenOrder = () => auth.get(API + '/vendorsWithOpenOrder');

//get order item detail in vendor dashboard by vendor id
export const reqGetOrderItemsByVendor = (id) => auth.get(API + '/orderItemsByVendor/' + id);

//search vendors
export const reqSearchVendors = ({searchName, searchType}) => auth.get(API + '/vendors', {
  params: {[searchType]: searchName}
});

//update vendor
export const reqUpdateVendor = (id, vendor) => auth.put(API + '/vendors/' + id, vendor);

//add new vendor
export const reqAddVendor = (vendor) => auth.post(API + '/vendors', vendor);

//delete vendor
export const reqDeleteVendor = (id) => auth.delete(API + '/vendors/' + id);

//put vendor back
export const reqPutVendorBack = (id) => auth.put(API + '/vendors/active/' + id);

//////////////////////////Users/////////////////////////////
//get all users
export const reqGetAllUsers = () => auth.get(API + '/users');

//search user
export const reqSearchUsers = ({searchName, searchType}) => auth.get(API + '/users', {
  params: {[searchType]: searchName}
});

//update user
export const reqUpdateUser = (id, user) => auth.put(API + '/users/' + id, user);

//add new user
export const reqAddUser = (user) => auth.post(API + '/users', user);

//delete user
export const reqDeleteUser = (id) => auth.delete(API + '/users/' + id);

//reset password
export const reqRestPwd = (id, user) => auth.put(API + '/userPwd/' + id, user);

//get all sales
export const reqGetSales = () => auth.get(API + '/userSales');

//get all sales(including delete)
export const reqGetSalesPlusDelete = () => auth.get(API + '/userSalesDelete');

/////////////////////////Orders//////////////////
//get all orders
export const reqGetAllOrders = (status) => auth.get(API + '/orders', {
  params: {status: status}
});

export const reqGetOrdersByStatusJobDescription = (status, job_description) => auth.get(API + '/ordersByJobDescription', {
  params: {status, job_description}
});

//all orders page
export const reqOrders = ({
                            currentPage,
                            per_page,
                            searchType,
                            searchValue,
                            sortType,
                            sortValue
                          }) => auth.get(API + '/allOrders', {
  params: {
    page: currentPage,
    per_page,
    searchType,
    searchValue,
    sortType,
    sortValue
  }
});

//get orders by customer Id
export const reqGetOrdersByCustomerId = (id) => auth.get(API + '/ordersByCustomer/' + id);

//get an order(all the information order need to display in the order page) by order Id
export const reqGetOrderByOrderId = (id) => auth.get(API + '/orders/' + id);

//get order info for Monday.com
export const reqGetMondayInfo = (id) => auth.get(API + '/mondayInfo/' + id);

//get an order in view page
export const reqGetOrderByOrderIdInView = (id) => auth.get(API + '/ordersView/' + id);

//get an order view by order Id
export const reqGetOrderItemsByOrderId = (id) => auth.get(API + '/print/' + id);

//get order info by order Id
export const reqGetOrderInfoByOrderId = (id) => auth.get(API + '/orderInfo/' + id);

//delete po number and vendor and statues
export const reqDeletePo = (id) => auth.put(API + '/po/' + id);

//get fee by order Id
export const reqGetOrderFeeByOrderId = (id) => auth.get(API + '/orderFee/' + id);

//overwrite tax by order id
export const reqOverWriteTax = (id, tax) => auth.put(API + '/orderFee/' + id, tax);

//add an order
export const reqAddOrder = (order) => auth.post(API + '/orders', order);

//update an order
export const reqUpdateOrder = (id, order) => auth.put(API + '/orders/' + id, order);

//delete an order
export const reqDeleteOrder = (id) => auth.delete(API + '/orders/' + id);

//move an order to Purgatory
export const reqMoveToPurgatory = (id) => auth.put(API + '/orderPurgatory/' + id);

//move an order back from to Purgatory
export const reqMoveBackFromPurgatory = (id) => auth.put(API + '/orderBackFromPurgatory/' + id);

//update discount
export const reqUpdateDiscount = (id, discount_type, discount_percent) => auth.put(API + '/discount/' + id,
  {discount_type, discount_percent});

////////////////////////////address//////////////
//get address by order id
export const reqGetAddressOrderId = (id) => auth.get(API + '/addresses/' + id);

//add/update address
export const reqAddAddress = (address) => auth.post(API + '/addresses', address);

//get distinct counties from addresses table
export const reqGetDistCountries = () => auth.get(API + '/addressCountries');

//get distinct province from addresses table by country name
export const reqGetDistProvincesByCountry = (country) => auth.get(API + '/addressProvinces/' + country);

///////////////////Notes//////////////////////
//get notes by order Id
export const reqGetNotesByOrderId = (id) => auth.get(API + '/notesByOrder/' + id);

//update note
export const reqUpdateNote = (id, note) => auth.put(API + '/notes/' + id, note);

//add new note
export const reqAddNote = (note) => auth.post(API + '/notes', note);

//delete note
export const reqDeleteNote = (id) => auth.delete(API + '/notes/' + id);

///////////////////Payments//////////////////////
//get payments by order Id
export const reqGetPaymentsByOrderId = (id) => auth.get(API + '/paymentsByOrder/' + id);

//add new payment
export const reqAddPayment = (payment) => auth.post(API + '/payments', payment);

////////////////////OrderItem///////////////////
//delete order item (Diamonds,Jewelleries,Components,Repairs,Stones,Style Details)
export const reqDeleteOrderItem = (id) => auth.delete(API + '/orderItems/' + id);

//change job status to completed by order_item_ids
export const reqUpdateStatusToCompletedByOrderItemId = (order_item_ids) => auth.put(API + '/changeJobStatus',
  {order_items: order_item_ids});

//change job status by order_item_id
export const reqUpdateStatusByOrderItemId = (order_item_id, status) => auth.put(API + '/jobStatus/' + order_item_id,
  {status});

//update order item exclude status
export const reqUpdateExcludeByOrderId = (order_id, order_item_id_array) => auth.put(API + '/excludeByOrderId/' + order_id, {order_item_id_array});

//update order each item exclude status by order_item_id
export const reqUpdateExcludeByOrderItemId = (order_item_id) => auth.put(API + '/excludeByOrderItemId/' + order_item_id);

//get order item by type, order id
export const reqGetOrderItemsByTypeId = (type, id) => auth.get(API + `/${type}ByOrder/${id}`);

//add order item by type, order_item
export const reqAddOrderItemByType = (type, order_item) => auth.post(API + `/${type}`, order_item);

////add order item by type, order_item_id, order_item
export const reqUpdateOrderItemIdByType = (type, order_item_id, order_item) => auth.put(API + `/${type}/${order_item_id}`, order_item);

//////////////////////Report/////////////////////////////

//get report AccountsReceivable
export const reqGetAccountsReceivable = () => auth.get(API + '/reports/accountsReceivable');

//get report cost
export const reqGetReportCost = (report) => auth.get(API + '/reports/cost', {
  params: {
    order_date: report.order_date,
    order_date_from: report.order_date_from,
    order_date_to: report.order_date_to,
    pick_up_from: report.pick_up_from,
    pick_up_to: report.pick_up_to,
    order_number: report.order_number,
    status: report.status,
    sales_representitive: report.sales_representitive,
    job_description: report.job_description,
    job_type: report.job_type,
  }
});

//export excel
export const reqExportCostReport = (report) => auth.get(API + '/reports/exportCost', {
  params: {
    order_date: report.order_date,
    order_date_from: report.order_date_from,
    order_date_to: report.order_date_to,
    pick_up_from: report.pick_up_from,
    pick_up_to: report.pick_up_to,
    order_number: report.order_number,
    status: report.status,
    sales_representitive: report.sales_representitive,
    job_description: report.job_description,
    payment_date_from: report.payment_date_from,
    payment_date_to: report.payment_date_to,
    countries: report.countries,
    provinces: report.provinces,
  },
  responseType: 'arraybuffer'
});


//////////////////////////country state selection/////////////////
//get all countries
export const reqGetCountries = () => auth.get(API + '/world/countries');

//get states by country
export const reqGetStates = (country) => auth.get(API + '/world/states/' + country);

//get cities by state
export const reqGetCities = (state) => auth.get(API + '/world/cities/' + state);


////////////////////calendar//////////////////////
export const reqGetCalendar = () => auth.get(API + '/calendar');


////////////////Adjustment/////////////////////
//get all adjustments
export const reqGetAllAdjustments = () => auth.get(API + '/adjustments');

//get current adjustment
export const reqGetCurrentAdjustment = () => auth.get(API + '/adjustmentsCurrent');

//update current adjustment
export const reqUpdateAdjustment = (adjustment) => auth.post(API + '/adjustmentsCurrent', adjustment);

//add adjustment
export const reqAddAdjustment = (adjustment) => auth.post(API + '/adjustments', adjustment);


///////////////Sell Tier///////////////////////
export const reqGetAllTiers = () => auth.get(API + '/sellTiers');

//add / update tier
export const reqAddTier = (tier) => auth.post(API + '/sellTiers', tier);

//delete tier
export const reqDeleteTier = (id) => auth.delete(API + '/sellTiers/' + id);


///////////////Inventory////////////////////
export const reqGetAllMemos = () => auth.get(API + '/inventory/memos');

export const reqGetAllLocations = (location) => auth.get(API + '/inventory/locations/' + location);

export const reqGetDiamondsAddedInOrder = () => auth.get(API + '/inventory/addedInOrder');

export const reqGetAllSold = (values) => auth.get(API + '/inventory/sold', {
  params: {
    between_dates: values.between_dates || '',
    oc: values.oc || '',
    reported: values.reported || '',
    vendor_id: values.vendor_id || '',
  }
});

//delete diamond
export const reqDeleteDiamond = (id) => auth.delete(API + '/inventory/diamonds/' + id);

//add invoice and diamonds
export const reqAddInvoices = (invoices) => auth.post(API + '/inventory/addDiamonds', invoices);

//change diamond state by diamond_id
export const reqUpdateStateByDiamondIds = (diamond_ids_arr, state) => auth.put(API + '/inventory/changeDiamondState',
  {state: state, diamonds: diamond_ids_arr});

//change diamond location by diamond_id
export const reqUpdateLocationByDiamondIds = (diamond_ids_arr, location) => auth.put(API + '/inventory/changeDiamondLocation',
  {location: location, diamonds: diamond_ids_arr});

//get diamond detail
export const reqGetDiamondDetail = (id) => auth.get(API + '/inventory/diamondDetail/' + id);

export const reqGetEditDiamondDetail = (id) => auth.get(API + '/inventory/editDiamondDetail/' + id);

export const reqUpdateEditDiamondDetail = (id, diamond) => auth.put(API + '/inventory/editDiamondDetail/' + id, diamond);

//global change diamond price
export const reqGlobalChangeDiamondPrice = () => auth.put(API + '/inventory/globalChangePrice');

//get all in-site diamonds vendors
export const reqGetInventoryVendors = () => auth.get(API + '/inventory/vendors');

//get all in-site diamonds invoices by vendor id
export const reqGetInvoices = (id) => auth.get(API + '/inventory/invoice/' + id);

//get all off site diamonds
export const reqGetOffSiteDiamonds = ({currentPage, per_page, searchValue}) => auth.get(API + '/inventory/offSite', {
  params: {page: currentPage, per_page, search: searchValue}
});

//get all diamonds by invoice number
export const reqGetAllDiamondsByInvoiceNo = ($invoice_number) => auth.get(API + '/inventory/diamonds/' + $invoice_number);

//search diamond
export const reqSearchDiamond = (search) => auth.get(API + '/inventory/diamonds/search', {
  params: {
    diamond_type: search.diamond_type,
    shape: search.shape,
    carats: search.carats,
    colour: search.colour,
    clarity: search.clarity,
    price: search.price,
  }
});

export const reqSearchPageDiamond = (val1, val2) => auth.get(API + '/inventory/diamonds/searchPage', {
  params: {
    diamond_type: val1.diamond_type,
    shape: val1.shape,
    carats: val1.carats,
    colour: val1.colour,
    clarity: val1.clarity,
    price: val1.price,
    sold_to: val2.sold_to,
    locations: val2.location
  }
});

//return diamonds
export const reqReturnDiamonds = (diamond_ids) => auth.put(API + '/inventory/returnDiamond/', {diamonds: diamond_ids});

//calculator
export const reqGetBuyPrice = (values) => auth.post(API + '/calculator/getBuyPrice', values);

export const reqGetSellPrice = (values) => auth.post(API + '/calculator/getSellPrice', values);


//style
export const reqGetManufacturingInfo = (order_id) => auth.get(API + '/manufacturing/' + order_id);