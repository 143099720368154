import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Form, Input, Select, Row, Col, Checkbox} from 'antd';

const {Item} = Form;
const {Option} = Select;

class AddForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
    };
    this.props.setForm(this.props.form);
  }

  validateToNextPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  compareToFirstPassword = (rule, value, callback) => {
    const {form} = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  handleConfirmBlur = e => {
    const {value} = e.target;
    this.setState({confirmDirty: this.state.confirmDirty || !!value});
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 15}
    };

    return (
      <Form {...formLayout} >
        <Item label="Username">
          {
            getFieldDecorator('username', {
              initialValue: '',
              rules: [
                {required: true, message: 'Username is required'},
                {pattern: /^[^\s]*$/, message: 'Cannot input space',},
                {max: 255, message: 'Maximum 255 characters'},
              ]
            })(
              <Input type="text" placeholder="Username"/>
            )
          }
        </Item>
        <Row>
          <Col span={13} offset={2}>
            <Item label="Sales Rep">
              {getFieldDecorator('is_sales_rep', {
                initialValue: 0,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={9}>
            <Item label="Active">
              {getFieldDecorator('is_active', {
                initialValue: 1,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
        </Row>
        <Item label="First Name">
          {
            getFieldDecorator('first_name', {
              initialValue: '',
              rules: [
                {required: true, message: 'First Name is required'},
                {max: 255, message: 'Maximum 255 characters'},
              ]
            })(
              <Input type="text" placeholder="First Name"/>
            )
          }
        </Item>
        <Item label="Last Name">
          {
            getFieldDecorator('last_name', {
              initialValue: '',
              rules: [
                {required: true, message: 'Last Name is required'},
                {max: 255, message: 'Maximum 255 characters'},
              ]
            })(
              <Input type="text" placeholder="Last Name"/>
            )
          }
        </Item>
        <Item label="Type">
          {getFieldDecorator('user_type', {
            initialValue: 'sales',
            rules: [
              {required: true, message: 'Type is required'}
            ]
          })(
            <Select>
              <Option value="sales">Sales</Option>
              <Option value="production">Production</Option>
              <Option value="super admin">Super Admin</Option>
            </Select>
          )}
        </Item>
        <Item label="Password" hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true, message: 'Please input your password!'
              },
              {max: 80, message: 'Maximum 80 characters'},
              {validator: this.validateToNextPassword,},
            ],
          })(<Input.Password/>)}
        </Item>
        <Item label="Confirm Password" hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true, message: 'Please confirm your password!'
              },
              {max: 80, message: 'Maximum 80 characters'},
              {validator: this.compareToFirstPassword,},
            ],
          })(<Input.Password onBlur={this.handleConfirmBlur}/>)}
        </Item>
      </Form>
    )
  }
}

AddForm.propTypes = {
  setForm: PropTypes.func.isRequired,
};

export default Form.create()(AddForm)