import React, {Component} from 'react';
import {Select} from 'antd';
import PropTypes from 'prop-types';

const {Option} = Select;

class SelectInput extends Component {

  //search value
  onSearchSelect = (input) => {
    if (!!input) {
      this.inputValue = input;
      this.triggerChange(input);
    }
  };

  //Remove focus
  onBlurSelect = () => {
    if (!!this.inputValue) {
      this.triggerChange(this.inputValue);
    }
  };

  //select existed value
  onSelect = (input) => {
    this.inputValue = input;
    this.triggerChange(input);
  };

  //use onChange to change form
  triggerChange = changedValue => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  render() {
    const { value , defaultOption} = this.props;
    return (
      <Select
        value={value}
        showSearch
        onSearch={value => this.onSearchSelect(value)}
        onBlur={() => this.onBlurSelect()}
        onSelect={this.onSelect}>
        {defaultOption && <Option value=''> -- </Option>}
        {
          this.props.options.map(item => <Option value={item} key={item}> {item} </Option>)
        }
      </Select>
    )
  }
}

SelectInput.propTypes = {
  value: PropTypes.string,
  defaultOption: PropTypes.bool,
}

SelectInput.defaultProps = {
  value: "",
  defaultOption: false,
}

export default SelectInput;