import React, {Component} from 'react';
import {Card, Spin} from 'antd';
import {reqGetAllSold} from '../../../api';
import SearchSold from '../../../components/Inventory/SoldDiamond/SearchSold';
import SoldDiamondTable from '../../../components/Inventory/SoldDiamond/SoldDiamondTable';

export default class Sold extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sold: [],
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.ajaxGetSoldDiamonds({});
  }

  ajaxGetSoldDiamonds = (values) => {
    this.setState({loading: true});
    reqGetAllSold(values)
      .then(response => {
        const sold = response.data;
        this.sold = sold;
        this.setState({sold});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  handleSubmit = values => {
    if (!values) return;
    console.log(values);
    this.ajaxGetSoldDiamonds(values);
  };


  render() {
    const {loading, sold} = this.state;

    return (
      <Card>
        <SearchSold setForm={form => this.form = form} onSubmit={this.handleSubmit}/>
        <Spin spinning={loading}>
          <SoldDiamondTable sold={sold}/>
        </Spin>
      </Card>
    )
  }

}