import React, {Component} from 'react';
import {reqGetManufacturingInfo} from '../../../api';

export default class ManufacturingInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      stones: [],
    }
  }

  componentDidMount() {
    reqGetManufacturingInfo(this.props.style.order_id)
      .then(response => {
        this.setState({stones: response.data})
      })
      .catch(error => {
        console.log(error)
      });
  }

  generateStones = (stones) => {
    if (stones.length === 1) {
      return <p style={{marginBottom: 0}}>Center: {stones[0].shape} {stones[0].carats}ct {stones[0].measurements}</p>
    } else if (stones.length > 0) {
      return stones.map((item) => (
        <p key={item.id}
           style={{color: 'red', marginBottom: 0}}>Center: {item.shape} {item.carats}ct {item.measurements}</p>
      ))
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.style !== prevProps.style) {
      reqGetManufacturingInfo(this.props.style.order_id)
        .then(response => {
          this.setState({stones: response.data})
        })
        .catch(error => {
          console.log(error)
        });
    }
  }

  render() {
    const style = this.props.style;
    return (
      <div>
        {!!style.po_number ? <p style={{marginBottom: 0}}>PO#: {style.po_number}</p> :
          <p style={{color: 'red', marginBottom: 0}}>PO#: {style.po_number}</p>}
        <p style={{marginBottom: 0}}>Style: {style.style_number_description}</p>
        <p style={{marginBottom: 0}}>Mount Metal: {style.mount_metal}</p>
        {!!style.head_metal && <p style={{marginBottom: 0}}>Head Metal: {style.head_metal}</p>}
        {!!style.finger_size ? <p style={{marginBottom: 0}}>Size: {style.finger_size}</p> :
          <p style={{color: 'red', marginBottom: 0}}>Size: {style.finger_size}</p>}
        {this.generateStones(this.state.stones)}
        <br/>
        {!!style.description && <>
          <p style={{marginBottom: 0}}>Same as style, except:</p>
          <p style={{whiteSpace: 'pre-line', paddingLeft: '1em',marginBottom: 0}}>{style.description}</p>
          </>
        }
        {!!style.description && <br/>}
        <p >We supply all stones,</p>
        <p><strong>*Send CAD for approval*</strong></p>
        <p>Thanks a lot,</p>
      </div>
    )
  }


}