import React, {Component} from 'react';
import {Button, Divider, Icon, message, Modal, Spin, Table} from "antd";
import {reqGetAllDiamondsByInvoiceNo, reqReturnDiamonds,} from '../../../api';
import './../list.less'
import {withRouter, Link} from 'react-router-dom';
import LinkButton from '../../LinkButton';
import CheckDiamond from '../CheckDiamond';
import {CSVLink} from 'react-csv';
import moment from 'moment';

class InvoiceTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dialogLoading: false,
      diamonds: [],
      checkDiamonds: [],
      returnDiamonds: [],
    };
    this.initColumns();
  }

  componentDidMount() {
    this.ajaxGetAllDiamondsByInvoiceNo(this.props.invocie);
  }

  ajaxGetAllDiamondsByInvoiceNo = ($invoice_number) => {
    this.setState({loading: true});
    reqGetAllDiamondsByInvoiceNo($invoice_number)
      .then(response => {
        const diamonds = response.data;
        const returnDiamonds = diamonds.filter((diamond) => diamond.order_id == null);
        this.setState({diamonds, loading: false});
        this.setState({returnDiamonds});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //return diamonds
  onSubmit = () => {
    this.setState({dialogLoading: true})
    reqReturnDiamonds(this.selectedItems)
      .then(response => {
        message.success('Returned');
        this.setState({showCheckDiamond: false, dialogLoading: false});
        const diamondsAfterDelete = this.state.diamonds.filter(item => !this.selectedItems.includes(item.id));
        this.setState({diamonds: diamondsAfterDelete});
      })
      .catch(error => {
        console.log(error)
      });
  }


  initColumns = () => {
    this.columns =
      [
        {
          title: 'Stock#',
          dataIndex: 'stock_number',
        },
        {
          title: 'Type',
          dataIndex: 'diamond_type',
          render: (diamond_type) => diamond_type === 'NATURAL' ? <span style={{color: 'red'}}>{diamond_type} </span> :
            <span>{diamond_type}</span>,
        },
        {
          title: 'Shape',
          dataIndex: 'shape',
          sorter: (a, b) => a.shape.localeCompare(b.shape),
        },
        {
          title: 'Carats',
          dataIndex: 'carats',
          sorter: (a, b) => a.carats.localeCompare(b.carats),
        },
        {
          title: 'Colour',
          dataIndex: 'colour',
          sorter: (a, b) => a.colour.localeCompare(b.colour),
        },
        {
          title: 'Clarity',
          dataIndex: 'clarity',
          sorter: (a, b) => a.clarity.localeCompare(b.clarity),
        },
        {
          title: 'Cut',
          dataIndex: 'cut',
        },
        {
          title: 'Cert. Type',
          dataIndex: 'certificate_type',
        },
        {
          title: 'Cert. No',
          dataIndex: 'certificate_number',
        },
        {
          title: 'Sold To',
          render: (item) =>
            item.order_id &&
            <span>
                <Link to={'/orders/view/' + item.order_id}>
                    <LinkButton>{`${item.last_name}, ${item.first_name}`}</LinkButton></Link>
            </span>,
        },
        {
          title: 'True Cost',
          render: (item) =>
            item.true_cost_cad ? <span>{item.true_cost_cad}</span> : <span>{item.true_cost_usd}</span>
        },
        {
          title: 'State',
          dataIndex: 'diamond_state',
        },
        {
          title: 'Location',
          dataIndex: 'diamond_location',
        },
        {
          title: 'Detail',
          render: (item) =>
            <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
              <Divider type="vertical"/>
              <Link to={'/inventory/edit/' + item.id}><LinkButton
                onClick={() => this.props.history.push('/inventory/edit/' + item.id)}><Icon
                type="edit"/></LinkButton>
              </Link>
              <Divider type="vertical"/>
              <a target="_blank" href={item.invoice_link}>
                  <LinkButton><Icon type="link"/></LinkButton></a>
            </span>
        }
      ]
  }

  render() {
    const {diamonds, loading, dialogLoading, checkDiamonds} = this.state;
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      columnTitle: ' ',
      onChange: (selectedRowKeys) => {
        this.selectedItems = selectedRowKeys;
        const checkDiamonds = this.state.diamonds.filter(item => this.selectedItems.includes(item.id));
        this.setState({checkDiamonds});
      },
    };

    return (
      <>
        <div style={{paddingBottom: '15px'}}>
          <Button type="primary" onClick={() => this.setState({showCheckDiamond: true})} size='small'>
            Return
          </Button>
          <Modal
            width={1000}
            visible={this.state.showCheckDiamond}
            okText='Return'
            onOk={this.onSubmit}
            onCancel={() => this.setState({showCheckDiamond: false})}>
            <Spin spinning={dialogLoading}>
              <CheckDiamond diamonds={checkDiamonds}/>
            </Spin>
          </Modal>
          <div style={{float: 'right'}}>
            <CSVLink
              data={this.state.returnDiamonds}
              filename={moment().format('YYYY-MM-DD-') + this.props.invocie + "-return.csv"}
            >
              Download Return
            </CSVLink>
          </div>
        </div>
        <Table
          className='memo-table'
          loading={loading}
          rowKey='id'
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={diamonds}
          size="small"
          rowSelection={rowSelection}
          scroll={{x: 'max-content'}}
          rowClassName={(record, index) => (record.status==="Hold") ? 'hold' : ((record.order_id) ? ('sold') : (''))}
        />
      </>
    )
  }
}

export default withRouter(InvoiceTable);