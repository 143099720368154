 import React, {Component} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import List from "../pages/Order/List";
import Edit from "../pages/Order/Edit";
import View from "../pages/Order/View";
import All from '../pages/Order/All';

class OrderRouter extends Component {

  render() {
    return (
      <Switch>
        <Route path="/orders/Incomplete" exact render={(props) => <List status='Incomplete' {...props} />}/>
        <Route path="/orders/AwaitingStone" exact render={(props) => <List status='Awaiting Stone' {...props} />}/>
        <Route path="/orders/AwaitingCAD" exact render={(props) => <List status='Awaiting CAD' {...props} />}/>
        <Route path="/orders/AwaitingResin" exact render={(props) => <List status='Awaiting Resin' {...props} />}/>
        <Route path="/orders/AwaitingReplica" exact render={(props) => <List status='Awaiting Replica' {...props} />}/>
        <Route path="/orders/AwaitingRepair" exact render={(props) => <List status='Awaiting Repair' {...props} />}/>
        <Route path="/orders/InProgress" exact render={(props) => <List status='In Progress' {...props} />}/>
        <Route path="/orders/ReadyForPickup" exact render={(props) => <List status='Ready For Pickup' {...props} />}/>
        <Route path="/orders/Purgatory" exact render={(props) => <List status='Purgatory' {...props} />}/>
        {/*<Route path="/orders/Completed" exact render={(props) => <InSite status='Completed'/>}/>*/}
        <Route path="/orders/list" exact component={All}/>
        <Route path="/orders/edit/:id" exact component={Edit}/>
        <Route path="/orders/view/:id" exact component={View}/>
        <Redirect to="/orders"/>
      </Switch>
    )
  }
}

export default OrderRouter;