import React, {Component} from 'react';
import {Card, Select, Input, Button, Icon, Table, Divider} from 'antd';
import {reqGetAllCustomers} from "../../api";
import LinkButton from "../../components/LinkButton";
import {formatDate} from "../../utils/utility";
import {Link} from 'react-router-dom';

const Option = Select.Option;
const PAGE_SIZE = 20;

export default class List extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      customers: [], //customer list
      searchValue: '',
      total: 0,
      currentPage:1
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.ajaxGetCustomers();
  }

  //get customers
  ajaxGetCustomers = () =>{
    this.setState({loading: true});
    let {currentPage,searchValue} = this.state;
    reqGetAllCustomers({currentPage,per_page:PAGE_SIZE,searchValue})
      .then(response => {
        const customers = response.data.data;
        const total = response.data.total;
        this.setState({customers,total});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //Search
  onSearchCustomers = () => {
    this.setState({currentPage: 1},()=>this.ajaxGetCustomers());
  };

  //Pagination changed
  handlePageChange = page => {
    window.scrollTo({top: 100, behavior: 'smooth'});
    this.setState({currentPage: page},()=>this.ajaxGetCustomers());
  };

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Customer Name',
        dataIndex: 'full_name'
      },
      {
        title: 'Customer phone',
        dataIndex: 'phone_primary',
      },
      {
        title: 'Partner name',
        dataIndex: 'partner_first_name',
      },
      {
        title: 'Partner phone',
        dataIndex: 'partner_phone_primary',
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'Last Order Date',
        dataIndex: 'last_order_time',
        render: formatDate
      },
      {
        title: 'Action',
        render: (customer) =>
          <span>
            <Link to={'/customers/view/' + customer.id}><LinkButton onClick={() => {
              this.props.history.push('/customers/view/' + customer.id);
            }
            }><Icon type="eye"/></LinkButton></Link>
            <Divider type="vertical"/>
            <Link to={'/customers/edit/' + customer.id}><LinkButton onClick={() => {
              this.props.history.push('/customers/edit/' + customer.id);
            }
            }><Icon type="edit"/></LinkButton></Link>
          </span>
      },
    ];
  };

  render() {
    const {loading, customers, searchValue,total,currentPage} = this.state;

    const title = (
      <span>
        <Select
          style={{width: 200}}
          value='Name/ Email/ Phone'
          showArrow={false}
        >
          <Option value="email">Name/Email/Phone</Option>
        </Select>
        <Input
          style={{width: 200, margin: '0 10px'}}
          value={searchValue}
          onChange={event => this.setState({searchValue: event.target.value})}
          onPressEnter={this.onSearchCustomers}
        />
        <Button type="primary" onClick={() => {
          this.onSearchCustomers();
        }}>SEARCH</Button>
      </span>
    );

    const extra = (
      <Link to='/customers/new'><Button type="primary" onClick={() => {
        this.props.history.push('/customers/new');
      }}>
        <Icon type="plus"/>
        Add Customer
      </Button></Link>
    );

    return (
      <div className="customer">
        <Card title={title} extra={extra}>
          <Table
            rowKey="id"
            loading={loading}
            dataSource={customers}
            columns={this.columns}
            pagination={{
              total,
              defaultPageSize: PAGE_SIZE,
              showQuickJumper: true,
              current: currentPage,
              onChange: this.handlePageChange
            }}
            size="middle"
            scroll={{ x: true }}
          />
        </Card>
      </div>
    );
  }
}
