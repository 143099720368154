import React, {Component} from 'react';
import {Card, Divider, Icon, Table} from 'antd';
import {reqGetDiamondsAddedInOrder} from '../../../api';
import {Link} from 'react-router-dom';
import LinkButton from '../../../components/LinkButton';

export default class Update extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      diamonds: []
    };
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    reqGetDiamondsAddedInOrder()
      .then(response => {
        const diamonds = response.data;
        this.setState({diamonds});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  initColumns = () => {
    this.columns =
      [
        {
          title: 'Type',
          dataIndex: 'diamond_type',
          render: (diamond_type) => diamond_type === 'NATURAL' ? <span style={{color: 'red'}}>{diamond_type} </span> :
            <span>{diamond_type}</span>,
        },
        {
          title: 'Shape',
          dataIndex: 'shape',
        },
        {
          title: 'Carats',
          dataIndex: 'carats',
        },
        {
          title: 'Colour',
          dataIndex: 'colour',
        },
        {
          title: 'Clarity',
          dataIndex: 'clarity',
        },
        {
          title: 'Cut',
          dataIndex: 'cut',
        },
        {
          title: 'Cert. Type',
          dataIndex: 'certificate_type',
        },
        {
          title: 'Cert. No',
          dataIndex: 'certificate_number',
        },
        {
          title: 'Sold To',
          render: (item) =>
            item.order_id &&
            <span>
                <Link to={'/orders/view/' + item.order_id}>
                    <LinkButton>{`${item.last_name}, ${item.first_name}`}</LinkButton></Link>
            </span>,
        },
        {
          title: 'Detail',
          fixed: 'right',
          render: (item) =>
            <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
              <Divider type="vertical"/>
              <Link to={'/inventory/edit/' + item.id}><LinkButton
                onClick={() => this.props.history.push('/inventory/edit/' + item.id)}><Icon
                type="edit"/></LinkButton>
              </Link>
            </span>
        }
      ]
  }

  render() {
    const {diamonds, loading} = this.state;

    return (
      <Card>
        <Table
          rowKey='id'
          loading={loading}
          dataSource={diamonds}
          columns={this.columns}
          pagination={false}
          size="middle"
          scroll={{x: true}}
        />
      </Card>
    )
  }

}