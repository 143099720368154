import React, {Component} from 'react';
import {Button, Divider, Icon, message, Modal, Spin, Table} from "antd";
import {reqGetAllLocations, reqUpdateLocationByDiamondIds} from '../../../api';
import {shapeSort, sortByType} from '../../../utils/utility';
import './../list.less'
import {withRouter, Link} from 'react-router-dom';
import LinkButton from '../../LinkButton';
import CheckDiamond from '../CheckDiamond';
import {CSVLink} from 'react-csv';
import moment from 'moment';

class LocationTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      loading: false,
      diamonds: [],
      total: 0,
      showCheckDiamond: false,
      dialogLoading: false,
      csvData: []
    }

    this.initColumns();
  }

  componentDidMount() {
    this.ajaxGetAllLocations();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    //if update is true and this.props.update !== prevProps.update
    if (this.props.update !== prevProps.update && this.props.update) {
      this.ajaxGetAllLocations();
      this.props.onUpdateData();
    }
  }

  ajaxGetAllLocations() {
    this.setState({loading: true});
    reqGetAllLocations(this.props.locations)
      .then(response => {
        const locations = response.data;
        //total
        let sum = response.data.reduce((pre, item) => pre + item.sell_cad * 1, 0);
        //csv
        const csvData = locations.map((item) => {
          const {
            id, name, diamond_type, shape, carats, colour, clarity, cut, measurements, certificate_type, certificate_number,
            inventory_date, comments, sell_cad
          } = item;
          let customer = "";
          if (item.last_name) {
            customer = item.last_name + ", " + item.first_name;
          }
          return {
            id,
            name,
            diamond_type,
            shape,
            carats,
            colour,
            clarity,
            cut,
            measurements,
            certificate_type,
            certificate_number,
            inventory_date,
            comments,
            customer,
            sell_cad
          };
        });
        this.setState({locations, loading: false, total: sum.toFixed(2), csvData});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //changeLocation
  //changeStatus
  onSubmit = () => {
    this.setState({dialogLoading: true})
    console.log(this.selectedItems)
    reqUpdateLocationByDiamondIds(this.selectedItems, 'In Manufacturing')
      .then(response => {
        message.success('Changed Successfully');
        this.setState({showCheckDiamond: false, dialogLoading: false});
        this.props.onUpdateData();
      })
      .catch(error => {
        console.log(error)
      });
  };

  initColumns = () => {
    this.columns =
      [
        {
          title: 'Vendor',
          dataIndex: 'name',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'name', 'letter'),
        },
        {
          title: 'OC',
          dataIndex: 'original_certificate',
          render: (item) => item === 1 ? 'Yes' : '',
          sorter: (a, b) => sortByType(a, b, 'original_certificate', 'number'),
        },
        {
          title: 'Sold To',
          render: (item) =>
            item.order_id &&
            <span>
                <Link to={'/orders/view/' + item.order_id}>
                    <LinkButton>{`${item.last_name}, ${item.first_name}`}</LinkButton></Link>
            </span>,
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'last_name', 'letter'),
        },
        {
          title: 'Type',
          dataIndex: 'diamond_type',
          render: (diamond_type) => diamond_type === 'NATURAL' ? <span style={{color: 'red'}}>{diamond_type} </span> :
            <span>{diamond_type}</span>,
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'diamond_type', 'letter'),
        },
        {
          title: 'Shape',
          dataIndex: 'shape',
        },
        {
          title: 'Carats',
          dataIndex: 'carats',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'carats', 'number'),
        },
        {
          title: 'Colour',
          dataIndex: 'colour',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'colour', 'letter'),
        },
        {
          title: 'Clarity',
          dataIndex: 'clarity',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'clarity', 'letter'),
        },
        {
          title: 'Cut',
          dataIndex: 'cut',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'cut', 'letter'),
        },
        {
          title: 'Cert. Type',
          dataIndex: 'certificate_type',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'certificate_type', 'letter'),
        },
        {
          title: 'Cert. No',
          dataIndex: 'certificate_number',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'certificate_number', 'letter'),
        },
        {
          title: 'Comments',
          dataIndex: 'comments',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'comments', 'letter'),
        },
        {
          title: 'State',
          dataIndex: 'diamond_state',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'diamond_state', 'letter'),
        },
        {
          title: 'True Cost CAD',
          dataIndex: 'true_cost_cad',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'true_cost_cad', 'number'),
        },
        {
          title: 'Shipping Cost CAD',
          dataIndex: 'shipping_cost_cad',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'shipping_cost_cad', 'number'),
        },
        {
          title: 'True Cost USD',
          dataIndex: 'true_cost_usd',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'true_cost_usd', 'number'),
        },
        {
          title: 'Shipping Cost USD',
          dataIndex: 'shipping_cost_usd',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'shipping_cost_usd', 'number'),
        },
        {
          title: 'Market Cost USD',
          dataIndex: 'market_cost_usd',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'market_cost_usd', 'number'),
        },
        {
          title: 'Buffer Rate',
          dataIndex: 'buffer_rate',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'buffer_rate', 'number'),
        },
        {
          title: ' Currency Cost CAD',
          dataIndex: 'currency_cost_cad',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'currency_cost_cad', 'number'),
        },
        {
          fixed: 'right',
          title: 'Sell',
          dataIndex: 'sell_cad',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'sell_cad', 'number'),
        },
        {
          fixed: 'right',
          title: 'Reduced',
          dataIndex: 'reduced_cad',
          sorter: (a, b, sortOrder) => shapeSort(a, b, sortOrder, 'reduced_cad', 'number'),
        },
        {
          title: 'Detail',
          fixed: 'right',
          render: (item) =>
            <span>
            <Link to={'/inventory/view/' + item.id}>
            <LinkButton onClick={() => this.props.history.push('/inventory/view/' + item.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
              <Divider type="vertical"/>
              <Link to={'/inventory/edit/' + item.id}><LinkButton
                onClick={() => this.props.history.push('/inventory/edit/' + item.id)}><Icon
                type="edit"/></LinkButton>
              </Link>
            </span>
        }
      ]


  }

  render() {
    const {locations, loading, dialogLoading, total, csvData} = this.state;
    // rowSelection object indicates the need for row selection
    const rowSelection = {
      columnTitle: ' ',
      onChange: (selectedRowKeys) => {
        this.selectedItems = selectedRowKeys;
        const diamonds = this.state.locations.filter(item => this.selectedItems.includes(item.id));
        this.setState({diamonds});
      },
    };

    return (
      <>
        <div style={{paddingBottom: '15px'}}>
          {this.props.locations === 'Instore' &&
          <>
            <Button type="primary" onClick={() => this.setState({showCheckDiamond: true})} size='small'>
              Change Location to In Manufacturing
            </Button>
            <Modal
              width={1000}
              visible={this.state.showCheckDiamond}
              okText='Change Location to In Manufacturing'
              onOk={this.onSubmit}
              onCancel={() => this.setState({showCheckDiamond: false})}>
              <Spin spinning={dialogLoading}>
                <CheckDiamond diamonds={this.state.diamonds}/>
              </Spin>
            </Modal>
          </>
          }
          <div style={{float: 'right', paddingBottom: '10px'}}>
            <CSVLink
              data={csvData}
              filename={`${moment().format('YYYY-MM-DD')}-${this.props.locations}.csv`}
            >
              Download {this.props.locations}
            </CSVLink>
          </div>
        </div>
        <Table
          className='memo-table'
          loading={loading}
          rowKey='id'
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={locations}
          size="small"
          rowSelection={this.props.locations === 'Instore' ? rowSelection : ""}
          scroll={{x: 'max-content'}}
          rowClassName={(record, index) => record.shape.toLowerCase()}
        />
        <h3 style={{textAlign: "right", paddingTop: '5px'}}>Total(Sell CAD): {total}</h3>
      </>
    )
  }
}

export default withRouter(LocationTable);