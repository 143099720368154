import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Checkbox, Row, Col, Select} from 'antd';
import {regexMoney} from '../../utils/utility';

const {Item} = Form;
const {Option} = Select;

class AddUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.props.setForm(this.props.form)
  }

  render() {
    const {getFieldDecorator, getFieldValue} = this.props.form;
    const {name, type, currency, shipping_cost, is_memo, is_cash_memo, memo_day, cash_memo_day, default_state} = this.props.vendor;

    return (
      <Form>
        <Row>
          <Col span={12}>
            <Item label="Vendor Name" labelCol={{span: 9}} wrapperCol={{span: 15}}>
              {
                getFieldDecorator('name', {
                  initialValue: name || '', //add or update
                  rules: [
                    {required: true, message: 'Required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <Input type="text"/>
                )
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Item label=" Vendor Type::" labelCol={{span: 24}} wrapperCol={{offset: 1, span: 23}}>
            {getFieldDecorator('type', {
              initialValue: type || [],
              rules: [
                {required: true, message: 'Required'}
              ]
            })(
              <Checkbox.Group style={{width: '100%'}}>
                <Row>
                  <Col span={8}>
                    <Checkbox value="diamond">diamond</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="repair">repair</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="jewellery">jewellery</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="style">style</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="stone">stone</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="component">component</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            )}
          </Item>
        </Row>
        {
          getFieldValue('type').includes("diamond") &&
          <>
          <Row>
            <Col span={12}>
              <Item label="Default Currency" labelCol={{span: 10}} wrapperCol={{offset: 1, span: 8}}>
                {
                  getFieldDecorator('currency', {
                    initialValue: currency || '', //add or update
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select>
                      <Option value='CAD' key='CAD'>CAD</Option>
                      <Option value='USD' key='USD'>USD</Option>
                    </Select>
                  )
                }
              </Item>
            </Col>
            <Col span={12}>
              <Item label='Shipping Cost' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('shipping_cost', {
                    initialValue: shipping_cost || 0,
                    rules: [
                      {required: true, message: 'Required'},
                      {
                        pattern: regexMoney,
                        message: 'Number with 2 decimal places Only',
                      }
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
          <Col span={17}>
          <Item label="Default State" labelCol={{span: 6}} wrapperCol={{span: 8}}>
          {
            getFieldDecorator('default_state', {
              initialValue: default_state || '', //add or update
            })(
              <Select allowClear>
                <Option value='Memo' key='Memo'>Memo</Option>
                <Option value='Cash Memo' key='Cash Memo'>Cash Memo</Option>
              </Select>
            )
          }
          </Item>
          </Col>
          </Row>
          <Row>
          <Col span={8}>
          <Item label="Cash Memo" labelCol={{offset: 1, span: 11}} wrapperCol={{span: 3}}>
          {getFieldDecorator('is_cash_memo', {
            initialValue: is_cash_memo || false,
            valuePropName: 'checked',
          })(
            <Checkbox></Checkbox>
          )}
          </Item>
          </Col>
          {
            getFieldValue('is_cash_memo') &&
            <Col span={12}>
              <Item label="Cash Memo Day" labelCol={{span: 10}} wrapperCol={{span: 10}}>
                {
                  getFieldDecorator('cash_memo_day', {
                    initialValue: cash_memo_day || '',
                    rules: [
                      {
                        pattern: /^[0-9]*[1-9][0-9]*$/,
                        message: 'Number Only',
                      }
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          }
          </Row>
          <Row>
          <Col span={8}>
          <Item label="Memo" labelCol={{offset: 1, span: 7}} wrapperCol={{span: 10}}>
          {getFieldDecorator('is_memo', {
            initialValue: is_memo || false,
            valuePropName: 'checked',
          })(
            <Checkbox></Checkbox>
          )}
          </Item>
          </Col>
          {
            getFieldValue('is_memo') &&
            <Col span={12}>
              <Item label="Memo Day" labelCol={{span: 10}} wrapperCol={{span: 10}}>
                {
                  getFieldDecorator('memo_day', {
                    initialValue: memo_day || '',
                    rules: [
                      {
                        pattern: /^[0-9]*[1-9][0-9]*$/,
                        message: 'Number Only',
                      }
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          }
          </Row>
          </>
        }

      </Form>
    )
  }
}

AddUpdateForm.propTypes = {
  setForm: PropTypes.func.isRequired,
  vendor: PropTypes.object,
};

export default Form.create()(AddUpdateForm)