import React, {Component} from 'react';
import {Card} from 'antd';

export default class Dashboard extends Component {

  render() {

    return (
      <div className="dashboard">
        <Card title="I am dashboard">

        </Card>
      </div>
    );
  }
}
