import decode from 'jwt-decode';

//get user type from token
export const getUserType = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedData = decode(token);
    return decodedData.user_type;
  } else {
    return "";
  }
}

//get user name from token
export const getUserName = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedData = decode(token);
    return decodedData.username;
  } else {
    return "";
  }
}

export const hasRole = (user_type, roles) => roles.some(role => user_type.includes(role));

