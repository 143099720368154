import React, {Component} from 'react';
import {Card, Icon, Table, Divider, Select, Input, Button, DatePicker} from 'antd';
import LinkButton from "../../components/LinkButton";
import {reqOrders} from "../../api";
import {formatDate, momentToString} from "../../utils/utility";
import {Link} from 'react-router-dom';

const Option = Select.Option;
const PAGE_SIZE = 20;

export default class All extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      orders: [], //customer list
      searchType: 'name', //default search customer name
      searchValue: '',
      currentPage: 1,
      total: 0,
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    this.ajaxGetOrders();
  }

  ajaxGetOrders = (sortType,sortValue) => {
    this.setState({loading: true});
    let {currentPage,searchValue,searchType} = this.state;
    reqOrders({
      currentPage,
      per_page:PAGE_SIZE,
      searchType,searchValue,
      sortType,sortValue})
      .then(response => {
        const orders = response.data.data;
        const total = response.data.total;
        this.setState({orders,total});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  //Search
  handlerSearch = () => {
    this.setState({currentPage: 1},()=>this.ajaxGetOrders());
  };

  //search content change
  handleSearchChange = (event,searchType) =>{
    switch(searchType) {
      case 'name':
        this.setState({searchValue: event.target.value});
        break;
      case 'date':
        this.setState({searchValue: formatDate(momentToString(event))});
        break;
      case 'sales':
        this.setState({searchValue: event.target.value});
        break;
      default:
    }
  }

  //sort
  handleTableChange = (pagination, filters, sorter) => {
    window.scrollTo({top: 100, behavior: 'smooth'});
    let page = pagination.current;
    let sortType = sorter.order;
    let sortValue = sorter.columnKey;
    console.log(sortValue)
    this.setState({currentPage: page},()=>this.ajaxGetOrders(sortType,sortValue));
  };


  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Order#',
        dataIndex: 'order_number'
      },
      {
        title: 'Customer',
        dataIndex: 'customers_name',
      },
      {
        title: 'Order Date',
        dataIndex: 'order_date',
        render: formatDate,
        sorter: true,
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: formatDate,
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Salesperson',
        dataIndex: 'sales_name',
        sorter: true,
      },
      {
        title: 'Job Description',
        dataIndex: 'job_description',
        sorter: true,
      },
      {
        title: 'Action',
        render: (order) =>
          <span>
            <Link to={'/orders/view/' + order.id}>
            <LinkButton onClick={() => this.props.history.push('/orders/view/' + order.id)}><Icon type="eye"/></LinkButton>
            </Link>
            <Divider type="vertical"/>
            <Link to={'/orders/edit/' + order.id}>
            <LinkButton onClick={() => this.props.history.push('/orders/edit/' + order.id)}><Icon type="edit"/></LinkButton>
            </Link>
          </span>
      },
    ];
  };

  render() {
    const {loading, orders, searchType,total,currentPage} = this.state;

    const title = (
      <span>
        <Select
          style={{width: 250}}
          value={searchType}
          onChange={value => this.setState({searchType: value, searchValue: ''})}
        >
          <Option value="name">Search by Customer Name</Option>
          <Option value="order_date">Search by Order Date</Option>
          <Option value="sales">Search by Sales Person</Option>
        </Select>
        {searchType === 'name' &&
        <Input
          style={{width: 200, margin: '0 10px'}}
          placeholder="Song Ji"
          onChange={(event)=>this.handleSearchChange(event,'name')}
          onPressEnter={this.handlerSearch}
        />}
        {searchType === 'order_date' &&
        <DatePicker
          style={{width: 200, margin: '0 10px'}}
          onChange={(value)=>this.handleSearchChange(value,'date')}
        />
        }
        {searchType === 'sales' &&
        <Input
          style={{width: 200, margin: '0 10px'}}
          placeholder="Song Ji"
          onChange={(event)=>this.handleSearchChange(event,'sales')}
          onPressEnter={this.handlerSearch}
        />}

        <Button type="primary" onClick={this.handlerSearch}>SEARCH</Button>
      </span>
    );

    return (
      <div className="customer">
        <Card title={title}>
          <Table
            rowKey="id"
            loading={loading}
            dataSource={orders}
            columns={this.columns}
            onChange={this.handleTableChange}
            pagination={{
              total,
              defaultPageSize: PAGE_SIZE,
              showQuickJumper: true,
              current: currentPage,
            }}
            size="middle"
            scroll={{ x: true }}
          />
        </Card>
      </div>
    );
  }
}
