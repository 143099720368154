import React, {Component} from 'react';
import {Descriptions, Table} from 'antd';
import {formatDate} from '../../../utils/utility';

const keys = ['true_cost', 'diamond_type', 'shape', 'carats', 'colour', 'clarity',
  'cut', 'measurements', 'certificate_type', 'certificate_number', 'comments'];

export class Preview extends Component {

  constructor(props) {
    super(props);
    //initialize table columns
    this.initColumns();
  }

  initColumns = () => {
    this.columns = keys.reduce((prev, cur) => {
      prev.push({
        title: cur,
        dataIndex: cur,
      },)
      return prev;
    }, [{
      title: 'No.',
      render: (text, record, index) => `${index + 1}`
    },]);
  }

  render() {
    return (
      <>
        <Descriptions bordered size="small" style={{marginBottom:'10px'}}>
          <Descriptions.Item label="Vendor">{this.props.invoices.vendor_name}</Descriptions.Item>
          <Descriptions.Item label="Invoice Number">{this.props.invoices.invoice_number}</Descriptions.Item>
          <Descriptions.Item label="Invoice Date">{formatDate(this.props.invoices.inventory_date)}</Descriptions.Item>
          <Descriptions.Item label="Currency">{this.props.invoices.currency}</Descriptions.Item>
          <Descriptions.Item label="Diamond State">{this.props.invoices.diamond_state}</Descriptions.Item>
          <Descriptions.Item label="Memo Day">{this.props.invoices.memo_day + " Days"}</Descriptions.Item>
        </Descriptions>
        <Table
          className='diamonds-pre-table'
          rowKey={(record, index) => `${index}`}
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={this.props.invoices.diamonds}
          size="small"
          scroll={{x: 'max-content'}}
        />
      </>
    )
  }
}