import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Form, Icon, Input, Button, message} from 'antd';
import {reqLogin} from "../../api";
import logo from '../../asset/logo-white.png'
import './login.less'

class Login extends Component {


  handleSubmit = e => {
    //block submitting
    e.preventDefault();

    //Validate the specified fields and get their values and errors.
    this.props.form.validateFields((err, {username, password}) => {
      if (!err) {
        reqLogin(username, password)
          .then(response => {
            localStorage.setItem('token', response.data.token);
            this.props.history.replace('/');
          })
          .catch(error => {
            if (error.response) {
              let errorMsg = error.response.data.message;
              message.error(`Error ${error.response.status} : ${errorMsg}`);
            }

          });
      } else {
        console.log('validate failed');
      }
    })
  };


  render() {
    // get user, if user exists, redirect to home
    const token = localStorage.getItem('token');
    if (token) {
      return <Redirect to="/"/>
    }

    //Get form and Two-way binding
    const form = this.props.form;
    const {getFieldDecorator} = form;

    return (
      <div className="login">

        <div className="login-header">
          <img src={logo} alt="logo"/>

        </div>
        <div className="login-content">
          <h1>LOGIN</h1>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('username', {
                initialValue: '',
                rules: [{required: true, whitespace: true, message: 'Please input your username!'}],
              })(
                <Input
                  prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                  placeholder="Username"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                initialValue: '',
                rules: [{required: true, whitespace: true,}],
              })(
                <Input
                  prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                  type="password"
                  placeholder="Password"
                />,
              )}

            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
              </Button>
            </Form.Item>
          </Form>

        </div>
      </div>
    );
  }
}

const WrapLogin = Form.create()(Login);
export default WrapLogin;