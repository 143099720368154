import React, {Component} from 'react';
import {Alert, Icon, Table, Card, Modal, message, Button, Divider} from 'antd';
import {reqAddTier, reqDeleteTier, reqGetAllTiers, reqGlobalChangeDiamondPrice} from "../../../../api";
import LinkButton from "../../../../components/LinkButton";
import {formatDate} from '../../../../utils/utility';
import SellTierModal from '../../../../components/Inventory/SellTierModal';

export default class List extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tiers: [],
      newDialogShow: false,
      errorMsgs: []
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    this.ajaxGetAllTiers();
  }

  //ajax: get all tiers
  ajaxGetAllTiers = () => {
    reqGetAllTiers()
      .then(response => {
        const tiers = response.data;
        this.setState({tiers});
        //disable loading
        this.setState({loading: false});
        this.checkTierError(tiers);
      })
      .catch(error => {
        console.log(error)
      });
  }

  //add a tier
  showAdd = () => {
    this.tier = {};
    this.setState({newDialogShow: true});
  };

  //show update Dialog
  showUpdate = (tier) => {
    this.tier = tier;
    this.setState({newDialogShow: true})
  };

  //delete
  onDelete = (item) => {
    Modal.confirm({
      title: `Are you sure you want to delete [ ${item.tier_name} ] ?`,
      onOk: () => {
        reqDeleteTier(item.id)
          .then(response => {
            message.success('Delete Successfully');
            this.ajaxGetAllTiers();
          })
          .catch(error => {
            console.log(error);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  };

  //create and update
  onAddUpdate = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const id = this.tier.id
        let {
          number_1, number_2, multiple_number, plus_number_1, plus_number_2
        } = values;
        let tier = {
          id, number_1, number_2, multiple_number, plus_number_1, plus_number_2
        };

        reqAddTier(tier)
          .then(response => {
            this.setState({newDialogShow: false});
            message.success('Add Successfully!');
            this.ajaxGetAllTiers();
          })
          .catch(error => {
            console.log(error)
          });
        this.form.resetFields();
        this.tier = {};
      }
    })
  };

  //check tier error
  checkTierError = (tiers) => {
    console.log(tiers)
    let length = tiers.length;
    let errorMsgs = [];
    //t0 number_1 must be 0
    if (tiers[0].number_1 !== 0) {
      errorMsgs.push("WARNING: The smallest tier must contain 0!");
    }
    if (tiers[length - 1].number_2 !== 999999) {
      errorMsgs.push("WARNING: The biggest tier must contain 999999!");
    }
    for (let i = 0; i < length - 1; i++) {
      if (tiers[i].number_2 !== tiers[i + 1].number_1) {
        errorMsgs.push(`WARNING: Missing number between ${tiers[i].tier_name} and ${tiers[i + 1].tier_name}`);
      }
      if (tiers[i + 1].number_1 < tiers[i].number_2) {
        errorMsgs.push(`WARNING: Duplicate number between ${tiers[i].tier_name} and ${tiers[i + 1].tier_name}`);
      }
      if (tiers[i].multiple_number < tiers[i + 1].multiple_number) {
        errorMsgs.push(`WARNING:${tiers[i + 1].tier_name}'s multiple_number should less than ${tiers[i].tier_name}'s multiple_number`);
      }
    }
    this.setState({errorMsgs})
  }

  changeDiamondPrice = () => {
    Modal.confirm({
      title: `Are you sure you want to change all the UNSOLD diamonds price based on the new tiers?`,
      onOk: () => {
        reqGlobalChangeDiamondPrice()
          .then(response => {
            message.success('Changed Successfully');
          })
          .catch(error => console.log(error));
      },
      onCancel() {
      },
    })
  };

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Create Date',
        dataIndex: 'created_at',
        render: formatDate
      },
      {
        title: 'Name',
        dataIndex: 'tier_name'
      },
      {
        title: 'Start No.(>=)',
        dataIndex: 'number_1',
        render: (item) => '$' + item
      },
      {
        title: 'End No.(<)',
        dataIndex: 'number_2',
        render: (item) => '$' + item
      },
      {
        title: 'Multiple_number',
        dataIndex: 'multiple_number'
      },
      {
        title: 'Plus_number_1',
        dataIndex: 'plus_number_1'
      },
      {
        title: 'Plus_number_2',
        dataIndex: 'plus_number_2'
      },
      {
        title: 'Action',
        render: (item) =>
          <span>
            <LinkButton onClick={() => this.showUpdate(item)}>Edit</LinkButton>
            <Divider type="vertical"/>
            <LinkButton onClick={() => this.onDelete(item)}>Delete</LinkButton>
          </span>
      },
    ];
  };

  render() {
    const {tiers, loading, newDialogShow, errorMsgs} = this.state;
    const extra =
      <span>
        <Button type='primary' onClick={this.showAdd}><Icon type="plus"/>New Tier</Button>
        <Button type='danger' onClick={this.changeDiamondPrice}>Refresh Diamond Price</Button>
      </span>;

    return (
      <div>
        {errorMsgs.map((item, index) => <Alert key={index} message={item} type="error" showIcon/>)}
        <Card title="Sell Tiers" extra={extra}>
          <Table
            rowKey='id'
            loading={loading}
            dataSource={tiers}
            columns={this.columns}
            pagination={false}
            size="small"
            scroll={{x: true}}
          />
          <Modal
            width={650}
            title={'New Tier'}
            visible={newDialogShow}
            onOk={this.onAddUpdate}
            onCancel={() => {
              this.form.resetFields();
              this.setState({newDialogShow: false})
            }}
          >
            <SellTierModal tier={this.tier} setForm={form => this.form = form}/>
          </Modal>
        </Card>
      </div>
    );
  }

}