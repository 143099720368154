import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Form, Modal, Button, Input, message, Select, Spin} from 'antd';
import {reqGetAddressOrderId, reqAddAddress, reqGetCountries, reqGetStates, reqGetCities} from "../../api";
/* global google */

const {Item} = Form;
const {Option} = Select;

class Address extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showAddDialog: false,
      address: {},
      countries: [],
      states: [],
      cities: [],
      loading: false
    };

    //google autocomplete address
    this.autocomplete = null;
  }

  onChangeCountry = (value) => {
    reqGetStates(value)
      .then(response => {
          this.setState({states: response.data})
        }
      )
      .catch(error => {
        console.log(error.response)
      });
  };

  onChangeState = (value) => {
    reqGetCities(value)
      .then(response => {
          this.setState({cities: response.data})
        }
      )
      .catch(error => {
        console.log(error.response)
      });
  };

  onEdit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const {address_1, address_2, city, province, postal_code, country,} = values;

        if (!(province && country)) {
          message.error("COUNTRY and PROVINCE are required!");
          return;
        }

        this.setState({loading: true});
        const order_id = this.props.order_id;
        const address = {
          address_1, address_2, city, province, postal_code, country, order_id
        };

        reqAddAddress(address)
          .then(response => {
            message.success('Address Edited!');
            const data = response.data;
            this.props.onUpdateData(data);
            this.autocomplete = null;
            this.props.form.resetFields();
            this.refs.autoAddress.value = '';
            this.setState({loading: false, showAddDialog: false});
          })
          .catch(error => {
            console.log(error)
          });
      }
    })
  };

  handleClick = () => {

    this.ajaxGetAddress(this.props.order_id);
    this.setState({showAddDialog: true});

    //after render address dialog
    setTimeout(() => {
      this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {types: ['geocode']});
      this.autocomplete.setFields(['address_component', 'formatted_address']);
      this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }, 2000);


  };

  //autocomplete address
  handlePlaceSelect = () => {
    this.props.form.resetFields();
    let address = {};
    let place = this.autocomplete.getPlace();
    for (let i = 0; i < place.address_components.length; i++) {
      let component = place.address_components[i];
      let addressType = component.types[0];

      switch (addressType) {
        case 'street_number':
          address.address_1 = component.short_name;
          break;
        case 'route':
          address.address_1 += ' ';
          address.address_1 += component.long_name;
          break;
        case 'locality':
          address.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          address.province = component.long_name;
          this.onChangeState(address.province);
          break;
        case 'country':
          address.country = component.long_name;
          this.onChangeCountry(address.country);
          break;
        case 'postal_code':
          address.postal_code = component.short_name;
          break;
        default:
        // code block
      }
      this.setState({address})
    }
  };

  ajaxGetAddress = (id) => {
    reqGetCountries()
      .then(response => {
          this.setState({countries: response.data})
        }
      )
      .catch(error => {
        console.log(error.response)
      });

    reqGetAddressOrderId(id)
      .then(response => {
        const address = response.data;
        this.setState({address});

        //states drop down
        reqGetStates(response.data.country)
          .then(response => {
              this.setState({states: response.data})
            }
          )
          .catch(error => {
            console.log(error.response)
          });

        //city drop down
        reqGetCities(response.data.province)
          .then(response => {
              this.setState({cities: response.data})
            }
          )
          .catch(error => {
            console.log(error.response)
          });
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  render() {
    const {showAddDialog, address, loading, cities, countries, states} = this.state;
    const {getFieldDecorator} = this.props.form;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 11}
    };

    return (
      <React.Fragment>
        <Button type='primary' size='small' onClick={this.handleClick}>
          Edit Address
        </Button>
        <Modal
          width={650}
          title='Edit Address'
          visible={showAddDialog}
          onOk={this.onEdit}
          onCancel={() => {
            this.autocomplete = null;
            this.setState({showAddDialog: false});
            this.props.form.resetFields();
            this.refs.autoAddress.value = '';
          }}
        >
          <Spin spinning={loading}>
            <Form {...formLayout}>

              <div style={{textAlign: 'center', verticalAlign: 'middel', margin: '5px 0px 18px 0px'}}>
                <input
                  style={{width: '450px', border: '1px solid #d9d9d9', padding: '4px'}}
                  placeholder=' Enter Address Here - Autocomplete Address'
                  id="autocomplete" type="text"
                  onChange={this.onChange}
                  ref="autoAddress"
                />
              </div>

              <Item label="Country">
                {
                  getFieldDecorator('country', {
                    initialValue: address.country || '',
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'}
                    ]
                  })(<Select
                    showSearch
                    onChange={this.onChangeCountry}
                  >
                    {countries.map(item => <Option value={item.name} key={item.id}> {item.name} </Option>)}
                  </Select>)
                }
              </Item>

              <Item label="Province">
                {
                  getFieldDecorator('province', {
                    initialValue: address.province || '',
                  })(<Select
                    showSearch
                    onChange={this.onChangeState}
                  >
                    {states.map(item => <Option value={item.name} key={item.id}> {item.name} </Option>)}
                  </Select>)
                }
              </Item>

              <Item label="City">
                {
                  getFieldDecorator('city', {
                    initialValue: address.city || '',
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'}
                    ]
                  })(<Select
                    showSearch
                  >
                    {cities.map(item => <Option value={item.name} key={item.id}> {item.name} </Option>)}
                  </Select>)
                }
              </Item>

              <Item label="Address 1">
                {
                  getFieldDecorator('address_1', {
                    initialValue: address.address_1 || '',
                    rules: [
                      {max: 255, message: 'Maximum 255 characters'}
                    ]
                  })(<Input placeholder='Line 1'/>)
                }
              </Item>
              <Item label="Address 2">
                {
                  getFieldDecorator('address_2', {
                    initialValue: address.address_2 || '',
                    rules: [
                      {max: 255, message: 'Maximum 255 characters'}
                    ]
                  })(<Input placeholder='Line 2'/>)
                }
              </Item>


              <Item label="Postal Code">
                {
                  getFieldDecorator('postal_code', {
                    initialValue: address.postal_code || '',
                    rules: [
                      {max: 10, message: 'Maximum 10 characters'}
                    ]
                  })(<Input placeholder='Postal Code'/>)
                }
              </Item>

            </Form>
          </Spin>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form.create()(withRouter(Address))
