import React, {Component} from 'react';
import {Form, Input, Row, Col, Select, Checkbox} from 'antd';
import {CLARITY, COLOUR, SHAPE} from '../../../utils/const';
import {regexMoney} from '../../../utils/utility';

const {Item} = Form;
const {Option} = Select;

class Search extends Component {

  refreshTable = () => {
    setTimeout(() => {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.props.onSubmit(values);
        }
      });
    }, 0);
  }


  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <Form>
        <Row>
          <Col span={3} offset={1}>
            <Item label="Diamond Type">
              {
                getFieldDecorator('diamond_type', {
                  initialValue: []
                })(
                  <Checkbox.Group onChange={this.refreshTable}>
                    <Row>
                      <Col span={24}>
                        <Checkbox value="LAB-GROWN">LAB-GROWN</Checkbox>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Checkbox value="NATURAL">NATURAL</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                )}
            </Item>
          </Col>
          <Col span={3} offset={1}>
            <Item label="Shape">
              {getFieldDecorator('shape', {
                initialValue: ['Round'],
                rules: [
                  {required: true, message: 'Shape is required'},
                ]
              })(
                <Select mode="multiple" onChange={this.refreshTable}>
                  {
                    SHAPE.map(item => <Option value={item} key={item}> {item} </Option>)
                  }
                </Select>
              )}
            </Item>
          </Col>
          <Col span={1} offset={1}>
            <Item label="Rang">
              <span className="ant-form-text">From</span>
            </Item>
            <Item>
              <span className="ant-form-text">To</span>
            </Item>
          </Col>
          <Col span={2} offset={1}>
            <Item label="Carats">
              {
                getFieldDecorator('carats_min', {
                  initialValue: '0.1',
                  rules: [
                    {required: true, message: ' Required'},
                    {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/, message: 'Number with 4 Decimal Only',}
                  ]
                })(<Input onChange={this.refreshTable}/>)
              }
            </Item>
            <Item>
              {
                getFieldDecorator('carats_max', {
                  initialValue: '9.0',
                  rules: [
                    {required: true, message: 'Required'},
                    {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/, message: 'Number with 4 Decimal Only',}
                  ]
                })(<Input onChange={this.refreshTable}/>)
              }
            </Item>
          </Col>
          <Col span={2} offset={1}>
            <Item label="Color">
              {
                getFieldDecorator('colour_min', {
                  initialValue: "Any"
                })(
                  <Select onChange={this.refreshTable}>
                    <Option value='Any' key='Any'>Any</Option>
                    {
                      COLOUR.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )
              }
            </Item>
            <Item>
              {
                getFieldDecorator('colour_max', {
                  initialValue: "Any"
                })(
                  <Select onChange={this.refreshTable}>
                    <Option value='Any' key='Any'>Any</Option>
                    {
                      COLOUR.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )
              }
            </Item>
          </Col>
          <Col span={2} offset={1}>
            <Item label="Clarity">
              {
                getFieldDecorator('clarity_min', {
                  initialValue: "Any"
                })(
                  <Select onChange={this.refreshTable}>
                    <Option value='Any' key='Any'>Any</Option>
                    {
                      CLARITY.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )
              }
            </Item>
            <Item>
              {
                getFieldDecorator('clarity_max', {
                  initialValue: "Any"
                })(
                  <Select onChange={this.refreshTable}>
                    <Option value='Any' key='Any'>Any</Option>
                    {
                      CLARITY.map(item => <Option value={item} key={item}> {item} </Option>)
                    }
                  </Select>
                )
              }
            </Item>
          </Col>
          <Col span={3} offset={1}>
            <Item label="Price (SELL CAD)">
              {
                getFieldDecorator('price_min', {
                  initialValue: '1',
                  rules: [
                    {required: true, message: 'Required'},
                    {
                      pattern: regexMoney,
                      message: 'Number with 2 Decimal Only',
                    }
                  ]
                })(<Input onChange={this.refreshTable}/>)
              }
            </Item>
            <Item>
              {
                getFieldDecorator('price_max', {
                  initialValue: '9999999',
                  rules: [
                    {required: true, message: 'Required'},
                    {
                      pattern: regexMoney,
                      message: 'Number with 2 Decimal Only',
                    }
                  ]
                })(<Input onChange={this.refreshTable}/>)
              }
            </Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

export default Form.create()(Search)