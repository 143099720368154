import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Col, Row, Select} from 'antd';

const {Option} = Select;

class FormattedSelect extends Component{

  constructor(props) {
    super(props);
    this.action = "";
    this.content = "";
  }

   handleChange =(value,item) =>{
    console.log(`selected ${value}`,item);
    if(item === 'action'){
      this.action = value;
      this.triggerChange();
    }else if(item === 'content'){
      this.content = value;
      this.triggerChange();
    }
  }

  //use onChange to change form
  triggerChange = () => {
    const { onChange } = this.props;
    let changedValue = `${this.props.userName} ${this.action} for ${this.content}`;
    if (onChange) {
      onChange(changedValue);
    }
  };


  render() {
    const {userName} = this.props;
    return(
      <Row>
        <Col span={4} offset={1}>
          <span>{userName}</span>
        </Col>
        <Col span={8}>
          <Select onChange={(e) => this.handleChange(e, "action")}>
            <Option value='emailed'>emailed</Option>
            <Option value='left voicemail'>left voicemail</Option>
            <Option value='texted'>texted</Option>
            <Option value='spoke'>spoke</Option>
          </Select>
        </Col>

        <Col span={2} offset={1}><span>for</span></Col>
        <Col span={8}>
          <Select onChange={(e) => this.handleChange(e, "content")}>
            <Option value='rendering review'>rendering review</Option>
            <Option value='resin review'>resin review</Option>
            <Option value='replica review'>replica review</Option>
            <Option value='pickup'>pickup</Option>
          </Select>
        </Col>
      </Row>
    )
  }
}

FormattedSelect.propTypes = {
  userName: PropTypes.string,
}

export default FormattedSelect