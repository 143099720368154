import React, {Component} from 'react';
import {Form, DatePicker, Input, Col, Row, Card, Button, Icon, Divider, Select, message, Modal, Checkbox} from 'antd';
import {CERTIFICATE_TYPE, CLARITY, COLOUR, CUT, SHAPE} from "../../../utils/const";
import SelectInput from '../../../components/SelectInput';
import LinkButton from '../../../components/LinkButton';
import {momentToString, regexMoney, createEmptyObjByKey} from '../../../utils/utility';
import {reqAddInvoices, reqGetVendorsPlusDelete} from '../../../api';
import {Preview} from '../../../components/Inventory/Add/Preview';

const {Item} = Form;
const {Option} = Select;
const {TextArea} = Input;
const keys = ['true_cost', 'diamond_type', 'shape', 'carats', 'colour', 'clarity', 'cut',
  'measurements', 'stock_number', 'original_certificate', 'certificate_type', 'certificate_number', 'comments'];

class AddDiamond extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      diamondList: this.initDynamicItemsList(),
      showPreview: false,
      invoices: {}
    };
  }

  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'diamond', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  initDynamicItemsList = () => {
    let arrayOfObjects = [];
    let obj = createEmptyObjByKey(keys);
    arrayOfObjects.push(obj);
    return arrayOfObjects;
  }

  collectDynamicItems = () => {
    const {getFieldValue, resetFields} = this.props.form;
    const diamondList = [];
    let diamondNumber = this.state.diamondList.length;

    for (let i = 0; i < diamondNumber; i++) {
      //let true_cost0 = getFieldValue(true_cost0);
      let obj = {};
      for (let j = 0; j < keys.length; j++) {
        obj[keys[j]] = getFieldValue(`${keys[j]}${i}`);
      }
      //！！！！if no this method，when deleted values，getFieldDecorator's initalValue doesn't work
      //resetFields([`true_cost${i}`, `diamond_type${i}`]);
      let newFieldArr = keys.map(item => `${item}${i}`);
      resetFields(newFieldArr);
      diamondList.push(obj);
    }
    return diamondList;
  }

  addDynamicItems = () => {
    let diamondList = this.collectDynamicItems();
    let obj = createEmptyObjByKey(keys);
    diamondList.push(obj);
    this.setState({diamondList});
  }

  delDynamicItems = (index) => {
    let diamondList = this.collectDynamicItems();
    //delete particular index
    diamondList.splice(index, 1);
    this.setState({diamondList});
  }

  handlePreview = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {

        let diamonds = [];
        let diamondNumber = this.state.diamondList.length;
        for (let i = 0; i < diamondNumber; i++) {
          let _r = {};
          keys.forEach((item) => {
            if (values.hasOwnProperty(`${item}${i}`)) {
              _r[item] = values[`${item}${i}`];
            }
          });
          diamonds.push(_r);
        }

        let {vendor_id, invoice_number, inventory_date, currency, diamond_state, memo_day,invoice_link} = values;
        inventory_date = momentToString(inventory_date);

        //get vendor name
        let vendor_name = this.state.vendors.find(item => item.id === vendor_id).name;

        const invoices = {
          vendor_id, vendor_name, invoice_number, inventory_date, currency, diamond_state,
          memo_day, invoice_link, diamonds
        };

        this.setState({invoices, showPreview: true});
      }
    });
  };

  onSubmit = () => {
    reqAddInvoices(this.state.invoices)
      .then(response => {
        this.setState({order: response.data});
        message.success('Updated successfully!');
        this.props.history.push('/inventory/vendor');
      })
      .catch(error => {
        console.log(error)
      });
  }

  handleVendorChange = (value) => {
    let _vendor = this.state.vendors.find((item) => item.id === value);
    let _memo_day;
    if (_vendor.default_state === 'Memo') {
      _memo_day = _vendor.memo_day;
    } else if (_vendor.default_state === 'Cash Memo') {
      _memo_day = _vendor.cash_memo_day;
    } else {
      _memo_day = 0;
    }
    this.props.form.setFieldsValue({
      currency: _vendor.currency,
      diamond_state: _vendor.default_state,
      memo_day: _memo_day
    });
  }


  render() {
    const {vendors} = this.state;
    const {getFieldDecorator} = this.props.form;

    let {diamondList} = this.state;
    let diamondNumber = diamondList.length;
    let diamondComps = diamondList.map((el, index) => {
      return (
        <span key={index}>
          <Row>
              <Col span={8}>
                <Item label="True Cost" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`true_cost${index}`, {
                    initialValue: el.true_cost,
                    rules: [
                      {required: true, message: 'Required'},
                      {
                        pattern: regexMoney,
                        message: 'Number with 2 decimal places Only',
                      }
                    ]
                  })(
                    <Input/>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Type" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`diamond_type${index}`, {
                    initialValue: el.diamond_type,
                    rules: [
                      {required: true, message: 'Required'},
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(
                    <Select>
                      <Option value="LAB-GROWN" key="LAB-GROWN">LAB-GROWN</Option>
                      <Option value="NATURAL" key="NATURAL">NATURAL</Option>
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Shape" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`shape${index}`, {
                    initialValue: el.shape,
                    rules: [
                      {required: true, message: 'Required'},
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(
                    <SelectInput options={SHAPE}/>
                  )}
                </Item>
              </Col>

            </Row>
            <Row>
              <Col span={6}>
                <Item label='Carats' labelCol={{span: 8}} wrapperCol={{span: 13}}>
                  {
                    getFieldDecorator(`carats${index}`, {
                      initialValue: el.carats,
                      rules: [
                        {required: true, message: 'Required'},
                        {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/, message: 'Number with 4 decimal places Only',}
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Colour" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`colour${index}`, {
                    initialValue: el.colour,
                    rules: [
                      {required: true, message: 'Required'},
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(
                    <SelectInput options={COLOUR}/>
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Clarity" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`clarity${index}`, {
                    initialValue: el.clarity,
                    rules: [
                      {required: true, message: 'Required'},
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(
                    <SelectInput options={CLARITY}/>
                  )}
                </Item>
              </Col>
                <Col span={6}>
                <Item label="Cut" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`cut${index}`, {
                    initialValue: el.cut,
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(
                    <SelectInput options={CUT}/>
                  )}
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={7}>
                <Item label='Stock#' labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {
                    getFieldDecorator(`stock_number${index}`, {
                      initialValue: el.stock_number,
                      rules: [
                        {max: 40, message: 'Maximum 40 characters'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
              <Col span={5}>
                <Item label="Original Cert." labelCol={{span: 11}} wrapperCol={{span: 10}}>
                  {getFieldDecorator(`original_certificate${index}`, {
                    initialValue: el.original_certificate,
                  })(
                    <Checkbox/>
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Cert. Type" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {getFieldDecorator(`certificate_type${index}`, {
                    initialValue: el.certificate_type,
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(
                    <SelectInput options={CERTIFICATE_TYPE}/>
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label='Cert. No.' labelCol={{span: 7}} wrapperCol={{span: 13}}>
                  {
                    getFieldDecorator(`certificate_number${index}`, {
                      initialValue: el.certificate_number,
                      rules: [
                        {max: 40, message: 'Maximum 40 characters'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
            </Row>
          <Row>
             <Col span={12}>
                <Item label='Measurements' labelCol={{span: 6}} wrapperCol={{span: 13}}>
                  {
                    getFieldDecorator(`measurements${index}`, {
                      initialValue: el.measurements,
                      rules: [
                        {max: 40, message: 'Maximum 40 characters'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
            <Col span={12}>
              <Item label='Comments' labelCol={{span: 5}} wrapperCol={{span: 16}}>
                    {
                      getFieldDecorator(`comments${index}`, {
                        initialValue: el.comments,
                      })(
                        <TextArea rows={2}/>
                      )
                    }
                  </Item>
            </Col>
          </Row>
          <Row>
            <Col span={4} style={{marginTop: 6}}>
             {index === diamondNumber - 1 &&
             <Button shape="circle" size="small" icon="plus" type="primary" style={{marginRight: 10}}
                     onClick={() => this.addDynamicItems()}/>}
              {((diamondNumber > 1 && index === 0) || index > 0) &&
              <Button shape="circle" size="small" icon="minus" type="default"
                      onClick={() => this.delDynamicItems(index)}/>}
           </Col>
           </Row>
           <Divider/>
        </span>
      )
    });


    return (
      <>
        <Modal
          width={1200}
          visible={this.state.showPreview}
          cancelText='Back To Form'
          okText='Submit'
          onOk={this.onSubmit}
          onCancel={() => this.setState({showPreview: false})}>
          <Preview invoices={this.state.invoices}/>
        </Modal>
        <div style={{padding: '10px', fontSize: '18px'}} className='customer_title'>
        <span>
          <LinkButton onClick={() => this.props.history.goBack()}>
            <Icon type="arrow-left"/>
          </LinkButton>
          Add Diamonds
          </span>
        </div>
        <Card>
          <Form>
            <Row>
              <Col span={8}>
                <Item label="Vendor" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {getFieldDecorator('vendor_id', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select
                      onChange={this.handleVendorChange}
                      showSearch
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                    >
                      {
                        vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                    key={item.id}> {item.name} </Option>)
                      }
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label='Invoice Number' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {
                    getFieldDecorator('invoice_number', {
                      initialValue: '',
                      rules: [
                        {required: true, message: 'Required'},
                        {max: 100, message: 'Maximum 100 characters'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Invoice Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {
                    getFieldDecorator('inventory_date', {
                      initialValue: null,
                      rules: [
                        {required: true, message: 'Required'},
                      ]
                    })(
                      <DatePicker/>
                    )
                  }
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Item label="Currency" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {getFieldDecorator('currency', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select>
                      <Option value="CAD" key="CAD">CAD</Option>
                      <Option value="USD" key="USD">USD</Option>
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="State" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {getFieldDecorator('diamond_state', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select>
                      <Option value="Memo" key="Memo">Memo</Option>
                      <Option value="Cash Memo" key="Cash Memo">Cash Memo</Option>
                      <Option value="Instock" key="Instock">Instock</Option>
                      <Option value="Consignment" key="Consignment">Consignment</Option>
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Memo Day" labelCol={{span: 10}} wrapperCol={{span: 10}}>
                  {
                    getFieldDecorator('memo_day', {
                      initialValue: '',
                      rules: [
                        {
                          pattern: /^\d+$/,
                          message: 'Number Only',
                        },
                        {required: true, message: 'Required'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={23} >
                <Item label="Invoice Link" labelCol={{span: 3}} wrapperCol={{span: 18}}>
                  {
                    getFieldDecorator('invoice_link', {
                      initialValue: '',
                    })(<Input/>)
                  }
                </Item>
              </Col>
            </Row>
            <Divider/>
            {diamondComps}
          </Form>
          <div style={{textAlign: 'center'}}>
            <Button onClick={() => this.handlePreview()} type="primary" htmlType="submit">Preview</Button>
          </div>
        </Card>
      </>
    )
  }
}

export default Form.create()(AddDiamond)