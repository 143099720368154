import React, {Component} from 'react';
import {Form, DatePicker, Input, Select, Col, Checkbox, Row} from 'antd';
import {reqGetVendorsPlusDelete} from "../../../api";
import {REPAIR_JOB_TYPE, STATUS_REPAIR} from "../../../utils/const";
import moment from 'moment';
import _ from 'lodash';
import {regexMoney} from "../../../utils/utility";
import LinkButton from "../../LinkButton";
import SelectInput from '../../SelectInput';

const {Item} = Form;
const {Option} = Select;
const {TextArea} = Input;

class RepairsAddUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      job_type: props.order_item.job_type,
      selectInput: ''
    };
    props.setForm(props.form)
  }

  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'repair', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      item_description, repair_description, start_date, job_type,
      requires_approval_repair, approved_repair, retail, vendor_id, status
    } = this.props.order_item;
    const {vendors} = this.state;

    return (
      <Form>
        <Row>
          <Col span={21}>
            <Item label="Job Type" labelCol={{span: 4}} wrapperCol={{span: 15}}>
              {getFieldDecorator('job_type', {
                initialValue: job_type || '',
                rules: [
                  {required: true, message: 'Job Type is required'},
                  {max: 40, message: 'Maximum 40 characters'}
                ]
              })(
                <SelectInput options={REPAIR_JOB_TYPE}/>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col offset={1} span={11}>
            <Item label="Item Description">
              {
                getFieldDecorator('item_description', {
                  initialValue: item_description || '', //add or update
                  rules: [
                    {required: true, message: 'Item Description is required'},
                  ]
                })(
                  <TextArea rows={5}/>
                )
              }
            </Item>
          </Col>
          <Col offset={1} span={11}>
            <Item label="Repair Description">
              {
                getFieldDecorator('repair_description', {
                  initialValue: repair_description || '', //add or update
                  rules: [
                    {required: true, message: 'Repair Description is required'},
                  ]
                })(
                  <TextArea rows={5}/>
                )
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label='Retail' labelCol={{span: 6}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('retail', {
                  initialValue: retail || '',
                  rules: [
                    {required: true, message: 'retail is required'},
                    {
                      pattern: regexMoney,
                      message: 'Number with 2 decimal places Only',
                    }
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Require Repair/Component" labelCol={{span: 14}} wrapperCol={{span: 8}}>
              {getFieldDecorator('requires_approval_repair', {
                initialValue: requires_approval_repair || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Approve Repair/Component" labelCol={{span: 14}} wrapperCol={{span: 8}}>
              {getFieldDecorator('approved_repair', {
                initialValue: approved_repair || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
        </Row>
        {
          !(_.isEmpty(this.props.order_item)) &&
          (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <Item label="Vendor" labelCol={{span: 6}} wrapperCol={{span: 12}}>
                    {getFieldDecorator('vendor_id', {
                      initialValue: vendor_id || '',
                    })(
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {
                          vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                      key={item.id}> {item.name} </Option>)
                        }
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="Start Date" labelCol={{span: 8}} wrapperCol={{span: 12}}>
                    {
                      getFieldDecorator('start_date', {
                        initialValue: !(start_date) ? null : moment(start_date),
                      })(
                        <DatePicker/>
                      )
                    }
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Item label="Status" labelCol={{span: 6}} wrapperCol={{span: 12}}>
                    {getFieldDecorator('status', {
                      initialValue: status || '',
                    })(
                      <Select>
                        {
                          STATUS_REPAIR.map(item => <Option value={item} key={item}> {item} </Option>)
                        }
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row>
                {!!vendor_id && <LinkButton onClick={() => this.props.deletePO()}>DELETE PO#</LinkButton>}
              </Row>
            </React.Fragment>
          )
        }
      </Form>
    )
  }
}

export default Form.create()(RepairsAddUpdateForm)