import React, {Component} from 'react';
import {Card} from 'antd';
import MemoTable from '../../../components/Inventory/List/MemoTable';
import TitleCurrentAdjustment from '../../../components/Inventory/List/TitleCurrentAdjustment';

export default class InSite extends Component {

  render() {
    return (
      <div className="diamonds">
        <TitleCurrentAdjustment/>
        <Card>
          <MemoTable/>
        </Card>
      </div>
    );
  }
}