import React, {Component} from 'react';
import {Icon, Table, Card, Modal, message, Button, Spin} from 'antd';
import {reqGetAllAdjustments, reqAddAdjustment} from "../../../../api";
import LinkButton from "../../../../components/LinkButton";
import AdjustmentModal from '../../../../components/Inventory/AdjustmentModal';
import {momentToString} from '../../../../utils/utility';
import './index.less'

export default class List extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      adjustments: [],
      newDialogShow: false,
      modalLoading: false
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    this.ajaxGetAllAdjustments();
  }

  //ajax: get all adjustment
  ajaxGetAllAdjustments = () => {
    reqGetAllAdjustments()
      .then(response => {
        const adjustments = response.data;
        this.setState({adjustments});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
      });
  }

  //first time add a adjustment
  firstAdd = () => {
    this.adjustment = {};
    this.setState({newDialogShow: true});
  };

  //show update Dialog
  showUpdate = (adjustment) => {
    this.adjustment = adjustment;
    this.setState({newDialogShow: true})
  };

  //create and update
  onAddUpdate = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({modalLoading: true});
        let {
          adjustment_updated_date, exchange_rate, buffer_adjustment_high, buffer_adjustment_low, buffer_rate_limits,
          cad_cost_adjustment, reduced_limits, reduced_rate
        } = values;
        adjustment_updated_date = momentToString(adjustment_updated_date);
        let adjustment = {
          adjustment_updated_date, exchange_rate, buffer_adjustment_high, buffer_adjustment_low, buffer_rate_limits,
          cad_cost_adjustment, reduced_limits, reduced_rate
        };
        reqAddAdjustment(adjustment)
          .then(response => {
            this.setState({newDialogShow: false, modalLoading: false});
            message.success('Add Successfully!');
            this.ajaxGetAllAdjustments();
          })
          .catch(error => {
            console.log(error)
          });
        this.form.resetFields();
        this.adjustment = {};
      }
    })

  };

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Last Updated Date',
        dataIndex: 'adjustment_updated_date'
      },
      {
        title: 'Exchange Rate',
        dataIndex: 'exchange_rate',
      },
      {
        title: 'Buffer Rate High',
        render: (item) => (
          <span>{item.buffer_rate_high + " (+" + item.buffer_adjustment_high + ")"}</span>
        )
      },
      {
        title: 'Buffer Rate Low',
        render: (item) => (
          <span>{item.buffer_rate_low + " (+" + item.buffer_adjustment_low + ")"}</span>
        )
      },
      {
        title: 'Buffer Rate Limits',
        dataIndex: 'buffer_rate_limits',
        render: (item) => '$' + item
      },
      {
        title: 'CAD Cost Rate',
        render: (item) => (
          <span>{`${item.cad_cost_rate} (${item.buffer_rate_low}-${item.cad_cost_adjustment})`}</span>
        )
      },
      {
        title: 'Reduced Limits',
        dataIndex: 'reduced_limits',
        render: (item) => '$' + item
      },
      {
        title: 'Reduced Rate',
        dataIndex: 'reduced_rate',
      },
      {
        title: 'Action',
        render: (item) =>
          item.deleted_at ? <span>Inactive</span> :
            <span>
            <LinkButton onClick={() => this.showUpdate(item)}>Active</LinkButton>
          </span>
      },
    ];
  };

  render() {
    const {adjustments, loading, newDialogShow, modalLoading} = this.state;
    const extra = adjustments.length === 0 &&
      <Button type='primary' onClick={this.firstAdd}><Icon type="plus"/>New Adjustment</Button>;

    return (
      <div>
        <Card title="Adjustment History" extra={extra}>
          <Table
            rowKey='id'
            loading={loading}
            dataSource={adjustments}
            columns={this.columns}
            pagination={{defaultPageSize: 15, showQuickJumper: true}}
            size="middle"
            scroll={{x: true}}
            rowClassName={(record, index) => !record.deleted_at && "active"}
          />
          <Modal
            width={650}
            title={'New Adjustment'}
            visible={newDialogShow}
            onOk={this.onAddUpdate}
            onCancel={() => {
              this.form.resetFields();
              this.setState({newDialogShow: false})
            }}
          >
            <Spin spinning={modalLoading}>
              <AdjustmentModal adjustment={this.adjustment} setForm={form => this.form = form}/>
            </Spin>
          </Modal>
        </Card>
      </div>
    );
  }

}