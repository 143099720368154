import React, {Component} from 'react';
import {Card, Collapse} from 'antd';
import LocationTable from '../../../components/Inventory/List/LocationTable';
import TitleCurrentAdjustment from '../../../components/Inventory/List/TitleCurrentAdjustment';

const {Panel} = Collapse;

export default class Location extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: ['Instore', 'In Manufacturing', 'Set in Ring'],
    };
  }

  render() {
    const {locations,} = this.state;

    return (
      <div className="diamonds">
        <TitleCurrentAdjustment/>
        <Card>
          <Collapse defaultActiveKey={locations}>
            {
              locations.map(item => <Panel key={item} header={item}>
                <LocationTable locations={item}/></Panel>)
            }
          </Collapse>
        </Card>
      </div>
    );
  }
}