import React, {Component} from 'react';
import Papa from 'papaparse';
import {Button, Card, Col, DatePicker, Divider, Form, Input, message, Row, Select, Table} from 'antd';
import {reqAddInvoices, reqGetVendorsPlusDelete} from '../../../api';
import {momentToString} from '../../../utils/utility';
import {CSVLink} from 'react-csv';

const {Item} = Form;
const {Option} = Select;

class ImportDiamond extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      diamonds: [],
      vendors: [],
    };
    this.initColumns();
    this.csv_template = [["stock_number", "true_cost", "diamond_type", "shape", "carats", "colour", "clarity", "cut",
      "measurements", "certificate_type", "certificate_number", "original_certificate", "comments"]];
  }


  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'diamond', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  //read the data from csv and write them to table
  changeHandler = (event) => {
    this.setState({loading: true});
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        this.setState({diamonds: results.data, loading: false});
      },
    });
  }

  //find memo states and days by vendor
  handleVendorChange = (value) => {
    let _vendor = this.state.vendors.find((item) => item.id === value);
    let _memo_day;
    if (_vendor.default_state === 'Memo') {
      _memo_day = _vendor.memo_day;
    } else if (_vendor.default_state === 'Cash Memo') {
      _memo_day = _vendor.cash_memo_day;
    } else {
      _memo_day = 0;
    }
    this.props.form.setFieldsValue({
      currency: _vendor.currency,
      diamond_state: _vendor.default_state,
      memo_day: _memo_day
    });
  }

  //submit data to backend
  onSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        let msg = this.validateDiamonds(this.state.diamonds);
        if (msg) {
          message.error(msg);
        } else {
          let {vendor_id, invoice_number, inventory_date, currency, diamond_state, memo_day, invoice_link} = values;
          inventory_date = momentToString(inventory_date);

          const diamonds = this.state.diamonds;

          //get vendor name
          let vendor_name = this.state.vendors.find(item => item.id === vendor_id).name;

          const invoices = {
            vendor_id, vendor_name, invoice_number, inventory_date, currency, diamond_state,
            memo_day, invoice_link, diamonds
          };

          reqAddInvoices(invoices)
            .then(response => {
              this.setState({order: response.data});
              message.success('Updated successfully!');
              this.props.history.push('/inventory/vendor');
            })
            .catch(error => {
              console.log(error)
            });
        }
      }
    });
  };

  //File type must be .csv, first row must be EXACT the same as here:
  //true_cost, diamond_type, shape, carats, clarity are required
  //diamond_type can only be: LAB-GROWN or NATURAL
  //original_certificate can only be: YES or NO or empty
  validateDiamonds = (diamonds) => {
    let msg = ""
    for (let diamond of diamonds) {
      if (!diamond.true_cost) {
        msg = "True Cost is required";
        break;
      }
      if (!diamond.diamond_type) {
        msg = "Diamond Type is required";
        break;
      }
      if (!diamond.shape) {
        msg = "Shape is required";
        break;
      }
      if (!diamond.carats) {
        msg = "Carats is required";
        break;
      }
      if (!diamond.colour) {
        msg = "Colour is required";
        break;
      }
      if (!diamond.clarity) {
        msg = "Clarity is required";
        break;
      }
      if (!(diamond.diamond_type === 'LAB-GROWN' || diamond.diamond_type === 'NATURAL')) {
        msg = "Diamond Type can only be: LAB-GROWN or NATURAL";
        break;
      }
      if (!(diamond.original_certificate === 'YES' || diamond.original_certificate === 'NO' || diamond.original_certificate === "")) {
        msg = "Original Cert can only be: YES or NO or empty";
        break;
      }
    }
    return msg
  }

  initColumns = () => {
    this.columns =
      [
        {
          title: 'No.',
          render: (text, record, index) => `${index + 1}`
        },
        {
          title: 'Stock No.',
          dataIndex: 'stock_number'
        },
        {
          title: 'True Cost',
          dataIndex: 'true_cost'
        },
        {
          title: 'Type',
          dataIndex: 'diamond_type',
        },
        {
          title: 'Shape',
          dataIndex: 'shape',
        },
        {
          title: 'Carats',
          dataIndex: 'carats',
        },
        {
          title: 'Colour',
          dataIndex: 'colour',
        },
        {
          title: 'Clarity',
          dataIndex: 'clarity',
        },
        {
          title: 'Cut',
          dataIndex: 'cut',
        },
        {
          title: 'Cert. Type',
          dataIndex: 'certificate_type',
        },
        {
          title: 'Cert. No',
          dataIndex: 'certificate_number',
        },
        {
          title: 'Original Cert.',
          dataIndex: 'original_certificate',
        },
        {
          title: 'Measurements',
          dataIndex: 'measurements'
        },
        {
          title: 'Comments',
          dataIndex: 'comments'
        }

      ]
  }

  render() {
    const {diamonds, vendors, loading} = this.state;
    const {getFieldDecorator} = this.props.form;

    return (
      <>
        <Card>
          <h4>File type must be .csv, download the template
            <CSVLink
              data={this.csv_template}
              filename={"diamonds_csv_template.csv"}
            > here
            </CSVLink></h4>
          <h4>True Cost, Type, Shape, Carats, Colour, Clarity are required</h4>
          <h4>Type can only be: LAB-GROWN or NATURAL</h4>
          <h4>Original Cert can only be: YES or NO or empty</h4>
        </Card>
        <Card>
          <input
            type="file"
            name="file"
            onChange={this.changeHandler}
            accept=".csv"
          />
          <Table
            rowKey={(record, index) => index.toString()}
            loading={loading}
            dataSource={diamonds}
            columns={this.columns}
            pagination={false}
            size="middle"
            scroll={{x: true}}
          />
        </Card>
        <Card>
          <Form>
            <Row>
              <Col span={8}>
                <Item label="Vendor" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {getFieldDecorator('vendor_id', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select
                      onChange={this.handleVendorChange}
                      showSearch
                      filterOption={(inputValue, option) =>
                        option.props.children
                          .toString()
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                    >
                      {
                        vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                    key={item.id}> {item.name} </Option>)
                      }
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label='Invoice Number' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {
                    getFieldDecorator('invoice_number', {
                      initialValue: '',
                      rules: [
                        {required: true, message: 'Required'},
                        {max: 100, message: 'Maximum 100 characters'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Invoice Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {
                    getFieldDecorator('inventory_date', {
                      initialValue: null,
                      rules: [
                        {required: true, message: 'Required'},
                      ]
                    })(
                      <DatePicker/>
                    )
                  }
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Item label="Currency" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {getFieldDecorator('currency', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select>
                      <Option value="CAD" key="CAD">CAD</Option>
                      <Option value="USD" key="USD">USD</Option>
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="State" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                  {getFieldDecorator('diamond_state', {
                    initialValue: '',
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <Select>
                      <Option value="Memo" key="Memo">Memo</Option>
                      <Option value="Cash Memo" key="Cash Memo">Cash Memo</Option>
                      <Option value="Instock" key="Instock">Instock</Option>
                      <Option value="Consignment" key="Consignment">Consignment</Option>
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Memo Day" labelCol={{span: 10}} wrapperCol={{span: 10}}>
                  {
                    getFieldDecorator('memo_day', {
                      initialValue: '',
                      rules: [
                        {
                          pattern: /^\d+$/,
                          message: 'Number Only',
                        },
                        {required: true, message: 'Required'},
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
            </Row>
            <Row>
              <Col span={23}>
                <Item label="Invoice Link" labelCol={{span: 3}} wrapperCol={{span: 18}}>
                  {
                    getFieldDecorator('invoice_link', {
                      initialValue: '',
                    })(<Input/>)
                  }
                </Item>
              </Col>
            </Row>
            <Divider/>
          </Form>
          <div style={{textAlign: 'center'}}>
            <Button onClick={() => this.onSubmit()} type="primary" htmlType="submit">Submit</Button>
          </div>
        </Card>
      </>
    )
  }
}

export default Form.create()(ImportDiamond)