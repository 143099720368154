import React, {Component} from 'react';
import {reqGetEditDiamondDetail, reqGetVendorsPlusDelete, reqUpdateEditDiamondDetail} from '../../api';
import {Divider, Col, DatePicker, Form, Icon, Input, Row, Select, Spin, Checkbox, Button, message} from 'antd';
import LinkButton from '../../components/LinkButton';
import moment from 'moment';
import SelectInput from '../../components/SelectInput';
import {CERTIFICATE_TYPE, CLARITY, COLOUR, CUT, SHAPE} from '../../utils/const';
import {momentToString, regexMoney} from '../../utils/utility';
import {Link} from 'react-router-dom';

const {Item} = Form;
const {Option} = Select;
const {TextArea} = Input;

class Edit extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;

    this.state = {
      loading: true,
      diamond: {},
      vendors: [],
    };
  }

  componentDidMount() {
    reqGetEditDiamondDetail(this.id)
      .then(response => {
        const diamond = response.data;
        this.setState({diamond});
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
        const status = error.response.status;
        const data = error.response.data;
        if (status === 302) {
          if (data.message) {
            message.error(data.message)
            this.props.history.replace('/inventory/view/' + this.id);
          }
        } else if (status === 404) {
          if (data.message) {
            message.error(data.message)
            this.props.history.replace('/inventory/insite');
          }
        } else {
          this.props.history.replace('/inventory/insite');
        }


      });
    reqGetVendorsPlusDelete({searchName: 'diamond', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  onSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        values.inventory_date = momentToString(values.inventory_date);
        console.log(values);
        reqUpdateEditDiamondDetail(this.id, values)
          .then(response => {
            message.success('Updated successfully!');
            this.props.history.replace('/inventory/view/' + this.id);
          })
          .catch(error => console.log(error.response));
      }
    });
  };

  render() {
    const {loading, diamond, vendors} = this.state;
    const {getFieldDecorator, getFieldValue} = this.props.form;

    return (
      <>
        <Spin spinning={loading} size="large">
          <div style={{padding: '10px', fontSize: '18px'}}>
          <span>
          <LinkButton onClick={() => this.props.history.goBack()}>
            <Icon type="arrow-left"/>
          </LinkButton>
          </span>
            <div style={{float: 'right', fontSize: '18px'}}>
              <Link to={'/inventory/view/' + this.id}>
                <Button style={{margin: '10px 10px'}}
                        onClick={() => this.props.history.push('/inventory/view/' + this.id)}>
                  View</Button>
              </Link>
            </div>
          </div>
        </Spin>
        <Form>
          <Divider orientation="left" plain>
            Vendor Info
          </Divider>

          <Row>
            <Col span={8}>
              <Item label="Vendor" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('vendor_id', {
                  initialValue: diamond.vendor_id,
                  rules: [
                    {required: true, message: 'Required'},
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                  >
                    {
                      vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                  key={item.id}> {item.name} </Option>)
                    }
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label='Invoice Number' labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('invoice_number', {
                    initialValue: diamond.invoice_number,
                    rules: [
                      {max: 100, message: 'Maximum 100 characters'},
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Invoice Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('inventory_date', {
                    initialValue: !diamond.inventory_date ? null : moment(diamond.inventory_date),
                    rules: [
                      {required: true, message: 'Required'},
                    ]
                  })(
                    <DatePicker/>
                  )
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={23} >
              <Item label="Invoice Link" labelCol={{span: 3}} wrapperCol={{span: 18}}>
                {
                  getFieldDecorator('invoice_link', {
                    initialValue: diamond.invoice_link,
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            Diamond Info
          </Divider>

          <Row>
            <Col span={8}>
              <Item label="Type" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`diamond_type`, {
                  initialValue: diamond.diamond_type,
                  rules: [
                    {required: true, message: 'Required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <Select>
                    <Option value="LAB-GROWN" key="LAB-GROWN">LAB-GROWN</Option>
                    <Option value="NATURAL" key="NATURAL">NATURAL</Option>
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Shape" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`shape`, {
                  initialValue: diamond.shape,
                  rules: [
                    {required: true, message: 'Required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={SHAPE}/>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label='Carats' labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {
                  getFieldDecorator(`carats`, {
                    initialValue: diamond.carats,
                    rules: [
                      {required: true, message: 'Required'},
                      {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,4})?$/, message: 'Number with 4 decimal places Only',}
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Item label="Colour" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`colour`, {
                  initialValue: diamond.colour,
                  rules: [
                    {required: true, message: 'Required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={COLOUR}/>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Clarity" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`clarity`, {
                  initialValue: diamond.clarity,
                  rules: [
                    {required: true, message: 'Required'},
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={CLARITY}/>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Cut" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`cut`, {
                  initialValue: diamond.cut,
                  rules: [
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={CUT}/>
                )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Item label='Stock#' labelCol={{span: 8}} wrapperCol={{span: 13}}>
                {
                  getFieldDecorator(`stock_number`, {
                    initialValue: diamond.stock_number,
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
            <Col span={4}>
              <Item label="Original Cert." labelCol={{span: 12}} wrapperCol={{span: 12}}>
                {getFieldDecorator(`original_certificate`, {
                  initialValue: diamond.original_certificate || false,
                  valuePropName: 'checked',
                })(
                  <Checkbox/>
                )}
              </Item>
            </Col>
            <Col span={6}>
              <Item label="Cert. Type" labelCol={{span: 8}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`certificate_type`, {
                  initialValue: diamond.certificate_type,
                  rules: [
                    {max: 40, message: 'Maximum 40 characters'},
                  ]
                })(
                  <SelectInput options={CERTIFICATE_TYPE}/>
                )}
              </Item>
            </Col>
            <Col span={6}>
              <Item label='Cert. No.' labelCol={{span: 7}} wrapperCol={{span: 14}}>
                {
                  getFieldDecorator(`certificate_number`, {
                    initialValue: diamond.certificate_number,
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Item label="Reported" labelCol={{span: 11}} wrapperCol={{span: 7}}>
                {getFieldDecorator(`reported`, {
                  initialValue: diamond.reported || false,
                  valuePropName: 'checked',
                })(
                  <Checkbox/>
                )}
              </Item>
            </Col>
            <Col span={9}>
              <Item label='Measurements' labelCol={{span: 7}} wrapperCol={{span: 15}}>
                {
                  getFieldDecorator(`measurements`, {
                    initialValue: diamond.measurements,
                    rules: [
                      {max: 40, message: 'Maximum 40 characters'},
                    ]
                  })(<Input/>)
                }
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Item label="Stone Sold Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {
                  getFieldDecorator('stone_sold_date', {
                    initialValue: !(diamond.stone_sold_date) ? null : moment(diamond.stone_sold_date),
                  })(
                    <DatePicker/>
                  )
                }
              </Item>
            </Col>
            <Col span={9}>
              <Item label='Comments' labelCol={{span: 5}} wrapperCol={{span: 16}}>
                {
                  getFieldDecorator(`comments`, {
                    initialValue: diamond.comments,
                  })(
                    <TextArea rows={2}/>
                  )
                }
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            Diamond State Info
          </Divider>
          <Row>
            <Col span={8}>
              <Item label="State" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('diamond_state', {
                  initialValue: diamond.diamond_state,
                  rules: [
                    {required: true, message: 'Required'},
                  ]
                })(
                  <Select>
                    <Option value="Memo" key="Memo">Memo</Option>
                    <Option value="Cash Memo" key="Cash Memo">Cash Memo</Option>
                    <Option value="Instock" key="Instock">Instock</Option>
                    <Option value="Consignment" key="Consignment">Consignment</Option>
                    <Option value="Return" key="Return">Return</Option>
                  </Select>
                )}
              </Item>
            </Col>
            {
              (getFieldValue('diamond_state') !== 'Memo' && getFieldValue('diamond_state') !== 'Cash Memo') ? "" :
                <Col span={8}>
                  <Item label="Memo Day" labelCol={{span: 10}} wrapperCol={{span: 10}}>
                    {
                      getFieldDecorator('memo_day', {
                        initialValue: diamond.memo_day,
                        rules: [
                          {
                            pattern: /^\d+$/,
                            message: 'Number Only',
                          },
                          {required: true, message: 'Required'},
                        ]
                      })(<Input/>)
                    }
                  </Item>
                </Col>
            }
          </Row>
          <Divider orientation="left" plain>
            Diamond Location Info
          </Divider>
          <Row>
            <Col span={8}>
              <Item label="Location" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('diamond_location', {
                  initialValue: diamond.diamond_location,
                  rules: [
                    {required: true, message: 'Required'},
                  ]
                })(
                  <Select>
                    <Option value="Instore" key="Instore">Instore</Option>
                    <Option value="Set in Ring" key="Set in Ring">Set in Ring</Option>
                    <Option value="In Manufacturing" key="In Manufacturing">In Manufacturing</Option>
                    <Option value="Picked Up" key="Picked Up">Picked Up</Option>
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Divider orientation="left" plain>
            Diamond Price Info
          </Divider>
          <Row>
            <Col span={8}>
              <Item label="Currency" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                {getFieldDecorator('currency', {
                  initialValue: diamond.currency,
                  rules: [
                    {required: true, message: 'Required'},
                  ]
                })(
                  <Select>
                    <Option value="CAD" key="CAD">CAD</Option>
                    <Option value="USD" key="USD">USD</Option>
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="True Cost" labelCol={{span: 7}} wrapperCol={{span: 13}}>
                {getFieldDecorator(`true_cost`, {
                  initialValue: diamond.currency === 'USD' ? diamond.true_cost_usd : diamond.true_cost_cad,
                  rules: [
                    {required: true, message: 'Required'},
                    {
                      pattern: regexMoney,
                      message: 'Number with 2 decimal places Only',
                    }
                  ]
                })(
                  <Input/>
                )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Item label="Has Market Cost" labelCol={{offset: 4, span: 8}} wrapperCol={{span: 5}}>
                {getFieldDecorator('has_market_cost', {
                  initialValue: diamond.has_market_cost || false,
                  valuePropName: 'checked',
                })(
                  <Checkbox></Checkbox>
                )}
              </Item>
            </Col>
            {
              // eslint-disable-next-line
              !!getFieldValue('has_market_cost') &&
              <Col span={11}>
                <Item label="Market Cost USD" labelCol={{span: 6}} wrapperCol={{span: 10}}>
                  {
                    getFieldDecorator('market_cost_usd', {
                      initialValue: diamond.market_cost_usd,
                      rules: [
                        {required: true, message: 'Required'},
                        {
                          pattern: regexMoney,
                          message: 'Number with 2 decimal places Only',
                        }
                      ]
                    })(<Input/>)
                  }
                </Item>
              </Col>
            }
          </Row>
          <div style={{textAlign: 'center', paddingBottom: '13px'}}>
            <Button onClick={() => this.onSubmit()} type="primary" htmlType="submit">Save Changes</Button>
          </div>
        </Form>
      </>
    )
  }

}

export default Form.create()(Edit);
