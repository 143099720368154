import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Row, Col, DatePicker} from 'antd';
import {regexAdjustment} from '../../utils/utility';
import moment from 'moment';

const {Item} = Form;

class AdjustmentModal extends Component {

  constructor(props) {
    super(props);
    this.props.setForm(this.props.form)
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      exchange_rate, buffer_adjustment_high, buffer_adjustment_low, buffer_rate_limits,
      cad_cost_adjustment, reduced_limits, reduced_rate
    } = this.props.adjustment;

    return (
      <Form >
        <Row>
          <Col span={17}>
            <Item label="Update Date" labelCol={{span: 6}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('adjustment_updated_date', {
                  initialValue: moment(),
                  rules: [
                    {required: true, message: 'Date is required'},
                  ]
                })(
                  <DatePicker/>
                )
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Exchange Rate">
              {
                getFieldDecorator('exchange_rate', {
                  initialValue: exchange_rate || '',
                  rules: [
                    {required: true, message: 'Exchange Rate is required'},
                    {
                      pattern: regexAdjustment,
                      message: 'Number with 3 decimal places Only',
                    }
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Buffer Rate Limits">
              {
                getFieldDecorator('buffer_rate_limits', {
                  initialValue: buffer_rate_limits || '',
                  rules: [
                    {required: true, message: 'Buffer Rate Limits is required'},
                    {
                      pattern: /^[0-9]*[1-9][0-9]*$/,
                      message: 'Number Only',
                    }
                  ]
                })(<Input addonBefore="$"/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Buffer Adjustment High">
              {
                getFieldDecorator('buffer_adjustment_high', {
                  initialValue: buffer_adjustment_high || '',
                  rules: [
                    {required: true, message: 'Buffer Adjustment High is required'},
                    {
                      pattern: regexAdjustment,
                      message: 'Number with 3 decimal places Only',
                    }
                  ]
                })(<Input addonBefore="Exchange Rate +"/>)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Buffer Adjustment Low">
              {
                getFieldDecorator('buffer_adjustment_low', {
                  initialValue: buffer_adjustment_low || '',
                  rules: [
                    {required: true, message: 'Buffer Adjustment Low is required'},
                    {
                      pattern: regexAdjustment,
                      message: 'Number with 3 decimal places Only',
                    }
                  ]
                })(<Input addonBefore="Exchange Rate +"/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Item label="Reduced Limits">
              {
                getFieldDecorator('reduced_limits', {
                  initialValue: reduced_limits || '',
                  rules: [
                    {required: true, message: 'Reduced Limits is required'},
                    {
                      pattern: /^[0-9]*[1-9][0-9]*$/,
                      message: 'Number Only',
                    }
                  ]
                })(<Input addonBefore="$"/>)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Reduced Rate">
              {
                getFieldDecorator('reduced_rate', {
                  initialValue: reduced_rate || '',
                  rules: [
                    {required: true, message: 'Reduced Rate is required'},
                    {
                      pattern: regexAdjustment,
                      message: 'Number with 3 decimal places Only',
                    }
                  ]
                })(<Input addonBefore="Sell x"/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={17}>
            <Item label="CAD Cost Adjustment" labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('cad_cost_adjustment', {
                  initialValue: cad_cost_adjustment || '',
                  rules: [
                    {required: true, message: 'CAD Cost Adjustment is required'},
                    {
                      pattern: regexAdjustment,
                      message: 'Number with 3 decimals Only',
                    }
                  ]
                })(<Input addonBefore="Buffer Rate Low - "/>)
              }
            </Item>
          </Col>
        </Row>

      </Form>
    )
  }
}

AdjustmentModal.propTypes = {
  setForm: PropTypes.func.isRequired,
  adjustment: PropTypes.object,
};

export default Form.create()(AdjustmentModal)