import React from 'react'
import ReactDOM from 'react-dom'
import App from "./routes/App";
import {message} from 'antd';

message.config({
  duration: 5,
  maxCount: 3,
});


ReactDOM.render(<App /> , document.getElementById('root'));
