import React, {Component} from 'react';
import logo from '../../asset/logo-black.png';
import moment from 'moment';
import {formatDate} from '../../utils/utility';

class Print extends Component {

  render() {
    const amount = {
      textAlign: 'right',
      paddingRight: '20px',
      borderLeft: '1px solid #cdcece',
      borderRight: '1px solid #cdcece'
    }

    const description = {
      textAlign: 'left',
      borderLeft: '1px solid #cdcece',
      borderRight: '1px solid #cdcece',
      paddingLeft: '10px',
    }

    const title = {
      textAlign: 'left',
      paddingLeft: '10px',
    }

    const {items_description, invoice_address, payments, order, full_name} = this.props.data;
    return (
      <iframe id="if_print" title='engage_print' style={{height: '0px', width: '0px', position: 'absolute'}}>
        <div id="content">
          <div id="main">
            <table summary="Client Invoice." id="printTable" cellPadding="0" cellSpacing="0"
                   style={{lineHeight: '1.7em', fontSize: 'small'}}>
              <thead>
              <tr>
                <th colSpan="2" style={{borderBottom: '1px solid #000000', padding: '30px 0px 30px 10px'}}>
                  <div>
                    <div id="printLogo" style={{float: 'left'}}>
                      <img src={logo} alt="Engage Diamond Studio" style={{height: '60px'}}/>
                    </div>
                    <div id="printAddress">
                      565 Bloor St E<br/>
                      Toronto, ON Canada M4W 1J1<br/>
                      Tel: (416) 491-6060
                    </div>
                  </div>
                </th>
                <th style={{borderBottom: '1px solid #000000', padding: '10px'}}>
                  <h2>STATEMENT</h2>
                </th>
              </tr>
              <tr>
                <th style={{...title}}><strong>Issued To:</strong></th>
                <th style={{textAlign: 'right'}}><strong>Invoice #:</strong></th>
                <th><span>{order.order_number}</span></th>
              </tr>
              <tr>
                <th style={{...title}}>
                  <address>
                    <strong>{full_name}</strong>
                    <br/>
                    {invoice_address.address_line1}
                    <br/>
                    {invoice_address.address_line2}
                    <br/>
                  </address>
                </th>
                <th style={{textAlign: 'right', verticalAlign: 'top'}}><strong>Date:</strong></th>
                <th style={{verticalAlign: 'top'}}><span>{moment().format('YYYY-MM-DD')}</span>
                </th>
              </tr>
              <tr>
                <th style={{...title}} colSpan="3"><strong>GST/HST No. 855548160</strong></th>
              </tr>

              <tr>
                <th colSpan="2" style={{border: '1px solid #cdcece'}}>Description</th>
                <th style={{border: '1px solid #cdcece'}}>Amount</th>
              </tr>
              </thead>
              <tfoot>
              <tr>
                <td colSpan="3" style={{borderTop: '1px solid #cdcece', height: '0px'}}>&nbsp;</td>
              </tr>
              </tfoot>

              <tbody className="items">
              <tr>
                <td colSpan="2" style={{...description}}>
                  <strong>Components</strong>
                </td>
                <td style={{...amount}}>&nbsp;</td>
              </tr>
              {
                items_description.map(item => {
                  return (
                    <tr key={item.id}>
                      <td colSpan="2" style={{...description}}>
                        <span>{item.type} -- {item.desc}</span>
                      </td>
                      <td style={{...amount}}>${item.retail}</td>
                    </tr>
                  )
                })
              }

              <tr>
                <td colSpan="2" style={{...amount}}>Subtotal</td>
                <td style={{...amount}}>${order.total}</td>
              </tr>
              { // eslint-disable-next-line
                order.discount != 0 &&
                <>
                  <tr>
                    <td colSpan="2" style={{...amount}}>{(order.discount_percent * 100).toFixed(2)}% full payment via {order.discount_type} discount</td>
                    <td style={{...amount}}>${order.discount}</td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{...amount}}>Retail</td>
                    <td style={{...amount}}>${order.retail}</td>
                  </tr>
                </>
              }

              <tr>
                <td colSpan="2" style={{...amount}}></td>
                <td style={{...amount}}></td>
              </tr>
              <tr>
                <td colSpan="2" style={{...amount}}>
                  {// eslint-disable-next-line
                    (order.gst * 1 + order.pst * 1 + order.hst * 1) != 0 && `Taxes`}&nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td style={{...amount}}>&nbsp;</td>
              </tr>
              { // eslint-disable-next-line
                order.gst != 0 &&
                <tr>
                  <td colSpan="2" style={{...amount}}>GST</td>
                  <td style={{...amount}}>
                    ${order.gst}
                  </td>
                </tr>
              }
              { // eslint-disable-next-line
                order.pst != 0 &&
                <tr>
                  <td colSpan="2" style={{...amount}}>PST</td>
                  <td style={{...amount}}>
                    ${order.pst}
                  </td>
                </tr>
              }
              { // eslint-disable-next-line
                order.hst != 0 &&
                <tr>
                  <td colSpan="2" style={{...amount}}>HST</td>
                  <td style={{...amount}}>
                    ${order.hst}
                  </td>
                </tr>
              }
              <tr>
                <td colSpan="2" style={{...amount}}><strong>Total</strong></td>
                <td style={{...amount}}>
                  ${order.retail_with_taxes}
                </td>
              </tr>
              <tr>
                <td colSpan="2" style={{...amount, borderTop: '1px solid #cdcece'}}>
                  <strong>Payments</strong>
                </td>
                <td style={{...amount, borderTop: '1px solid #cdcece'}}>&nbsp;</td>
              </tr>
              {
                payments.map(item => {
                  return (
                    <tr key={item.id}>
                      <td colSpan="2" style={{...amount}}>
                        {formatDate(item.payment_time)} - {item.type}
                      </td>
                      <td style={{...amount}}>
                        ${item.amount}
                      </td>
                    </tr>
                  )
                })
              }
              <tr>
                <td colSpan="2" style={{...description}}>
                  <strong>Comments and Special Instructions: </strong>{order.comments}
                </td>
                <td style={{...amount}}>

                </td>
              </tr>
              <tr>
                <td rowSpan="2" style={{...description, borderTop: '1px solid #cdcece'}}>
                  The diamonds herein invoiced have been purchased from legitimate sources not involved in the
                  funding conflict and in compliance with UNITED NATIONS resolutions. The seller hereby
                  guarantees that these diamonds are conflict free based on the personal knowledge and/or
                  written guarantees provided by the supplier of these diamonds.
                </td>
                <td style={{...amount, borderTop: '1px solid #cdcece', borderBottom: '1px solid #cdcece'}}><strong>Amount
                  Paid</strong></td>
                <td style={{
                  ...amount,
                  borderTop: '1px solid #cdcece',
                  borderBottom: '1px solid #cdcece'
                }}>${order.amount_paid}</td>
              </tr>
              <tr>
                <td style={{...amount, borderTop: '1px solid #cdcece', paddingLeft: '10px'}}><strong>Balance
                  Due</strong></td>
                <td style={{...amount, borderTop: '1px solid #cdcece', paddingLeft: '10px'}}>${order.outstanding_balance}</td>
              </tr>
              </tbody>

            </table>
          </div>
        </div>
      </iframe>
    )
  }
}

export default Print;