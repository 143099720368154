import React, {Component} from 'react';
import {Button, Card, Col, Radio, Form, Input, Row, Spin} from 'antd';
import {reqGetSellPrice} from "../../api";
import {regexMoney} from "../../utils/utility";

const {Item} = Form;

class SellCalculator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sell_prices: {
        sell_cad: '',
        sell_usd: '',
        reduced_cad: '',
        reduced_usd: ''
      },
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({loading: true});
        reqGetSellPrice(values)
          .then(response => {
            console.log(response.data)
            this.setState({loading: false, sell_prices: response.data});
          })
          .catch(error => {
            console.log(error)
          });
      }
    });
  };

  render() {
    const { loading, sell_prices} = this.state;
    const {getFieldDecorator} = this.props.form;

    return (
      <Card title='Sell Calculator'>
        <Form onSubmit={this.handleSubmit}>
          <Item label="Buy Currency" labelCol={{span: 4}} wrapperCol={{span: 10}}>
            {getFieldDecorator('buy_currency', {
              initialValue: 'USD',
              rules: [
                {required: true, message: 'Required'},
              ]
            })(
              <Radio.Group>
                <Radio value='CAD'>CAD</Radio>
                <Radio value='USD'>USD</Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label="Shipping Buffer" labelCol={{span: 4}} wrapperCol={{span: 4}}>
            {getFieldDecorator('shipping_buffer', {
              initialValue: 0,
              rules: [
                {required: true, message: 'Required'},
                {
                  pattern: regexMoney,
                  message: 'Number with 2 decimal places Only',
                }
              ]
            })(
              <Input/>
            )}
          </Item>
          <Item label="Buy Price" labelCol={{span: 4}} wrapperCol={{span: 4}}>
            {
              getFieldDecorator('buy_price', {
                initialValue: '',
                rules: [
                  {required: true, message: 'Required'},
                  {
                    pattern: regexMoney,
                    message: 'Number with 2 Decimal Only',
                  }
                ]
              })(<Input/>)
            }
          </Item>
          <Row>
            <Col span={12} offset={3}>
              <Item>
                <Button type="primary" htmlType="submit">Submit</Button>
              </Item>
            </Col>
          </Row>
        </Form>
        <Spin spinning={loading}>
          <h1>Sell Price: CAD{sell_prices.sell_cad}/USD{sell_prices.sell_usd} Discount: CAD{sell_prices.reduced_cad}/USD{sell_prices.reduced_usd}</h1>
        </Spin>
      </Card>
    );
  }

}

export default Form.create()(SellCalculator)