import React, {Component} from 'react';
import {
  Card, Form, Input, Radio, DatePicker, Select,
  Col, Row, Icon, Button, message, AutoComplete, Spin, Modal
} from 'antd';
import {
  reqAddCustomer, reqDeleteCustomer, reqGetAddressOrderId, reqGetCities, reqGetCountries, reqGetCustomerById,
  reqGetCustomersName, reqGetStates, reqUpdateCustomer
} from "../../api";
import LinkButton from "../../components/LinkButton";
import moment from 'moment';
import './customer.less'
import {momentToString} from "../../utils/utility";
import OrderTable from "../../components/Customer/OrderTable";
import {REFERRALS} from "../../utils/const";
import OrderAddForm from "../../components/Customer/OrderAddForm";
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {getUserType, hasRole} from '../../utils/auth';
/* global google */

const Item = Form.Item;
const {Option} = Select;
const {Group} = Radio;

class ViewAddUpdate extends Component {

  constructor(props) {
    super(props);
    this.user_type = getUserType();

    this.state = {
      loading: true,
      customer: {
        first_name: '',
        last_name: '',
        block_call_display: 0,
      },
      partner: {},
      address: {},
      referral: {},
      customerRef: [],//customer name list for Referral Source

      customer_birthday: '',
      partner_birthday: '',
      approx_engagement: '',
      engagement_confirmed: '',
      wedding: '',

      countries: [],
      states: [],
      cities: [],
    };

    //view(0) or add(1) or update(2)
    const path = this.props.location.pathname;
    if (path.indexOf('/customers/edit') === 0) { //update(2)
      this.viewAddUpdate = 2;
      this.id = this.props.match.params.id;
      this.disableForm = false;
    } else if (path === "/customers/new") {  //add(1)
      this.viewAddUpdate = 1;
      this.disableForm = false;
      this.id = null;
    } else {                             //view(0)
      this.viewAddUpdate = 0;
      this.id = this.props.match.params.id;
      this.disableForm = true;
    }

    //google autocomplete address
    this.autocomplete = null;
  }

  componentDidMount() {
    //if it is not add new customer(1), get customer info by id
    if (this.viewAddUpdate !== 1) {
      this.ajaxGetCustomerById(this.id);
    } else {
      this.setState({loading: false});
    }

    //if it is not view customer, load country dropdown list
    if (this.viewAddUpdate !== 0) {
      reqGetCountries()
        .then(response => {
            this.setState({countries: response.data})
          }
        )
        .catch(error => {
          console.log(error.response)
        });
    }

    //google autocomplete address
    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {types: ['geocode']});
    this.autocomplete.setFields(['address_component', 'formatted_address']);
    this.autocomplete.addListener("place_changed", _.debounce(this.handlePlaceSelect, 300));
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //view(0) or add(1) or update(2)
      const path = this.props.location.pathname;
      if (path.indexOf('/customers/edit') === 0) { //update(2)
        this.props.form.resetFields();
        this.viewAddUpdate = 2;
        this.id = this.props.match.params.id;
        this.disableForm = false;
        this.ajaxGetCustomerById(this.id);
      } else if (path === "/customers/new") {  //add(1)
        this.viewAddUpdate = 1;
        this.disableForm = false;
        this.id = null;
      } else {                             //view(0)
        this.viewAddUpdate = 0;
        this.id = this.props.match.params.id;
        this.disableForm = true;
        this.ajaxGetCustomerById(this.id);
      }
    }
  }

  deleteCustomer = (id) => {
    Modal.confirm({
      title: `Are you sure you want to delete this customer?`,
      onOk: () => {
        reqDeleteCustomer(id)
          .then(response => {
              this.props.history.push('/customers/list');
            }
          )
          .catch(error => {
            //console.log(error.response);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const {address_1, address_2, city, province, postal_code, country} = values;

        //if one of address_1,address_2,city,postal_code are not empty
        if (!!(address_1 || address_2 || city || postal_code)) {
          //country or province is empty
          if (!(province && country)) {
            message.error("COUNTRY and PROVINCE are required!");
            return;
          }
        }

        const {first_name, last_name, email, phone_primary, phone_secondary, instagram, block_call_display, referral_source, referral_other_description} = values;
        const {partner_first_name, partner_last_name, partner_email, partner_phone_primary, partner_phone_secondary, partner_instagram} = values;

        let {customer_birthday, partner_birthday, approx_engagement, engagement_confirmed, wedding} = values;
        customer_birthday = momentToString(customer_birthday);
        partner_birthday = momentToString(partner_birthday);
        approx_engagement = momentToString(approx_engagement);
        engagement_confirmed = momentToString(engagement_confirmed);
        wedding = momentToString(wedding);

        let customer = {
          first_name, last_name, email, phone_primary, phone_secondary, instagram, block_call_display,
          referral_source, referral_other_description, partner_first_name, partner_last_name,
          partner_email, partner_phone_primary, partner_phone_secondary, partner_instagram,
          address_1, address_2, city, province, postal_code, country,
          customer_birthday, partner_birthday, approx_engagement, engagement_confirmed, wedding
        };

        //update(2)
        if (this.viewAddUpdate === 2) {
          customer.customer_birthday_id = this.customer_birthday_id;
          customer.partner_birthday_id = this.partner_birthday_id;
          customer.approx_engagement_id = this.approx_engagement_id;
          customer.engagement_confirmed_id = this.engagement_confirmed_id;
          customer.wedding_id = this.wedding_id;

          reqUpdateCustomer(this.id, customer)
            .then(response => {
              message.success('Updated successfully!');
              window.scrollTo({top: 0, behavior: 'auto'});
            })
            .catch(error => {
              console.log(error)
            });
        } else if (this.viewAddUpdate === 1) { //add(1)
          reqAddCustomer(customer)
            .then(response => {
              message.success('Added successfully!');
              console.log(response.data);
              const id = response.data.id;
              //console.log(id);
              this.props.history.replace('/customers/edit/' + id);
            })
            .catch(error => {
              console.log(error)
            });
        }
      }
    });
  };

  onRef = (value) => {
    if (value === 'Referral') {

      reqGetCustomersName()
        .then(response => {
          this.setState({customerRef: response.data})
        })
        .catch(error => {
          console.log(error)
        });
    }
  };

  ajaxGetCustomerById = (id) => {
    reqGetCustomerById(id)
      .then(res => {
        this.setState({loading: false});
        let {customer, partner, address, referral, important_date} = res.data;
        //if address record exists and it is not view, get states and cities dropdown list
        if (address) {
          this.setState({address});
          if (this.viewAddUpdate !== 0) {
            //states drop down
            reqGetStates(address.country)
              .then(response => {
                  this.setState({states: response.data})
                }
              )
              .catch(error => {
                console.log(error.response)
              });

            //city drop down
            reqGetCities(address.province)
              .then(response => {
                  this.setState({cities: response.data})
                }
              )
              .catch(error => {
                console.log(error.response)
              });
          }
        }

        //if customer_important_dates record exists, add id to this
        for (let item of important_date) {
          switch (item.type) {
            case 'wedding':
              this.setState({wedding: item.date});
              this.wedding_id = item.id;
              break;
            case 'engagement_confirmed':
              this.setState({engagement_confirmed: item.date});
              this.engagement_confirmed_id = item.id;
              break;
            case 'approx_engagement':
              this.setState({approx_engagement: item.date});
              this.approx_engagement_id = item.id;
              break;
            case 'customer_birthday':
              this.setState({customer_birthday: item.date});
              this.customer_birthday_id = item.id;
              break;
            case 'partner_birthday':
              this.setState({partner_birthday: item.date});
              this.partner_birthday_id = item.id;
              break;
            default:
          }

        }
        this.setState({customer, partner, referral});
      })
      .catch(error => {
        console.log(error.response);
        const data = error.response.data;
        if (data.message) {
          message.error(data.message)
        }
        this.props.history.replace('/customers/list/');
      });
  };

  //get address country state select dropdown info
  ajaxGetAddress = (id) => {


    reqGetAddressOrderId(id)
      .then(response => {
        const address = response.data;
        this.setState({address});

        //states drop down
        reqGetStates(response.data.country)
          .then(response => {
              this.setState({states: response.data})
            }
          )
          .catch(error => {
            console.log(error.response)
          });

        //city drop down
        reqGetCities(response.data.province)
          .then(response => {
              this.setState({cities: response.data})
            }
          )
          .catch(error => {
            console.log(error.response)
          });
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  //address
  handlePlaceSelect = () => {
    let address = {};
    let place = this.autocomplete.getPlace();
    for (let i = 0; i < place.address_components.length; i++) {
      let component = place.address_components[i];
      let addressType = component.types[0];

      switch (addressType) {
        case 'street_number':
          address.address_1 = component.short_name;
          break;
        case 'route':
          address.address_1 += ' ';
          address.address_1 += component.long_name;
          break;
        case 'locality':
          address.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          address.province = component.long_name;
          this.onChangeState(address.province);
          break;
        case 'country':
          address.country = component.long_name;
          this.onChangeCountry(address.country);
          break;
        case 'postal_code':
          address.postal_code = component.short_name;
          break;
        default:
        // code block
      }
      this.setState({address})

    }
  };

  //country state select dropdown
  onChangeCountry = (value) => {
    reqGetStates(value)
      .then(response => {
          this.setState({states: response.data})
        }
      )
      .catch(error => {
        console.log(error.response)
      });
  };

  onChangeState = (value) => {
    reqGetCities(value)
      .then(response => {
          this.setState({cities: response.data})
        }
      )
      .catch(error => {
        console.log(error.response)
      });
  };

  render() {
    const {
      customer, partner, address, referral, customerRef, loading,
      customer_birthday, partner_birthday, approx_engagement, engagement_confirmed, wedding,
      cities, countries, states
    } = this.state;
    const {getFieldDecorator} = this.props.form;
    //view or add or update
    const {disableForm} = this;

    const formLayout = {
      labelCol: {span: 9},
      wrapperCol: {span: 12}
    };

    return (
      <div className="addUpdate">
        <Spin spinning={loading} size="large">

          <div style={{padding: '10px', fontSize: '18px'}} className='customer_title'>
            <span>
          <LinkButton onClick={() => this.props.history.goBack()}>
            <Icon type="arrow-left"/>
          </LinkButton>
          </span>
            <span>
              {!this.id ? 'Add a new Customer' : `${customer.first_name} ${customer.last_name}`}
            </span>
            <div style={{float: 'right', fontSize: '18px'}}>
              {
                (this.viewAddUpdate === 0) &&
                (<Link to={'/customers/edit/' + customer.id}><Button style={{margin: '0px 5px'}}
                         onClick={() => this.props.history.push('/customers/edit/' + this.id)}>
                  Edit Customer
                </Button></Link>)
              }
              {
                (this.viewAddUpdate === 2) &&
                (<Button style={{margin: '0px 5px'}}
                         onClick={() => this.props.history.push('/customers/view/' + this.id)}>
                  View Customer
                </Button>)
              }
              {
                (hasRole(this.user_type, ['super admin', 'production'])) && this.id &&
                (<Button style={{margin: '0px 5px'}} onClick={() => this.deleteCustomer(this.id)}>
                  Delete Customer
                </Button>)
              }
              {this.id && <OrderAddForm id={this.id} customer={customer}/>}
            </div>
          </div>

          <Form {...formLayout} onSubmit={this.onSubmit}>
            <div style={{background: '#ECECEC', padding: '20px'}}>
              <Row gutter={16}>
                <Col span={12}>
                  <Card title="Customer" bordered={false} size="small">
                    <Item label="First Name">
                      {
                        getFieldDecorator('first_name', {
                          initialValue: customer.first_name || "",
                          rules: [
                            {required: true, message: 'First Name is required',},
                            {max: 40, message: 'Maximum 40 characters'}
                          ],
                        })(<Input placeholder="First Name" disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Last Name">
                      {
                        getFieldDecorator('last_name', {
                          initialValue: customer.last_name || "",
                          rules: [
                            {required: true, message: 'Last Name is required',},
                            {max: 40, message: 'Maximum 40 characters'}
                          ],
                        })(<Input placeholder="Last Name" disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label={
                      !customer.mailchimp_user ? 'Email' :
                        <span><Icon type="mail" theme="twoTone"/>&nbsp;&nbsp;&nbsp;Email</span>
                    }>
                    <span>
                    {
                      getFieldDecorator('email', {
                        initialValue: customer.email || '',
                        rules: [
                          {
                            pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                            message: 'Email is not validated',
                          },
                          {max: 40, message: 'Maximum 40 characters'}
                        ]
                      })(<Input placeholder="Email" disabled={disableForm}/>)
                    }
                    </span>
                    </Item>
                    <Item label="Phone Primary">
                      {
                        getFieldDecorator('phone_primary', {
                          initialValue: customer.phone_primary || '',
                          rules: [
                            {max: 40, message: 'Maximum 40 characters'}
                          ]
                        })(<Input placeholder='Phone Primary' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Phone Secondary">
                      {
                        getFieldDecorator('phone_secondary', {
                          initialValue: customer.phone_secondary || '',
                          rules: [
                            {max: 40, message: 'Maximum 40 characters'}
                          ]
                        })(<Input placeholder='Phone Secondary' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Instagram">
                      {
                        getFieldDecorator('instagram', {
                          initialValue: customer.instagram || '',
                          rules: [
                            {max: 128, message: 'Maximum 128 characters'}
                          ]
                        })(<Input placeholder='Instagram' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Call Display">
                      {
                        getFieldDecorator('block_call_display', {
                          initialValue: customer.block_call_display,
                        })(
                          <Group disabled={disableForm}>
                            <Radio value={1}>Block</Radio>
                            <Radio value={0}>Show</Radio>
                          </Group>
                        )
                      }
                    </Item>

                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Partner" bordered={false} size="small">
                    <Item label="First Name">
                      {
                        getFieldDecorator('partner_first_name', {
                          initialValue: partner.partner_first_name || '',
                          rules: [
                            {max: 80, message: 'Maximum 80 characters'}
                          ]
                        })(
                          <Input placeholder="First Name" disabled={disableForm}/>
                        )
                      }
                    </Item>
                    <Item label="Last Name" disabled={disableForm}>

                      {
                        getFieldDecorator('partner_last_name', {
                          initialValue: partner.partner_last_name || '',
                          rules: [
                            {max: 80, message: 'Maximum 80 characters'}
                          ]
                        })(
                          <Input placeholder="Last Name" disabled={disableForm}/>
                        )
                      }
                    </Item>
                    <Item label={
                      !partner.partner_mailchimp_user ? 'Email' :
                        <span><Icon type="mail" theme="twoTone"/>&nbsp;&nbsp;&nbsp;Email</span>
                    }>
                      {
                        getFieldDecorator('partner_email', {
                          initialValue: partner.partner_email || '',
                          rules: [
                            {
                              pattern: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                              message: 'Email is not validated',
                            },
                            {max: 80, message: 'Maximum 80 characters'}
                          ]
                        })(<Input placeholder="Email" disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Phone Primary">
                      {
                        getFieldDecorator('partner_phone_primary', {
                          initialValue: partner.partner_phone_primary || '',
                          rules: [
                            {max: 40, message: 'Maximum 40 characters'}
                          ]
                        })(<Input placeholder='Phone Primary' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Phone Secondary">
                      {
                        getFieldDecorator('partner_phone_secondary', {
                          initialValue: partner.partner_phone_secondary || '',
                          rules: [
                            {max: 40, message: 'Maximum 40 characters'}
                          ]
                        })(<Input placeholder='Phone Secondary' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Instagram">
                      {
                        getFieldDecorator('partner_instagram', {
                          initialValue: partner.partner_instagram || '',
                          rules: [
                            {max: 128, message: 'Maximum 128 characters'}
                          ]
                        })(<Input placeholder='Instagram' disabled={disableForm}/>)
                      }
                    </Item>
                    <br/>
                    <br/>
                    <br/>
                  </Card>
                </Col>
              </Row>
            </div>
            <div style={{background: '#ECECEC', padding: '0px 20px 10px 20px'}}>
              <Row gutter={16}>
                <Col span={12}>
                  <Card title="Other Information" bordered={false} size="small">
                    <Item label="Customer's Birthday">
                      {
                        getFieldDecorator('customer_birthday', {
                          initialValue: !customer_birthday ? null : moment(customer_birthday)
                        })(<DatePicker disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Partner's Birthday">
                      {
                        getFieldDecorator('partner_birthday', {
                          initialValue: !partner_birthday ? null : moment(partner_birthday)
                        })(<DatePicker disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Approx Engagement">
                      {
                        getFieldDecorator('approx_engagement', {
                          initialValue: !approx_engagement ? null : moment(approx_engagement)
                        })(<DatePicker disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Engagement Confirmed">
                      {
                        getFieldDecorator('engagement_confirmed', {
                          initialValue: !engagement_confirmed ? null : moment(engagement_confirmed)
                        })(<DatePicker disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Wedding">
                      {
                        getFieldDecorator('wedding', {
                          initialValue: !wedding ? null : moment(wedding)
                        })(<DatePicker disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Referral Source">
                      {
                        getFieldDecorator('referral_source', {
                          initialValue: customer.referral_source || ''
                        })(
                          <Select disabled={disableForm} onSelect={this.onRef}>
                            {
                              REFERRALS.map(referral => <Option value={referral}
                                                                key={referral}> {referral} </Option>)
                            }
                          </Select>
                        )}
                    </Item>
                    {
                      (customerRef.length !== 0 || referral.referral_source === 'Referral') && (
                        <Item label="Referral People">
                          {
                            getFieldDecorator('referral_other_description', {
                              initialValue: referral.referral_other_description || '',
                              rules: [
                                {max: 255, message: 'Maximum 255 characters'}
                              ]
                            })(<AutoComplete
                              disabled={disableForm}
                              style={{width: 200}}
                              dataSource={customerRef}
                              placeholder="Customer Name"
                              filterOption={(inputValue, option) =>
                                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                            />)
                          }
                        </Item>)
                    }
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title="Address" bordered={false} size="small">
                    <div style={{textAlign: 'center', verticalAlign: 'middel', margin: '5px 0px 18px 0px'}}>
                      <input
                        style={{width: '450px', border: '1px solid #d9d9d9', padding: '4px'}}
                        placeholder=' Enter Address Here - Autocomplete Address'
                        disabled={disableForm}
                        id="autocomplete" type="text"
                        onChange={this.onChange}
                      />
                    </div>
                    <Item label="Country">
                      {
                        getFieldDecorator('country', {
                          initialValue: address.country || '',
                          rules: [
                            {max: 40, message: 'Maximum 40 characters'}
                          ]
                        })(<Select
                          showSearch
                          onChange={this.onChangeCountry}
                          disabled={disableForm}
                        >
                          {countries.map(item => <Option value={item.name} key={item.id}> {item.name} </Option>)}
                        </Select>)
                      }
                    </Item>
                    <Item label="Province">
                      {
                        getFieldDecorator('province', {
                          initialValue: address.province || '',
                        })(<Select
                          showSearch
                          onChange={this.onChangeState}
                          disabled={disableForm}
                        >
                          {states.map(item => <Option value={item.name} key={item.id}> {item.name} </Option>)}
                        </Select>)
                      }
                    </Item>
                    <Item label="City">
                      {
                        getFieldDecorator('city', {
                          initialValue: address.city || '',
                          rules: [
                            {max: 40, message: 'Maximum 40 characters'}
                          ]
                        })(<Select
                          showSearch
                          disabled={disableForm}
                        >
                          {cities.map(item => <Option value={item.name} key={item.id}> {item.name} </Option>)}
                        </Select>)
                      }
                    </Item>
                    <Item label="Address 1">
                      {
                        getFieldDecorator('address_1', {
                          initialValue: address.address_1 || '',
                          rules: [
                            {max: 255, message: 'Maximum 255 characters'}
                          ]
                        })(<Input placeholder='Line 1' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Address 2">
                      {
                        getFieldDecorator('address_2', {
                          initialValue: address.address_2 || '',
                          rules: [
                            {max: 255, message: 'Maximum 255 characters'}
                          ]
                        })(<Input placeholder='Line 2' disabled={disableForm}/>)
                      }
                    </Item>
                    <Item label="Postal Code">
                      {
                        getFieldDecorator('postal_code', {
                          initialValue: address.postal_code || '',
                          rules: [
                            {max: 10, message: 'Maximum 10 characters'}
                          ]
                        })(<Input placeholder='Postal Code' disabled={disableForm}/>)
                      }
                    </Item>

                  </Card>
                </Col>
              </Row>
            </div>
            <div style={{background: '#ECECEC', padding: '5px'}}>
              <Item wrapperCol={{span: 24}} style={{textAlign: "center"}}>
                <Button type="primary" htmlType="submit">submit</Button>
              </Item>
            </div>
          </Form>
          {
            this.id && <OrderTable id={this.id}/>
          }
        </Spin>
      </div>
    );
  }
}

export default Form.create()(ViewAddUpdate);
