import React, {Component} from 'react';
import {Form, DatePicker, Input, Select, Col, Checkbox, Row} from 'antd';
import {reqGetVendorsPlusDelete} from "../../../api";
import { HEAD_METAL, MOUNT_METAL, STATUS_STYLE} from "../../../utils/const";
import moment from 'moment';
import _ from 'lodash';
import LinkButton from "../../LinkButton";
import SelectInput from '../../SelectInput';

const {Item} = Form;
const {Option} = Select;
const {TextArea} = Input;

class StyleDetailsAddUpdateForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
    };
    props.setForm(props.form)
  }

  componentDidMount() {
    //get all vendors by type
    reqGetVendorsPlusDelete({searchName: 'style', searchType: 'type'})
      .then(response => {
        const vendors = response.data;
        this.setState({vendors});
      })
      .catch(error => {
        console.log(error.response)
      });
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {
      style_number_description, description,finger_size,mount_metal, head_metal, start_date, requires_approval_cad,
      requires_approval_replica,requires_approval_resin,
      approved_resin,approved_cad,approved_replica,retail, vendor_id, status
    } = this.props.order_item;
    const {vendors} = this.state;

    return (
      <Form>
        <Item label="Style Number" labelCol={{span: 5}} wrapperCol={{span: 17}}>
          {
            getFieldDecorator('style_number_description', {
              initialValue: style_number_description || '', //add or update
              rules: [
                {required: true, message: 'Style Number is required'}
              ]
            })(
              <Input/>
            )
          }
        </Item>
        <Item label="Description" labelCol={{span: 5}} wrapperCol={{span: 17}}>
          {
            getFieldDecorator('description', {
              initialValue: description || '', //add or update
            })(
              <TextArea rows={4}/>
            )
          }
        </Item>
        <Item label="Mount Metal" labelCol={{span: 5}} wrapperCol={{span: 17}}>
          {getFieldDecorator('mount_metal', {
            initialValue: mount_metal || '',
            rules: [
              {required: true, message: 'Mount Metal is required'},
              {max: 64, message: 'Maximum 64 characters'},
            ]
          })(
            <SelectInput options={MOUNT_METAL}/>
          )}
        </Item>
        <Item label="Head Metal" labelCol={{span: 5}} wrapperCol={{span: 17}}>
          {getFieldDecorator('head_metal', {
            initialValue: head_metal || '',
            rules: [
              {max: 64, message: 'Maximum 64 characters'},
            ]
          })(
            <SelectInput options={HEAD_METAL}/>
          )}
        </Item>
        <Row>
          <Col span={12}>
            <Item label='Finger Size' labelCol={{span: 9}} wrapperCol={{span: 13}}>
              {
                getFieldDecorator('finger_size', {
                  initialValue: finger_size || '',
                  rules: [
                    {pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/, message: 'Number with 2 decimal places Only',}
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
          <Col span={12}>
            <Item label='Retail' labelCol={{span: 9}} wrapperCol={{span: 12}}>
              {
                getFieldDecorator('retail', {
                  initialValue: retail || '',
                  rules: [
                    {required: true, message: 'retail is required'},
                    {
                      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                      message: 'Number with 2 decimal places Only',
                    }
                  ]
                })(<Input/>)
              }
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={1}>
            <Item label="Require CAD" labelCol={{span: 12}} wrapperCol={{span: 12}}>
              {getFieldDecorator('requires_approval_cad', {
                initialValue: requires_approval_cad || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={7}>
            <Item label="Require Resin" labelCol={{span: 12}} wrapperCol={{span: 12}}>
              {getFieldDecorator('requires_approval_resin', {
                initialValue: requires_approval_resin || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Require Replica" labelCol={{span: 12}} wrapperCol={{span: 12}}>
              {getFieldDecorator('requires_approval_replica', {
                initialValue: requires_approval_replica || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={8} offset={1}>
            <Item label="Approve CAD" labelCol={{span: 12}} wrapperCol={{span: 12}}>
              {getFieldDecorator('approved_cad', {
                initialValue: approved_cad || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={7}>
            <Item label="Approve Resin" labelCol={{span: 12}} wrapperCol={{span: 12}}>
              {getFieldDecorator('approved_resin', {
                initialValue: approved_resin || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Approve Replica" labelCol={{span: 12}} wrapperCol={{span: 12}}>
              {getFieldDecorator('approved_replica', {
                initialValue: approved_replica || false,
                valuePropName: 'checked',
              })(
                <Checkbox></Checkbox>
              )}
            </Item>
          </Col>
        </Row>

        {
          !(_.isEmpty(this.props.order_item)) &&
          (
            <React.Fragment>
              <Row>
                <Col span={12}>
                  <Item label="Vendor" labelCol={{span: 7}} wrapperCol={{span: 12}}>
                    {getFieldDecorator('vendor_id', {
                      initialValue: vendor_id || '',
                    })(
                      <Select
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {
                          vendors.map(item => <Option hidden={!!item.deleted_at} value={item.id}
                                                      key={item.id}> {item.name} </Option>)
                        }
                      </Select>
                    )}
                  </Item>
                </Col>
                <Col span={12}>
                  <Item label="Start Date" labelCol={{span: 9}} wrapperCol={{span: 12}}>
                    {
                      getFieldDecorator('start_date', {
                        initialValue: !(start_date) ? null : moment(start_date),
                      })(
                        <DatePicker/>
                      )
                    }
                  </Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Item label="Status" labelCol={{span: 7}} wrapperCol={{span: 12}}>
                    {getFieldDecorator('status', {
                      initialValue: status || '',
                    })(
                      <Select>
                        {
                          STATUS_STYLE.map(item => <Option value={item} key={item}> {item} </Option>)
                        }
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row>
                {!!vendor_id && <LinkButton onClick={() => this.props.deletePO()}>DELETE PO#</LinkButton>}
              </Row>
            </React.Fragment>
          )
        }
      </Form>
    )
  }
}

export default Form.create()(StyleDetailsAddUpdateForm)