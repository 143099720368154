import React, {Component} from 'react';
import {Table} from 'antd';

export default class CheckDiamond extends Component {

  constructor(props) {
    super(props);
    //initialize table columns
    this.initColumns();
  }

  initColumns = () => {
    this.columns = [{
      title: 'Vendor',
      dataIndex: 'name',
    },
      {
        title: 'Type',
        dataIndex: 'diamond_type',
      },
      {
        title: 'Shape',
        dataIndex: 'shape',
      },
      {
        title: 'Carats',
        dataIndex: 'carats',
      },
      {
        title: 'Colour',
        dataIndex: 'colour',
      },
      {
        title: 'Clarity',
        dataIndex: 'clarity',
      },
      {
        title: 'Cut',
        dataIndex: 'cut',
      },
      {
        title: 'Cert. Type',
        dataIndex: 'certificate_type',
      },
    ];
  }

  render() {
    const rowSelection = {
      columnTitle: ' ',
      getCheckboxProps(record) {
        return {
          defaultChecked: record.id
        }
      },
      onChange: (selectedRowKeys) => {
        this.selectedItems = selectedRowKeys;
        const checkDiamonds = this.props.diamonds.filter(item => this.selectedItems.includes(item.id));
        this.props.onGetDiamonds(checkDiamonds);
      },
    };

    return (
      <>
        <Table
          className='check-diamond'
          rowKey="id"
          bordered
          pagination={false}
          columns={this.columns}
          dataSource={this.props.diamonds}
          size="small"
          scroll={{x: 'max-content'}}
          rowSelection={this.props.rowSelected ? rowSelection : ""}
        />
      </>
    )
  }
}

CheckDiamond.defaultProps = {
  rowSelected: false,
}

