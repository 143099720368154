import React, {Component} from 'react';

import {Form, Input} from 'antd';

const {Item} = Form;

class TaxDialog extends Component {

  constructor(props) {
    super(props);
    this.props.setForm(this.props.form);
  }

  render() {

    const {gst, pst, hst} = this.props.fee;
    const {getFieldDecorator} = this.props.form;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 12}
    };

    return (
      <Form {...formLayout}>
        <Item label='GST'>
          {
            getFieldDecorator('gst', {
              initialValue: gst || '',
              rules: [
                {required: true, message: 'retail is required'},
                {
                  pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                  message: 'Number with 2 decimal places Only',
                }
              ]
            })(<Input/>)
          }
        </Item>
        <Item label='PST'>
          {
            getFieldDecorator('pst', {
              initialValue: pst || '',
              rules: [
                {required: true, message: 'retail is required'},
                {
                  pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                  message: 'Number with 2 decimal places Only',
                }
              ]
            })(<Input/>)
          }
        </Item>
        <Item label='Hst'>
          {
            getFieldDecorator('hst', {
              initialValue: hst || '',
              rules: [
                {required: true, message: 'retail is required'},
                {
                  pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
                  message: 'Number with 2 decimal places Only',
                }
              ]
            })(<Input/>)
          }
        </Item>
      </Form>
    )
  }
}


export default Form.create()(TaxDialog)