import {formatDate} from './utility';
import {Link} from 'react-router-dom';
import LinkButton from '../components/LinkButton';
import React from 'react';

export const ORDER_ITEM_TABLE = [
  {
    type: 'diamonds',
    key: 'diamond',
    title: 'Diamonds',
    columns: [
      {
        title: 'Shape',
        render: (item) => <Link to={'/inventory/view/' + item.id}><LinkButton>{item.shape}</LinkButton></Link>,
      },
      {
        title: 'Carats',
        dataIndex: 'carats',
      },
      {
        title: 'Colour',
        dataIndex: 'colour',
      },
      {
        title: 'Clarity',
        dataIndex: 'clarity',
      },
      {
        title: 'Cut',
        dataIndex: 'cut',
      },
      {
        title: 'Measure',
        dataIndex: 'measurements',
        width: 120,
      },
      {
        title: 'Cert. Type',
        dataIndex: 'certificate_type',
      },
      {
        title: 'Cert. No.',
        dataIndex: 'certificate_number',
        width: 105,
      },
      {
        title: 'Stone',
        render: (data) => (data.requires_approval_stone === 1 && data.approved_stone === 0) ? 'Required' :
          (data.requires_approval_stone === 1 && data.approved_stone === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'PO#',
        dataIndex: 'po_number',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
      },
    ]
  },
  {
    type: 'jewelleries',
    key: 'jewellery',
    title: 'Jewellery',
    columns: [
      {
        title: 'Job Type',
        dataIndex: 'job_type',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'CAD',
        render: (data) => (data.requires_approval_cad === 1 && data.approved_cad === 0) ? 'Required' :
          (data.requires_approval_cad === 1 && data.approved_cad === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Resin',
        render: (data) => (data.requires_approval_resin === 1 && data.approved_resin === 0) ? 'Required' :
          (data.requires_approval_resin === 1 && data.approved_resin === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Replica',
        render: (data) => (data.requires_approval_replica === 1 && data.approved_replica === 0) ? 'Required' :
          (data.requires_approval_replica === 1 && data.approved_replica === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'PO#',
        dataIndex: 'po_number',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
      },
    ]
  },
  {
    type: 'repairs',
    key: 'repair',
    title: 'Repairs / Maintenance',
    columns: [
      {
        title: 'Job Type',
        dataIndex: 'job_type',
      },
      {
        title: 'Item Description',
        dataIndex: 'item_description',
      },
      {
        title: 'Repair Description',
        dataIndex: 'repair_description',
      },
      {
        title: 'Repair',
        render: (data) => (data.requires_approval_repair === 1 && data.approved_repair === 0) ? 'Required' :
          (data.requires_approval_repair === 1 && data.approved_repair === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'PO#',
        dataIndex: 'po_number',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
      },
    ]
  },
  {
    type: 'stones',
    key: 'stone',
    title: 'Stones',
    columns: [
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Shape',
        dataIndex: 'shape',
      },
      {
        title: 'Measure',
        dataIndex: 'measurements',
        width: 120,
      },
      {
        title: 'Carats',
        dataIndex: 'carats',
      },
      {
        title: 'Colour',
        dataIndex: 'colour',
      },
      {
        title: 'Clarity',
        dataIndex: 'clarity',
      },
      {
        title: 'Cut',
        dataIndex: 'cut',
      },
      {
        title: 'Cert. Type',
        dataIndex: 'certificate_type',
      },
      {
        title: 'Cert. No.',
        dataIndex: 'certificate_number',
        width: 105,
      },
      {
        title: 'Count',
        dataIndex: 'count',
      },
      {
        title: 'Stone',
        render: (data) => (data.requires_approval_stone === 1 && data.approved_stone === 0) ? 'Required' :
          (data.requires_approval_stone === 1 && data.approved_stone === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'PO#',
        dataIndex: 'po_number',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
      },
    ]
  },
  {
    type: 'styles',
    key: 'style',
    title: 'Style Details',
    columns: [
      {
        title: 'Style Number',
        dataIndex: 'style_number_description',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Mount Metal',
        dataIndex: 'mount_metal',
      },
      {
        title: 'Head Metal',
        dataIndex: 'head_metal',
      },
      {
        title: 'Finger Size',
        dataIndex: 'finger_size',
      },
      {
        title: 'CAD',
        render: (data) => (data.requires_approval_cad === 1 && data.approved_cad === 0) ? 'Required' :
          (data.requires_approval_cad === 1 && data.approved_cad === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Resin',
        render: (data) => (data.requires_approval_resin === 1 && data.approved_resin === 0) ? 'Required' :
          (data.requires_approval_resin === 1 && data.approved_resin === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Replica',
        render: (data) => (data.requires_approval_replica === 1 && data.approved_replica === 0) ? 'Required' :
          (data.requires_approval_replica === 1 && data.approved_replica === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'PO#',
        dataIndex: 'po_number',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
      },
    ]
  },
  {
    type: 'components',
    key: 'component',
    title: 'Components',
    columns: [
      {
        title: 'Component Type',
        dataIndex: 'job_type',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Component',
        render: (data) => (data.requires_approval_repair === 1 && data.approved_repair === 0) ? 'Required' :
          (data.requires_approval_repair === 1 && data.approved_repair === 1) ? 'Approved' : 'No Require'
      },
      {
        title: 'Vendor',
        dataIndex: 'name',
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: formatDate
      },
      {
        title: 'PO#',
        dataIndex: 'po_number',
        width: 150,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Retail',
        dataIndex: 'retail',
      },
    ]
  }
]