import React, {Component} from 'react';
import {Spin, Card, Descriptions, Icon, Table, Col, Row, Button, message, Modal} from 'antd';
import LinkButton from '../../components/LinkButton';
import {Link} from 'react-router-dom';
import {reqDeleteDiamond, reqGetDiamondDetail} from '../../api';
import {getUserType, hasRole} from '../../utils/auth';

class View extends Component {

  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
    this.user_type = getUserType();
    this.state = {
      diamond: {},
      price_history: [],
      location_history: [],
      state_history: [],
      loading: true
    };

    this.initColumns();
  }

  componentDidMount() {
    reqGetDiamondDetail(this.id)
      .then(response => {
        const {diamond, price_history, location_history, state_history} = response.data;
        this.setState({diamond, price_history, location_history, state_history});
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error)
        const data = error.response.data;
        if (data.message) {
          message.error(data.message)
        }
        this.props.history.replace('/inventory/insite');
      });
  }

  //Delete
  deleteDiamond = (id) => {
    Modal.confirm({
      title: `Are you sure you want to delete this diamond?`,
      onOk: () => {
        reqDeleteDiamond(id)
          .then(response => {
              this.props.history.push('/inventory/insite');
            }
          )
          .catch(error => {
            //console.log(error.response);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      },
      onCancel() {
      },
    })
  };


  initColumns = () => {
    this.price_columns = [
      {
        title: 'Last Updated Date',
        dataIndex: 'created_at'
      },
      {
        title: 'Adjustment Updated Date',
        dataIndex: 'adjustment_updated_date'
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
      },
      {
        title: 'True Cost CAD',
        dataIndex: 'true_cost_cad',
      },
      {
        title: 'Shipping Cost CAD',
        dataIndex: 'shipping_cost_cad',
      },
      {
        title: 'CAD Cost Rate',
        dataIndex: 'cad_cost_rate',
      },
      {
        title: 'True Cost USD',
        dataIndex: 'true_cost_usd',
      },
      {
        title: 'Shipping Cost USD',
        dataIndex: 'shipping_cost_usd',
      },
      {
        title: 'Market Cost USD',
        dataIndex: 'market_cost_usd',
      },
      {
        title: 'Buffer Rate',
        dataIndex: 'buffer_rate',
      },
      {
        title: 'Currency Cost CAD',
        dataIndex: 'currency_cost_cad',
      },
      {
        title: 'Reduced Rate',
        dataIndex: 'reduced_rate',
      },
      {
        fixed: 'right',
        title: 'Sell CAD',
        dataIndex: 'sell_cad',
      },
      {
        fixed: 'right',
        title: 'Reduced CAD',
        dataIndex: 'reduced_cad',
      },
    ];
    this.location_columns = [
      {
        title: 'Location Date',
        dataIndex: 'created_at'
      },
      {
        title: 'Location',
        dataIndex: 'diamond_location'
      },
    ];
    this.state_columns = [
      {
        title: 'State Date',
        dataIndex: 'created_at'
      },
      {
        title: 'State',
        dataIndex: 'diamond_state'
      },
      {
        title: 'Memo Day',
        dataIndex: 'memo_day'
      },
    ]
  };

  render() {
    const {loading, diamond, price_history, location_history, state_history} = this.state;

    return (
      <>
        <Spin spinning={loading} size="large">
          <div style={{padding: '10px', fontSize: '18px'}}>
          <span>
          <LinkButton onClick={() => this.props.history.goBack()}>
            <Icon type="arrow-left"/>
          </LinkButton>
          </span>
            <div style={{float: 'right', fontSize: '18px'}}>
              {hasRole(this.user_type, ['super admin', 'production']) &&
              (<Button style={{marginLeft: '5px'}} onClick={() => this.deleteDiamond(this.id)}>
                Delete Diamond
              </Button>)}
              <Link to={'/inventory/edit/' + this.id}>
                <Button style={{margin: '0px 10px'}}
                        onClick={() => this.props.history.push('/inventory/edit/' + this.id)}>
                  Edit</Button>
              </Link>
            </div>
          </div>

          <div style={{background: '#ECECEC', padding: '15px'}}>
            <Card bordered={false} size="small" title="Diamond Detail">
              <Descriptions bordered size='small' column={3}>
                <Descriptions.Item label="Vendor">{diamond.name}</Descriptions.Item>
                <Descriptions.Item label="Invoice No.">{<a target="_blank" href={diamond.invoice_link}>
                  <LinkButton>{diamond.invoice_number}</LinkButton></a>}</Descriptions.Item>
                <Descriptions.Item label="Invoice Date">{diamond.inventory_date}</Descriptions.Item>
                <Descriptions.Item label="Sold To">{diamond.order_id &&
                <span><Link to={'/orders/view/' + diamond.order_id}>
                  <LinkButton>{`${diamond.last_name}, ${diamond.first_name}`}</LinkButton></Link></span>}</Descriptions.Item>
                <Descriptions.Item label="Type">{diamond.diamond_type}</Descriptions.Item>
                <Descriptions.Item label="Shape">{diamond.shape}</Descriptions.Item>
                <Descriptions.Item label="Carats">{diamond.carats}</Descriptions.Item>
                <Descriptions.Item label="Colour">{diamond.colour}</Descriptions.Item>
                <Descriptions.Item label="Clarity">{diamond.clarity}</Descriptions.Item>
                <Descriptions.Item label="Cut">{diamond.cut}</Descriptions.Item>
                <Descriptions.Item label="Stock#">{diamond.stock_number}</Descriptions.Item>
                <Descriptions.Item
                  label="Original Cert.">{diamond.original_certificate === 1 ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Cert.Type">{diamond.certificate_type}</Descriptions.Item>
                <Descriptions.Item label="Cert.No.">{diamond.certificate_number}</Descriptions.Item>
                <Descriptions.Item label="Reported">{diamond.reported === 1 ? 'Yes' : 'No'}</Descriptions.Item>
                <Descriptions.Item label="Stone Sold Date">{diamond.stone_sold_date}</Descriptions.Item>
                <Descriptions.Item label="Measurement" span={2}>{diamond.measurements}</Descriptions.Item>
                <Descriptions.Item label="Comments" span={3}>
                  <p style={{whiteSpace: 'pre-line'}}>{diamond.comments}</p>
                </Descriptions.Item>

              </Descriptions>
            </Card>
          </div>
          <div style={{background: '#ECECEC', padding: '0px 15px 15px 15px'}}>
            <Card title="Price History" size="small">
              <Table
                columns={this.price_columns}
                dataSource={price_history}
                size='small'
                scroll={{x: 'max-content'}}
                bordered={true} rowKey='id'
                pagination={false}/>
            </Card>
          </div>

          <Row style={{background: '#ECECEC'}}>
            <Col span={12}>
              <div style={{background: '#ECECEC', padding: '0px 15px 15px 15px'}}>
                <Card title="Location History" size="small">
                  <Table
                    columns={this.location_columns}
                    dataSource={location_history}
                    size='small'
                    bordered={true} rowKey='id'
                    pagination={false}/>
                </Card>
              </div>
            </Col>
            <Col span={12}>
              <div style={{background: '#ECECEC', padding: '0px 15px 15px 15px'}}>
                <Card title="State History" size="small">
                  <Table
                    columns={this.state_columns}
                    dataSource={state_history}
                    size='small'
                    bordered={true} rowKey='id'
                    pagination={false}/>
                </Card>
              </div>
            </Col>
          </Row>


        </Spin>
      </>
    )
  }
}


export default View;