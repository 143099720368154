import React, {Component} from 'react';
import {Card, Icon, Table, Divider, Select, Input, Button, DatePicker} from 'antd';
import LinkButton from "../../components/LinkButton";
import {reqGetAllOrders} from "../../api";
import {formatDate, momentToString} from "../../utils/utility";
import {Link} from 'react-router-dom';

const Option = Select.Option;

export default class defaultSortOrderList extends Component {


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      orders: [], //customer list
      searchType: 'name', //default search customer name
      searchContent: '',
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    this.ajaxGetAllOrders();

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.status !== prevProps.status) {
      this.setState({loading: true});
      this.ajaxGetAllOrders();
    }
  }

  ajaxGetAllOrders = () => {
    reqGetAllOrders(this.props.status)
      .then(response => {
        const orders = response.data;
        this.orders = orders;
        this.setState({orders});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  handleChangeSearchName = (event) => {
    this.setState({searchContent: event.target.value});
  };

  handleChangeSearchDate = (value) => {
    this.setState({searchContent: formatDate(momentToString(value))});
  };

  handleChangeSearchSales = (event) => {
    this.setState({searchContent: event.target.value});
  };

  handlerSearch = () => {
    let searchContent = this.state.searchContent.toLowerCase();
    if (this.state.searchType === 'name') {
      // if has space
      if (searchContent.indexOf(' ') >= 0) {
        let first_name = searchContent.split(" ")[0];
        let last_name = searchContent.substr(searchContent.indexOf(' ') + 1);

        let first_last = first_name.concat(', ', last_name);
        let last_first = last_name.concat(', ', first_name);

        const newOrders = this.orders.filter((item) => (((item.customer.full_name.toLowerCase()).indexOf(last_first) !== -1) || (item.customer.full_name.toLowerCase()).indexOf(first_last) !== -1))

        this.setState({orders: newOrders})
      } else {
        const newOrders = this.orders.filter((item) => (item.customer.full_name.toUpperCase()).indexOf(searchContent.toUpperCase()) !== -1);
        this.setState({orders: newOrders})
      }

    } else if (this.state.searchType === 'order_date') {
      const newOrders = this.orders.filter((item) => (item.order_date).indexOf(searchContent) !== -1);
      this.setState({orders: newOrders})
    } else if (this.state.searchType === 'sales') {
      if (searchContent.indexOf(' ') >= 0) {
        let first_name = searchContent.split(" ")[0];
        let last_name = searchContent.substr(searchContent.indexOf(' ') + 1);

        let first_last = first_name.concat(', ', last_name);
        let last_first = last_name.concat(', ', first_name);

        const newOrders = this.orders.filter((item) => (((item.sale.full_name.toUpperCase()).indexOf(last_first.toUpperCase()) !== -1) || (item.sale.full_name.toUpperCase()).indexOf(first_last.toUpperCase()) !== -1))

        this.setState({orders: newOrders})
      } else {
        const newOrders = this.orders.filter((item) => (item.sale.full_name.toUpperCase()).indexOf(searchContent.toUpperCase()) !== -1);
        this.setState({orders: newOrders})
      }
    }

  };


  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Order#',
        dataIndex: 'order_number'
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        render: (customer) => customer.full_name,
      },
      {
        title: 'Order Date',
        dataIndex: 'order_date',
        render: formatDate,
        sorter: (a, b) => new Date(a.order_date) - new Date(b.order_date),
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: formatDate,
        sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date),
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Days To Due Date',
        dataIndex: 'days_to_due',
        render: days_to_due => days_to_due && (days_to_due + " days"),
        sorter: (a, b) => a.days_to_due - b.days_to_due,
      },
      {
        title: 'Salesperson',
        dataIndex: 'sale',
        render: (sale) => sale.full_name,
        sorter: (a, b) => a.sale.full_name.localeCompare(b.sale.full_name),
      },
      {
        title: 'Job Description',
        dataIndex: 'job_description',
        sorter: (a, b) => a.job_description.localeCompare(b.job_description),
      },
      {
        title: 'Action',
        render: (order) =>
          <span>
            <Link to={'/orders/view/' + order.id}>
            <LinkButton onClick={() => this.props.history.push('/orders/view/' + order.id)}><Icon
              type="eye"/></LinkButton>
            </Link>
            {this.props.status !== 'Purgatory' &&
            <>
              <Divider type="vertical"/>
              <Link to={'/orders/edit/' + order.id}>
                <LinkButton onClick={() => this.props.history.push('/orders/edit/' + order.id)}><Icon
                  type="edit"/></LinkButton>
              </Link>
            </>
            }
          </span>
      },
    ];
  };

  render() {
    const {loading, orders, searchType} = this.state;

    const title = (
      <span>
        <Select
          style={{width: 250}}
          value={searchType}
          onChange={value => this.setState({searchType: value, searchContent: ''})}
        >
          <Option value="name">Search by Customer Name</Option>
          <Option value="order_date">Search by Order Date</Option>
          <Option value="sales">Search by Sales Person</Option>
        </Select>
        {searchType === 'name' &&
        <Input
          style={{width: 200, margin: '0 10px'}}
          placeholder="Song Ji"
          onChange={this.handleChangeSearchName}
          onPressEnter={this.handlerSearch}
        />}
        {searchType === 'order_date' &&
        <DatePicker
          style={{width: 200, margin: '0 10px'}}
          onChange={this.handleChangeSearchDate}
        />
        }
        {searchType === 'sales' &&
        <Input
          style={{width: 200, margin: '0 10px'}}
          placeholder="Song Ji"
          onChange={this.handleChangeSearchSales}
          onPressEnter={this.handlerSearch}
        />}

        <Button type="primary" onClick={this.handlerSearch}>SEARCH</Button>
          </span>
    );

    return (
      <div className="customer">
        <Card title={title}>
          <Table
            rowKey="id"
            loading={loading}
            dataSource={orders}
            columns={this.columns}
            pagination={false}
            size="middle"
            scroll={{x: true}}
          />
        </Card>
      </div>
    );
  }
}