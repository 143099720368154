import React, {Component} from 'react';
import {Card, message, Spin} from 'antd';
import moment from 'moment';
import {reqGetReportCost, reqExportCostReport} from "../../api";
import {momentToString, formatDate, isEmpty} from "../../utils/utility";
import Search from "../../components/Report/Search";
import Result from "../../components/Report/Result";


export default class CostReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      costs: {
        orders: [],
        usd: 0,
        cad: 0
      },
      loading: false
    }
  }

  handleSubmit = values => {
    const report = this.validateFilter(values);
    if (!report) return;

    this.setState({loading: true});
    reqGetReportCost(report)
      .then(response => {
        const costs = response.data;
        this.setState({costs});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response)
        this.setState({loading: false});
        message.error("Memory Exhausted!")
      });
  };

  handleExport = values => {
    const report = this.validateFilter(values);
    if (!report) return;

    reqExportCostReport(report)
      .then(response => {
        let blob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        let objectUrl = URL.createObjectURL(blob); // create url
        const link = document.createElement('a'); // generate a
        link.href = window.URL.createObjectURL(blob); // href download
        const today = moment().format('YYYY-MM-DD');
        link.download = "Cost_Report_" + today;// download File name
        link.click();
        URL.revokeObjectURL(objectUrl); //release memory
      })
      .catch(error => {
        console.log(error.response)
      });
  };

  validateFilter = values => {
    console.log(values);
    let {
      order_date, between_dates, picked_up, order_number, status, sales_representitive, job_description,
      payment_dates, countries, provinces, job_type
    } = values;

    //get date values
    let order_date_from, order_date_to, pick_up_from, pick_up_to, payment_date_from, payment_date_to;
    if (!isEmpty(between_dates)) {
      order_date_from = between_dates[0].format('YYYY-MM-DD');
      order_date_to = between_dates[1].format('YYYY-MM-DD');
    }

    if (!isEmpty(picked_up)) {
      pick_up_from = picked_up[0].format('YYYY-MM-DD');
      pick_up_to = picked_up[1].format('YYYY-MM-DD');
    }

    if (!isEmpty(payment_dates)) {
      payment_date_from = payment_dates[0].format('YYYY-MM-DD');
      payment_date_to = payment_dates[1].format('YYYY-MM-DD');
    }

    order_date = formatDate(momentToString(order_date));

    const report = {
      order_date, order_date_from, order_date_to, pick_up_from, pick_up_to, payment_date_from, payment_date_to,
      order_number, status, sales_representitive, job_description, countries, provinces, job_type
    };

    return report;
  };


  render() {
    const {costs, loading} = this.state;

    return (
      <div className="report-cost">
        <Card title='Cost Report'>
          <Search onSubmit={this.handleSubmit} onExport={this.handleExport}/>
          <Spin spinning={loading}>
            <Result costs={costs} style={{paddingTop: '40px', paddingBottom: '20px'}}/>
          </Spin>
        </Card>
      </div>
    );
  }

}