import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Select, DatePicker} from 'antd';
import moment from 'moment';
import {regexMoney} from "../../../utils/utility";
import {PAYMENT_TYPE} from '../../../utils/const';

const {Item} = Form;
const {Option} = Select;

class PaymentAddForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      IsChequeNoShow: false,
      IsRed: false
    };
    this.props.setForm(this.props.form);
  }

  onChange = (value) => {
    if (value === 'Cheque') {
      this.setState({IsChequeNoShow: true})
    } else {
      this.setState({IsChequeNoShow: false})
    }
    if (value === 'Refund') {
      this.setState({IsRed: true});
    } else {
      this.setState({IsRed: false});
    }
  };

  render() {

    const {getFieldDecorator} = this.props.form;
    const formLayout = {
      labelCol: {span: 7},
      wrapperCol: {span: 15}
    };

    const select_color = this.state.IsRed ? "red" : "";

    return (
      <Form {...formLayout}>
        <Item label="Type">
          {getFieldDecorator('type', {
            initialValue: 'Credit Card',
            rules: [
              {required: true, message: 'Type is required'}
            ]
          })(
            <Select
              onChange={this.onChange}
              style={{color: select_color}}
            >
              {
                PAYMENT_TYPE.map(item => <Option value={item} key={item}>{item}</Option>)
              }
              <Option style={{color: 'red'}} value='Refund'>Refund</Option>
            </Select>
          )}
        </Item>
        {this.state.IsChequeNoShow && (
          <Item label="Cheque Number">
            {getFieldDecorator('cheque_number', {
              initialValue: '',
            })(
              <Input type="text" placeholder="cheque number"/>
            )}
          </Item>
        )
        }
        <Item label="Payment Date">
          {
            getFieldDecorator('payment_time', {
              initialValue: moment(Date.now()),
            })(
              <DatePicker/>
            )
          }
        </Item>
        <Item label='Amount'>
          {
            getFieldDecorator('amount', {
              initialValue: '',
              rules: [
                {required: true, message: 'Amount is required'},
                {pattern: regexMoney, message: 'Number with 2 decimal places Only',}
              ]
            })(<Input placeholder='Amount'/>)
          }
        </Item>
      </Form>
    )
  }
}

PaymentAddForm.propTypes = {
  setForm: PropTypes.func.isRequired,
};

export default Form.create()(PaymentAddForm)