import React, {Component} from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import InSite from "../pages/Inventory/List/InSite";
import OffSite from "../pages/Inventory/Other/OffSite";
import View from "../pages/Inventory/View";
import Edit from "../pages/Inventory/Edit";
import Adjustment from '../pages/Inventory/Other/Adjustment/List';
import SellTier from '../pages/Inventory/Other/SellTier/List';
import AddDiamond from '../pages/Inventory/Action/AddDiamond';
import Vendor from '../pages/Inventory/Vendor/List';
import Update from '../pages/Inventory/Action/Update';
import SearchDiamond from '../pages/Inventory/Action/SearchDiamond';
import Sold from '../pages/Inventory/List/Sold';
import Location from '../pages/Inventory/List/Location';
import {getUserType, hasRole} from '../utils/auth';
import ExportDiamond from '../pages/Inventory/Action/ImportDiamond';

class InventoryRouter extends Component {
  constructor(props) {
    super(props);
    this.user_type = getUserType();
  }

  render() {
    return (
      <Switch>
        <Route path="/inventory/search" exact component={SearchDiamond}/>
        <Route path="/inventory/update" exact component={Update}/>
        <Route path="/inventory/insite" exact component={InSite}/> {/* exact: exactly match*/}
        <Route path="/inventory/location" exact component={Location}/>
        <Route path="/inventory/offsite" exact component={OffSite}/> {/* exact: exactly match*/}
        <Route path="/inventory/sold" exact component={Sold}/>
        <Route path="/inventory/vendor" exact component={Vendor}/> {/* exact: exactly match*/}
        {/*<Route path="/inventory/edit/:id" exact component={AddUpdate}/>*/}
        <Route path="/inventory/view/:id" exact component={View}/>
        <Route path="/inventory/edit/:id" exact component={Edit}/>
        {hasRole(this.user_type, ['super admin']) && <Route path="/inventory/adjustment" exact component={Adjustment}/>}
        {hasRole(this.user_type, ['super admin']) && <Route path="/inventory/sell-tier" exact component={SellTier}/>}
        <Route path="/inventory/add-diamonds" exact component={AddDiamond}/>
        <Route path="/inventory/import-diamonds" exact component={ExportDiamond}/>
        <Redirect to="/inventory"/>
      </Switch>
    )
  }
}

export default InventoryRouter;