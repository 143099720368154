import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Card, Divider, Table} from "antd";
import {reqGetOrdersByCustomerId} from "../../api";
import {formatDate} from "../../utils/utility";
import LinkButton from "../LinkButton";

class OrderTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
    };

    //initialize table columns
    this.initColumns();
  }


  componentDidMount() {
    if (this.props.id) {
      reqGetOrdersByCustomerId(this.props.id)
        .then(res => {
          this.setState({orders: res.data});
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.id !== prevProps.id) {
      reqGetOrdersByCustomerId(this.props.id)
        .then(res => {
          this.setState({orders: res.data});
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Order#',
        dataIndex: 'order_number'
      },
      {
        title: 'Order Date',
        dataIndex: 'order_date',
        render: formatDate
      },
      {
        title: 'Due Date',
        dataIndex: 'due_date',
        render: formatDate
      },
      {
        title: 'Job Description',
        dataIndex: 'job_description'
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Action',
        render: (order) =>
          <span>
            <Link to={'/orders/view/' + order.id}>
              <LinkButton onClick={() => this.props.history.push('/orders/view/' + order.id)}>View</LinkButton>
            </Link>
            <Divider type="vertical"/>
            <Link to={'/orders/edit/' + order.id}>
              <LinkButton onClick={() => this.props.history.push('/orders/edit/' + order.id)}>Edit</LinkButton>
            </Link>
          </span>
      },
    ];
  };


  render() {

    const {orders} = this.state;

    return (
      <React.Fragment>
        <div style={{padding: '15px', fontSize: '18px'}}>
          <span style={{padding: '10px'}}>Customer Order History</span>
        </div>
        <div style={{background: '#ECECEC', padding: '10px'}}>
          <Card bordered={false} size="small">
            <Table rowKey='id'
                   dataSource={orders}
                   columns={this.columns}
                   pagination={false}
                   size='middle'
            >
            </Table>
          </Card>
        </div>
      </React.Fragment>
    )
  }

}

export default withRouter(OrderTable);