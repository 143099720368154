import React, {Component, Fragment} from "react";
import PropTypes from 'prop-types';
import {Table} from "antd";

class ItemTable extends Component {
  render() {
    const {columns, dataSource, rowSelection, rowKey} = this.props;
    return (
      <Fragment>
        <Table
          pagination={false}
          rowKey={rowKey}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          size="small"
          bordered
          scroll={{ x: true }}
        />
      </Fragment>
    )
  }
}

ItemTable.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  rowSelection: PropTypes.object,
  rowKey: PropTypes.string,
}

ItemTable.defaultProps = {
  column: [],
  dataSource: [],
  rowKey: "id",
}
export default ItemTable;