import React, {Component} from 'react';
import {Icon, Table, Card, Divider, Modal, message, Button, Select, Input} from 'antd';
import {
  reqAddUser, reqDeleteUser,
  reqGetAllUsers, reqRestPwd,
  reqSearchUsers, reqUpdateUser,
} from "../../api";
import LinkButton from "../../components/LinkButton";
import AddForm from "../../components/User/AddForm";
import UpdateForm from "../../components/User/UpdateForm";
import PasswordForm from "../../components/User/PasswordForm";

const Option = Select.Option;

export default class List extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [], //user list
      showAddDialog: false,
      showUpdateDialog: false,
      showPasswordFrom: false,
      searchType: 'name', //default search name
      searchName: '',
    };

    //initialize table columns
    this.initColumns();
  }

  componentDidMount() {
    this.setState({loading: true});
    //get vendors
    reqGetAllUsers()
      .then(response => {
        const users = response.data;
        this.setState({users});
        //disable loading
        this.setState({loading: false});
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  //show add Dialog
  showAdd = () => {
    this.user = {};
    this.setState({showAddDialog: true})
  };

  //show update Dialog
  showUpdate = (user) => {
    this.user = user;
    this.setState({showUpdateDialog: true})
  };

  //show reset password Dialog
  showPassword = (user) => {
    this.user = user;
    this.setState({showPasswordFrom: true})
  };

  //Search
  onSearchUsers = () => {
    const {searchName, searchType} = this.state;

    reqSearchUsers({searchName, searchType})
      .then(response => {
        this.setState({users: response.data})
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  //Add
  onAdd = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        let {username, is_sales_rep, is_active, first_name, last_name, user_type, password} = values;
        let user = {username, is_sales_rep, is_active, first_name, last_name, user_type, password};
        reqAddUser(user)
          .then(response => {
              const newUser = response.data;
              this.setState(state => ({users: [newUser, ...state.users]}));
              this.setState({showAddDialog: false});
              message.success('Add Successfully!');
              this.form.resetFields();
            }
          )
          .catch(error => {
            //console.log(error.response);
            message.error('Error ' + error.response.status + ": " + error.response.data.error);
          });
      }
    })
  };

  //update
  onUpdate = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const {is_sales_rep, is_active, first_name, last_name, user_type} = values;
        const user = {is_sales_rep, is_active, first_name, last_name, user_type};
        const id = this.user.id;
        reqUpdateUser(id, user)
          .then(response => {
            this.setState({users: response.data});
            this.setState({showUpdateDialog: false});
            message.success('Update Successfully!');
            this.form.resetFields();
          })
          .catch(error => {
            console.log(error.response)
          });
      }
    })
  };

  //reset password
  onResetPassword = () => {
    this.form.validateFields(async (err, values) => {
      if (!err) {
        const {password} = values;
        const user = {password};
        const id = this.user.id;
        reqRestPwd(id, user)
          .then(response => {
            this.setState({users: response.data});
            this.setState({showPasswordFrom: false});
            message.success('Password Reset!');
            this.form.resetFields();
          })
          .catch(error => {
            console.log(error.response)
          });
      }
    })
  };

  //Delete
  onDelete = (user) => {
    Modal.confirm({
      title: `Are you sure you want to delete [ ${user.username} ] ?`,
      onOk: () => {
        reqDeleteUser(user.id)
          .then(response => {
            const usersAfterDelete = this.state.users.filter(item => !(item.id === user.id));
            this.setState({users: usersAfterDelete});
            message.success('Delete Successfully');
          })
          .catch(error => {
            console.log(error)
          });
      },
      onCancel() {
      },
    })
  };

  //initial table columns
  initColumns = () => {
    this.columns = [
      {
        title: 'Username',
        dataIndex: 'username'
      },
      {
        title: 'Type',
        dataIndex: 'user_type'
      },
      {
        title: 'First Name',
        dataIndex: 'first_name'
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name'
      },
      {
        title: 'Is Sales',
        dataIndex: 'is_sales_rep',
        render: (is_sales_rep) => is_sales_rep ? 'Yes' : 'No'
      },
      {
        title: 'Is Active',
        dataIndex: 'is_active',
        render: (is_active) => is_active ? 'Yes' : 'No'
      },
      {
        title: 'Action',
        render: (user) =>
          <span>
            <LinkButton onClick={() => this.showUpdate(user)}>Edit</LinkButton>
            <Divider type="vertical"/>
            <LinkButton onClick={() => this.showPassword(user)}>Reset Password</LinkButton>
            <Divider type="vertical"/>
            <LinkButton onClick={() => this.onDelete(user)}>Delete</LinkButton>
          </span>
      },
    ];
  };

  render() {
    const {users, loading, showAddDialog, showUpdateDialog, showPasswordFrom, searchType, searchName} = this.state;

    const title = (
      <span>
        <Select
          style={{width: 200}}
          value={searchType}
          showArrow={false}
          onChange={(value) => this.setState({searchType: value})}
        >
          <Option value="name">Search by Name</Option>
        </Select>
        <Input
          style={{width: 200, margin: '0 10px'}}
          placeholder="search"
          value={searchName}
          onChange={event => this.setState({searchName: event.target.value})}
          onPressEnter={this.onSearchUsers}
        />
        <Button type="primary" onClick={() => {
          this.onSearchUsers();
        }}>SEARCH</Button>
      </span>
    );

    const extra = <Button type='primary' onClick={this.showAdd}><Icon type="plus"/>Add User</Button>;

    return (
      <div className="users">
        <Card title={title} extra={extra}>
          <Table
            rowKey='id'
            loading={loading}
            dataSource={users}
            columns={this.columns}
            pagination={{defaultPageSize: 15, showQuickJumper: true}}
            size="middle"
            scroll={{ x: true }}
          />
          <Modal
            title='Add User'
            visible={showAddDialog}
            onOk={this.onAdd}
            onCancel={() => {
              this.setState({showAddDialog: false});
              this.form.resetFields();
            }}
          >
            <AddForm setForm={form => this.form = form}/>
          </Modal>
          <Modal
            title='Update User'
            visible={showUpdateDialog}
            onOk={this.onUpdate}
            onCancel={() => {
              this.setState({showUpdateDialog: false});
              this.form.resetFields();
            }}
          >
            <UpdateForm user={this.user} setForm={form => this.form = form}/>
          </Modal>
          <Modal
            title='Reset Password'
            visible={showPasswordFrom}
            onOk={this.onResetPassword}
            onCancel={() => {
              this.setState({showPasswordFrom: false});
              this.form.resetFields();
            }}
          >
            <PasswordForm user={this.user} setForm={form => this.form = form}/>
          </Modal>

        </Card>
      </div>
    );
  }

}